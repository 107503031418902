import React from 'react'
import { TextField } from '@material-ui/core'

class TextInputComponent extends React.Component {
  render() {
    const { input, style, password, fullWidth, ...inputProps } = this.props
    return (
      <View style={{ paddingVertical: 5 }}>
        <TextField
          {...inputProps}
          style={style && style}
          value={input.value}
          onChange={input.onChange}
          helperText={this.props.hinttext}
          error={this.error(this.props.meta)}
          type={password && "password"}
          fullWidth={true}
        />
      {this.error(this.props.meta) && <Text style={{ color: 'red', paddingTop: 5 }}>{`*${this.props.meta.error}`}</Text>}
      </View>
    )
  }

  error = ({touched, error, warning}) =>
    touched && ((error && error) || (warning && warning))
}

export default TextInputComponent

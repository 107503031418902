export default StyleSheet.create({
  title: {
    fontSize: 24
  },
  messageContainer: {
    flexDirection: 'row',
    borderWidth: 0.5,
    borderRadius: 5,
    borderColor: 'gainsboro',
    flex: 1,
    height: '95%',
    marginVertical: 5
  },
  userContainer: {
    flex: 1,
    borderRightWidth: 0.2,
    borderColor: 'gainsboro',
    overflow: 'scroll',
  },
  messages: {
    padding: 15,

  },
  incomingMessage: {
    padding: 10,
    backgroundColor: 'gainsboro',
    borderRadius: 10,
    marginVertical: 10,
    alignSelf: 'flex-start'
  },
  outgoingMessage: {
    padding: 10,
    backgroundColor: '#0084FF',
    borderRadius: 10,
    marginVertical: 10,
    alignSelf: 'flex-end'
  },
  outgoingText: {
    color: 'white'
  },
  clientContainer: {
    flex: 2,
    backgroundColor: '#fcfcfc',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: 'gainsboro',
    borderTopRightRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
  profile: {
    paddingTop: 12,
    fontSize: 16,
    color: '#007AFF',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  profileName: {
    paddingTop: 20,
    fontSize: 20,
    fontWeight: '600'
  },
  profileText: {
    paddingTop: 8,
    fontSize: 16,
    color: 'gray'
  },
  sendBtn: {
    backgroundColor: '#0084FF',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  msgInput: {
    paddingHorizontal: 10,
    flex: 11,
    outlineColor: 'white',
  },
  inputContainer: {
    flex: 1,
    borderTopWidth: 0.2,
    borderColor: 'gainsboro'
  },
  innerContainer: {
    flexDirection: 'row',
    height: '100%',
    flex: 1
  },
  emptyMessage: {
    alignSelf: 'center',
    paddingTop: 15,
    color: 'gray',
    fontSize: 16
  }
})

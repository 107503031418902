import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeltaIcon from '@material-ui/icons/ChangeHistory'
import AssessIcon from '@material-ui/icons/Assessment'
import LinearProgress from '@material-ui/core/LinearProgress'
import style from './style'

export default class DeltaEvent extends Component {
  render() {
    return (
      <Paper style={{ height: height - 20, overflow: 'scroll', paddingBottom: 20 }}>
        <View style={{ position: 'relative', height: 350, backgroundPosition: 'center', backgroundSize: 'cover' ,backgroundImage: 'url(https://www.trbimg.com/img-5bbbc876/turbine/ct-spt-chicago-marathon-2019-applications-20181008)'}}>
          <View style={{ position: 'absolute', paddingHorizontal: 20, paddingVertical: 5, width: 300, borderRightWidth: 5, borderColor: 'white', left: 0, top: 20, backgroundColor: 'rgba(0,0,0,0.4)'}}>
            <Text style={{ fontSize: 30, color: 'white', fontWeight: '600' }}>L.A. Marathon</Text>
            <Text style={{ color: 'white', fontSize: 14 }}>August 3rd, 2019 @ 4PM</Text>
            <Text style={{ color: 'white', fontSize: 14 }}>1234 W 2nd St.</Text>
            <Text style={{ color: 'white', fontSize: 14 }}>Los Angeles, CA 90024</Text>
          </View>
        </View>
        <View style={{ padding: 20 }}>
          <Text style={style.title}>Teams</Text>
        </View>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Text style={{ fontSize: 18, fontWeight: '600' }}>1. Los Angeles Lakers</Text>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://assets.capitalfm.com/2018/23/lilliya-scarlett-instagram-1528814125-custom-0.png'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Kelly Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>goodbye@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>30</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>10</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>40</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={80} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 80%</Text>
                </View>
              </View>
            </Paper>

            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://cdn.pixabay.com/photo/2018/02/07/20/58/girl-3137998_960_720.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Kelly Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>hello@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>45</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>45</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>90</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={45} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 45%</Text>
                </View>
              </View>
            </Paper>

            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://cdn.thedailymash.co.uk/wp-content/uploads/20190324205229/40-something-man-2-1.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>John Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>welcome@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>14</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>28</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>42</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={20} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 20%</Text>
                </View>
              </View>
            </Paper>
            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://assets.vogue.com/photos/5a0b9d136ed2ed3c2e007e85/master/pass/pine-post-tout.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Ken Rogers</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>whatsgoingon@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>8</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>31</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>39</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={100} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 100%</Text>
                </View>
              </View>
            </Paper>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Text style={{ fontSize: 18, fontWeight: '600' }}>2. Super Healthy Vegetebles</Text>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://assets.capitalfm.com/2018/23/lilliya-scarlett-instagram-1528814125-custom-0.png'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Kelly Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>goodbye@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>30</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>10</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>40</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={80} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 80%</Text>
                </View>
              </View>
            </Paper>

            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://cdn.pixabay.com/photo/2018/02/07/20/58/girl-3137998_960_720.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Kelly Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>hello@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>45</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>45</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>90</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={45} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 45%</Text>
                </View>
              </View>
            </Paper>

            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://cdn.thedailymash.co.uk/wp-content/uploads/20190324205229/40-something-man-2-1.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>John Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>welcome@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>14</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>28</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>42</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={20} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 20%</Text>
                </View>
              </View>
            </Paper>
            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://assets.vogue.com/photos/5a0b9d136ed2ed3c2e007e85/master/pass/pine-post-tout.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Ken Rogers</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>whatsgoingon@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>8</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>31</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>39</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={100} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 100%</Text>
                </View>
              </View>
            </Paper>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Text style={{ fontSize: 18, fontWeight: '600' }}>3. Super UnHealthy Meatlovers</Text>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://assets.capitalfm.com/2018/23/lilliya-scarlett-instagram-1528814125-custom-0.png'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Kelly Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>goodbye@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>30</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>10</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>40</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={80} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 80%</Text>
                </View>
              </View>
            </Paper>

            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://cdn.pixabay.com/photo/2018/02/07/20/58/girl-3137998_960_720.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Kelly Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>hello@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>45</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>45</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>90</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={45} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 45%</Text>
                </View>
              </View>
            </Paper>

            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://cdn.thedailymash.co.uk/wp-content/uploads/20190324205229/40-something-man-2-1.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>John Ryan</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>welcome@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>14</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>28</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>42</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={20} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 20%</Text>
                </View>
              </View>
            </Paper>
            <Paper style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              <Image style={{width: 150, height: 150}} source={{ uri: 'https://assets.vogue.com/photos/5a0b9d136ed2ed3c2e007e85/master/pass/pine-post-tout.jpg'}}/>
              <View style={{ width: 300, height: 150, position: 'relative' }}>
                <Text style={{ paddingTop: 15, paddingLeft: 15, fontSize: 14 }}>Ken Rogers</Text>
                <Text style={{ paddingLeft: 15, fontSize: 12, color: 'gray'}}>whatsgoingon@gmail.com</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 5, right: 5 }}>
                  <DeltaIcon color="primary" fontSize="large" />
                  <Text style={{ fontWeight: '600', fontSize: 18, color: '#03a9f4'}}>8</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>31</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>Initial Assess</Text>
                  </View>
                  <View style={{ margin: 20, textAlign: 'center' }}>
                    <Text style={{ fontWeight: '600', fontSize: 18 }}>39</Text>
                    <Text style={{ color: 'gray', fontSize: 10 }}>End Assess</Text>
                  </View>
                </View>
                <LinearProgress style={{ height: 20, marginTop: 10 }} variant="determinate" value={100} />
                <View style={{ position: 'absolute', bottom: 4, left: 5 }}>
                  <Text style={{ color: 'white', fontSize: 10 }}>exercise compliance: 100%</Text>
                </View>
              </View>
            </Paper>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Paper>
    )
  }
}

const { height } = Dimensions.get('window')

import Avatar from '@material-ui/core/Avatar'
import style from './style'

export default class Client extends Component {
  render() {
    const { first_name, last_name, email, id } = this.props.client
    return (
      <View style={style.clientContainer}>
        <Avatar style={{width: 150, height: 150, fontSize: 30}}>{`${first_name[0].toUpperCase()} ${last_name[0].toUpperCase()}`}</Avatar>
        <Text style={style.profileName}>{`${first_name} ${last_name}`}</Text>
        <Text style={style.profileText}>{email}</Text>
        <Text
          onPress={() => this.props.history.push({pathname: `/client/${id}`, state: { client: this.props.client }})}
          style={style.profile}
        >
          View Profile
        </Text>
      </View>
    )
  }
}

import { style } from './style'

export class Toolbar extends React.Component {
  render() {
    const { buttons, backgroundColor='white' } = this.props
    return (
      <View style={[style.toolbarContainer, {backgroundColor: backgroundColor}]}>
        {buttons && buttons.map((button, index) => (
          <View style={style.toolbarBtn} key={index} >
            {button}
          </View>
        ))}
      </View>
    )
  }
}

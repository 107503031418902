import React, {useState} from 'react'
import {useModifyWorkout} from '../../actions/workout'
import ConfirmModal from './ConfirmModal'
import {TextField, FormControl} from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

// TODO right now useModifyWorkout gets called every time text changes
// TODO figure out how react does it so that the function only creates once but uses the updated text
const RenameWorkout = props => {
  const [name, setName] = useState(props.name);
  const [endDate, handleEndDateChange] = useState(moment(props.workout.end_date));
  const { workout, closeModal } = props;
  const [renameWorkout] = useModifyWorkout(workout.id, name, endDate);

  const handleNameChange = e => {
    setName(e.target.value);
  }

  return (
    <ConfirmModal
      title="Edit Workout"
      actionLabel="Save Workout Name"
      action={() => renameWorkout()}
      modalClass="sm"
      closeModal={closeModal}
    >
      <div className="renameExerciseField">
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={handleNameChange}
          style={{marginBottom: '20px'}}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
        />
      </div>
    </ConfirmModal>
  );
}

export default RenameWorkout

import { maxBy } from 'lodash'

const excellent = '#6ece00'
const good = '#b1d639'
const fair = '#FFCC01'
const poor = '#FF9500'
const very_poor = '#FF3A30'

const colors = [very_poor, poor, fair, good, excellent]

export const setPosition = msrs =>
  msrs && msrs.map(msr =>
    msr['position'] === null
      ? {...msr, position: 0}
      : msr
 )


export const serializeAssess = (assess={}) => {
  return {
    ...assess,
    // make sure the measures are sorted by position in form
    // and given a position if none exist
    msrs: sortBy(setPosition(assess.msrs), 'position'),
    // changing obs structure to an object with msr id as key,
    // and value as ob object. Assessment UI depends on this shape.
    obs: reduce(
      assess.obs,
      (obs, ob) => {
        ob = {...ob, msr: ob.msr.id}
        return set(obs, ob['msr'], ob)
      },
      {}
    )
  }
}

export const scoreInfo = (assessPercent, assessType) => {
  const percent = Math.round(assessPercent) || 0
  const maxScore = assessType.best_score || 100
  return getScoreInfo(percent, assessType.risk || assessType.category === 'Cardio' ? heartLegend : startLegend, maxScore)
}


const getScoreInfo = (assessScore, legend, maxScore) => {
  const score =  Math.round(assessScore) || 0
  if (score >= maxScore * .80) return legend[0]
  else if ((maxScore * .79 >= score) && (score >= maxScore * .60)) return legend[1]
  else if ((maxScore * .59 >= score) && (score >= maxScore * .40)) return legend[2]
  else if ((maxScore * .39 >= score) && (score >= maxScore * .20)) return legend[3]
  else if (maxScore * .19 >= score) return legend[4]
}

export const getHighScore = (ob, assessment_type) => {
  if (assessment_type.heart) {
    return 0
  } else {
    return 100
  }
}

export const getScoreOb = (obs, {msrs, id, display}) => (
  display == "Multi"
  ? maxBy(map(msrs, partial(getScoreOb, obs)), 'score')
  : find(obs, { msr: { id }})
)

export const msrmntScore = (obs, msr, cardio) => {
  const scoreOb = getScoreOb(obs, msr)
  return scoreOb && cardio ? ((scoreOb.score - 5) * msr.weight) + 10 : (5 - scoreOb.score) * msr.weight
}

export const obValue = ({ display, optionables, unit }, { value }) => {
  if (display == 'Date') return moment(value * 1000).format('MMMM Do YYYY')
  if (optionables.length) {
    const text = find(optionables, {value})
    return text ? text.option.text : Math.round(value)
  }
  return `${Math.round(value)} ${unit ? unit.plural : ''}`
}

const makeLegend = (colors, labels) =>
  zip(colors, labels)
    .map(([color, label]) => ({color, label}))

export const heartLegend =
  makeLegend(colors, ['Very High Risk', 'High Risk', 'Moderate Risk', 'Low Risk', 'Very Low Risk'])

export const startLegend =
  makeLegend(colors.reverse(), ['Excellent', 'Good', 'Fair', 'Poor', 'Very Poor'])

import React from 'react'
import { View } from 'react-native-web'
import { graphql as _graphql } from 'react-apollo'
import { LinearProgress as LoadingBar } from '@material-ui/core'

export const graphql = (query, options={}) => {
  const {loadingStyle, ...gqlOptions} = options
  return (
    (Component) => (
      @_graphql(query, gqlOptions)
      @async(options)
      class extends React.Component {
        render() {
          return <Component {...this.props}/>
        }
      }
    )
  )
}

export const async = options => Component => props =>
  asyncRender(Component, props, options)

const asyncRender = (Component, props, options={}) => {
  const {loadingStyle, name='data'} = options
  return (
    props[name] && props[name].loading
      ? <View style={{height: '100%', justifyContent: 'center'}}>
          <LoadingBar style={loadingStyle}/>
        </View>
      : <Component {...props}/>
  )
}

import React, {useContext, useState} from 'react';
import {useAddStage} from '../../actions/workout';
import ConfirmModal from './ConfirmModal'

const AddStage = props => {
  const { stage, exercise, stageIndex, workout }  = props;
  const levelData = groupBy(exercise.level_data, 'level');
  const [level, setLevel] = useState('1');
  const [addStage] = useAddStage(workout.id, stage, exercise, levelData);

  return (
    <ConfirmModal
      action={() => addStage()}
      modalClass="sm"
      {...props}
    >
      <Text type="subheading">
        Add <span className="secondary">{` Stage ${stageIndex} `}</span>
        for <span className="primary">{exercise.name}</span>?
      </Text>
    </ConfirmModal>
  )
}

export default AddStage

import { useSelector} from 'react-redux';
import MUIDataTable from "mui-datatables"
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { randomColor } from '../../helpers/ui'
import { changeLanguage } from '../../config/actions/localization'
import useReactRouter from 'use-react-router';

const ClientTable = props => {
  const localization = useSelector(state => state.localization);
  const { strings } = localization;

  const { history } = useReactRouter();
  const { clients } = props;

  const columns = [
    {name: strings.photo, options: {sort: false}},
    {name: strings.firstName, options: { sortDirection: 'desc' }},
    strings.lastName,
    strings.email,
    "Last Active",
    {name: 'id', options: { display: false, viewColumns: false }}
  ];

  const options = {
    onRowClick: data => history.push({pathname: `/client/${data[5]}`}),
    sortFilterList: false,
    filter: false,
    selectableRows: 'none',
    print: false,
    download: false,
    responsive: "standard",
    textLabels: {
      body: {
        noMatch: strings.noMatch,
        toolTip: strings.sort,
      },
      pagination: {
        rowsPerPage: strings.rowsPerPage
      },
      toolbar: {
        search: strings.search,
        viewColumns: strings.viewColumns,
      },
      viewColumns: {
        title: strings.showColumns,
      }
    }
  };

  const clientsList = clients &&
    clients.map(client =>
      ([<Avatar style={{backgroundColor: randomColor()}}>{client.first_name[0].toUpperCase()}</Avatar>,
        client.first_name,
        client.last_name,
        client.email,
        client.last_activity ? moment(client.last_activity).format('LLLL') : 'N/A',
        client.id
      ])
    );

  const getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTableBodyCell: {
        cellHide: {
          display: "none !important"
        }
      },
      MUIDataTableBodyRow: {
        root: {
          height: "60px !important",
          cursor: "pointer"
        }
      },
      MuiPaper: {
        elevation4: {
          boxShadow: "0px 0px 0px 0px rgba(0,0,0,0) !important"
        }
      },
      MuiTableCell: {
        root: {
          width: 100
        },
        head: {
          color: 'gray'
        }
      }
    }
  });

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        data={clientsList}
        columns={columns}
        options={options}
      />
    </MuiThemeProvider>
  );
}

export default ClientTable

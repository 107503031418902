import { style } from './style'

class StateButton extends Component {
  state={loading: false}

  render() {
    let {disabled, text, loading, style: _style} = this.props
    loading = loading !== undefined ? loading : this.state.loading

    return (
      <View style={[style.submitBtn, _style]}>
        <TouchableOpacity
          style={disabled ? style.disabled : style.button}
          disabled={loading || disabled}
          onPress={this.onSubmit}
        >
          {loading
            ? <ActivityIndicator size="small" color="white" />
            : <Text style={style.btnText}>{text}</Text>
          }
        </TouchableOpacity>
      </View>
    )
  }

  onSubmit = () => {
    const {onSubmit, onCompleted} = this.props
    if (onSubmit) {
      this.setState({loading: true})
      onSubmit().then(() => {
        this.setState({loading: false})
        onCompleted && onCompleted()
      })
    }
  }
}

export default StateButton

import React, {Component, useContext, useState} from 'react'
import moment from 'moment'
import {closeModal} from '../../../actions/app'
import {editStage, editWeek} from '../../../actions/workout'
import _, {cloneDeep, pick, difference, find, remove, join, map, keys, groupBy, upperFirst} from 'lodash'
import { Button, Card, CardContent, Dialog, MenuItem, Select, InputLabel, DialogTitle, DialogContent, DialogActions, FormControl } from '@material-ui/core'
import ParameterSet from './ParameterSet'
import {editStyle} from './style'
import {useActions} from "../../../../../helpers/useActions";
import {useWorkout, WorkoutContext} from '../../../WorkoutContext';
import Workout from "../../Workout";

const weekDays = ['monday','tuesday', "wednesday",
                  "thursday", "friday", "saturday", "sunday", 'monday','tuesday', "wednesday",
                  "thursday", "friday", "saturday", "sunday"]
const schedules =  ["morning", "afternoon", "evening"]

const EditWeek = props => {
  const actions = useActions({closeModal, editStage, editWeek});
  const [week, setWeek] = useState(cloneDeep(props.week));
  const { workout } = useWorkout()

  props = {...props, ...actions};

  const renderTitle = () =>
    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
      <Text style={{fontSize: 20}}>Edit Week</Text>
      <FormControl style={{minWidth: 150}}>
        <Select
          value=""
          onChange={addDay}
          displayEmpty
        >
          <MenuItem value="" disabled><em>Add day</em></MenuItem>
          {scheduledDays().map((day, i) => (
            <MenuItem key={i} value={day}>{upperFirst(day)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </View>

  const renderHeader = () => {
    const {exercise: {name}, week: {stageIndex, weekIndex, start_date, end_date}} = props
    return (
      <div>
        <Text type="subheading">
          {'Exercise: '} <span className="value">{name}</span>
        </Text>
        <div className="scheduleDetail">
          <Text style={styles.scheduleLabel}>
            Stage: <span className="value">{stageIndex + 1}</span>
          </Text>
          <Text style={styles.scheduleLabel}>
           Week: <span className="value">{weekIndex + 1}</span>
          </Text>
          <Text style={styles.scheduleLabel}>
            {'Dates: '}
            <span className="value">
              {join([start_date, end_date].map(getDateStr), ' - ')}
            </span>
          </Text>
        </div>
      </div>)}

  const renderWeekDays = () => {
    const groupedParams = groupBy(week.parameter_sets, 'day_of_week')
    const dayIndex = indexOf(weekDays, lowerCase(props.week.start_date.format('dddd')))
    const days = slice(weekDays, dayIndex, dayIndex + 7)
    return days.map((day, index) => {
      const params = groupedParams[day]
      return params && (
        <Card key={index} className="exerciseCard">
          <CardContent>
            <View style={editStyle.weekDayCard}>
              {getDate(index)}
              {renderAddRoutineSelector(params)}
            </View>
            {renderWeekDay(params)}
          </CardContent>
        </Card>)})
  }

  const renderAddRoutineSelector = params =>
      <div style={{display:'block'}}>
        <Select
          style={{width:125}}
          value=""
          displayEmpty
          onChange={(e, i, v) => addRoutine(params, v)}>
          <MenuItem value="" disabled><em>Add new time</em></MenuItem>
          {difference(schedules, map(params, 'daily_index'))
            .map((dailyIndex) =>
              <MenuItem key={dailyIndex} value={dailyIndex}>
                {upperFirst(dailyIndex)}
              </MenuItem>)}
        </Select>
      </div>

  const renderWeekDay = (params) =>
    params.map(renderParam)

  const renderParam = (paramSet, i) =>
    paramSet.daily_index && (
      <ParameterSet
        key={i}
        paramSet={paramSet}
        addParam={addParam}
        editParam={editParam}
        rmParam={rmParam}/>
    )

  const scheduledDays = () =>
    difference(weekDays, map(week.parameter_sets, 'day_of_week'))

  const editParamSet = param =>
    setWeek(({parameter_sets}) => {
      find(parameter_sets, param).open = true; return {...week, parameter_sets}})

  const addDay = (e, index, day_of_week) =>
    setWeek(({parameter_sets}) =>
      parameter_sets.push({day_of_week, __typename: "ParameterSet"}))

  const addRoutine = ([{day_of_week}], daily_index) =>
    setWeek(({parameter_sets}) => (
      parameter_sets.push({day_of_week, daily_index, parameters: [], __typename: "ParameterSet"})))

  const removeParamSet = param =>
    setWeek(({parameter_sets}) => {
      remove(parameter_sets, param); return {...week, parameter_sets}})

  const addParam = (paramSet, label) =>
    setWeek(({parameter_sets}) => {
      find(parameter_sets, paramSet)
        .parameters.push({label, __typename: "Parameter"})
      return {...week, parameter_sets}})

  const editParam = (paramSet, {label, value}) =>
    setWeek(({parameter_sets}) => {
      findParam(parameter_sets, paramSet, label).value = value.toString()
      return {...week, parameter_sets}})

  const rmParam = (paramSet, label) =>
    setWeek(({parameter_sets}) => {
      remove(find(parameter_sets, paramSet).parameters, {label})
      return {...week, parameter_sets}})

  const saveWeek = () => {
    const {editWeek, exercise, closeModal} = props;
    editWeek(workout.id, week, props.week, exercise)
    closeModal()
  }

  const getDate = (index) => {
    const DAY_DATE_OBJ = moment(props.week.start_date)
    DAY_DATE_OBJ.date(DAY_DATE_OBJ.date() + index)
    return (
      <View>
        <Text type="subheading">{DAY_DATE_OBJ.format('dddd')}</Text>
        <Text>{getDateStr(DAY_DATE_OBJ)}</Text>
      </View>
    )
  }

  return (
    <Dialog
      open={true}
      onClose={props.closeModal}
      maxWidth="md"
      fullWidth={true}>
      <DialogTitle onClose={props.closeModal}>
        {renderTitle()}
      </DialogTitle>
      <DialogContent>
        <div>
          {renderHeader()}
          <div className="cardContainer">
            {renderWeekDays()}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.closeModal}>Cancel</Button>
        <Button variant="text" color="primary" onClick={saveWeek}>Save changes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditWeek

const getDateStr = (date) => date.format('MMM D')

const isPastPeriod = (period) => moment().diff(period.end_date) > 0

const styles = {
  scheduleLabel: {marginVertical: 10, marginRight: 10},
  addTimeLabel: {fontSize: 13, top: 5},
  addDayLabel: {fontWeight: '400', fontSize: 15, top: 5}
}

const findParam = (week, paramSet, label) =>
  find(find(week, paramSet).parameters,
       {label})

import { Collapse, Radio as _RadioButton, RadioGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Text, View } from 'react-native-web';
import { style } from './style'

export class RadioButtonGroup extends Component {
  render() {
    const { msrToggle, toggleState, msrGuide, options, action, label } = this.props
    return (
      <View>
        <View style={style.radioTitleContainer}>
          <Text style={{fontWeight: '300', fontSize: 14, marginBottom: 5}}>{label}</Text>
          {msrToggle}
        </View>
        <Collapse in={toggleState} unmountOnExit>
          {msrGuide}
        </Collapse>
        <View style={style.radioBtns}>
          <RadioGroup>
            {options
              .map(({value, label, legend}, index) => {
                const legendLabel = legend ? ` - ${legend}` : ''
                return (

                  <FormControlLabel
                    key={value}
                    value={value}
                    label={`${label} ${legendLabel}`}
                    className={style.assessmentLabel}
                    style={{marginBottom: '3px'}}
                    control={
                      <RadioButton
                        checked={this.props.value == value}
                        color="default"
                        action={action}
                        />
                    }
                    />
                )
              })}
          </RadioGroup>
        </View>
      </View>
    )
  }
}

class RadioButton extends Component {
  render() {
    const { action, ...props } = this.props

    return (
      <_RadioButton
        onClick={(e) => action(e.target.value)}
        onSelect={action}
        {...props}
      />
    )
  }
}

import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'

export default class User extends Component {
  render() {
    const { first_name, last_name, email } = this.props.user
    return (
      <Paper style={{margin: 'auto', height: 250, width: 400, padding: 20 }}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 15 }}>
          <Avatar style={{width: 130, height: 130, fontSize: 30}}>
            {`${first_name[0].toUpperCase()} ${last_name[0].toUpperCase()}`}
          </Avatar>
          <Text style={{ paddingTop: 20, fontSize: 20, fontWeight: '600' }}>{`${first_name} ${last_name}`}</Text>
          <Text style={{ paddingTop: 8, fontSize: 16, color: 'gray' }}>{email}</Text>
        </View>
      </Paper>
    )
  }
}

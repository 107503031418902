import { isEmpty, partial, find } from 'lodash'
import CheckboxGrid from './schedule/checkboxGrid'
import DropdownGrid from './schedule/dropdownGrid'
import { AvailabilityMeasure, CardioAvailabilityMeasure } from './schedule/availability.js'
import { MultiMeasure } from './multiMeasure'
import { TextInputMeasure, RadioMeasure, HiddenMeasure, HybridMeasure } from './inputs'
import { DatePicker } from '../../common/DatePicker'
import styles from './style'

class Measure extends Component {
  render() {
    const { msr, msr: { description, name, id},
            assessment, postObservation } = this.props

    const ob = find(assessment.obs, x => x.id === id)
    const value = ob && ob.value
    const freetext = ob && ob.freetext
    const MeasureComponent = this.msrComponent(msr)


    return (
      <MeasureComponent
        {...this.props}
        value={value}
        postOb={this.postOb}
        freetext={freetext}
        label={description || name}
      />
    )
  }

  // // TODO: might want to also pass the msrid, since its being passed down anyway
  // postOb = (value, msrId, type) => {
  //   const { assessment: {id: assessment, client: {uuid: client}},
  //           msr: {id: msr}, postObservation} = this.props
  //   postObservation(assessment, client, msrId || msr, value, this.props.msr, type)
  // }

  postOb = (value, msrId, type) => {
    const { assessment: {id: assessment},
            msr: {id: msr}, postObservation} = this.props

    return postObservation(assessment, msr || msrId, value)
  }

  msrComponent = ({id, options, style, display, msrs, type, components}) => {
    // console.log(type, 'type')
    // console.log(components, "components")
    if (type == 'Q&A')             return HybridMeasure
    if (id == 'cardio-exercise-availability')
                                   return CardioAvailabilityMeasure
    if (type == 'ARRAY')           return AvailabilityMeasure
    if (display == 'CheckboxGrid') return CheckboxGrid
    if (display == 'DropdownGrid') return DropdownGrid
    if (style == 'MULTI')          return MultiMeasure
    if (type == 'DATE')            return DateMeasure
    if (display == 'Hidden')       return HiddenMeasure
    if (!isEmpty(components))      return MeasureWithMeasures
    if (!isEmpty(options))         return RadioMeasure
    else                           return TextInputMeasure

    // return props => <div>msrComponent {display} </div>
  }
}

export default Measure

class MeasureWithMeasures extends Component {
  render() {
    return (
      this.props.msr.components.map((msr, i) =>
        <Measure {...this.props} msr={msr} key={i} />)
    )
  }
}

class DateMeasure extends Component {
  render() {
    const {label, value} = this.props
    const dateValue = value ? moment.unix(value).format('MM-DD-YYYY') : moment().format('MM-DD-YYYY')

    return (
      <View style={{ width: 350, paddingVertical: 10 }}>
        <Text style={{ fontWeight: '500', paddingVertical: 10 }}>Please select a start date.</Text>
        <DatePicker
          date={dateValue}
          onDateChange={this.handleDateChange}
          format='MM-DD-YYYY'
          showIcon={false}
        />
      </View>
    )
  }

  handleDateChange = date => {
    const {msr: { id: msrId, min }, postOb} = this.props
    const timestamp = moment(date).unix()
    postOb(timestamp)
  }
}

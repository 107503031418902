import React, { PureComponent } from 'react'
import {flatten, partial, join,
        isEqual, partition, sortBy,
        uniqBy, capitalize, groupBy} from 'lodash'
import moment from 'moment'
import {openModal} from '../../actions/app'
import {connect} from 'react-redux'
import AddWeek from '../Modals/Week/Add'
import EditWeek from '../Modals/Week/Edit'
import DeleteWeek from '../Modals/Week/Delete'
import {IconButton} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import BlockIcon from '@material-ui/icons/Block'
import {uniqFormatted, belongsToParameterSets,
        intersection, state, cardioBPM} from '../../helpers/Parameters'
import {daysHaveParameterSets} from '../../helpers/DayOfWeek'
import {ICON_SECONDARY_COLOR, ICON_SECONDARY_COLOR_HOVER} from '../../styles/index'

const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

export default (props) => (
  <div className="weekContent" style={{width: '100%'}}>
    <div className="weeks-content" style={{width: '100%'}}>
      {props.weeks.map((week, i, weeks) => (
        <Week {...props}
              key={i} weekIndex={i}
              week={week}
              lastCell={i == weeks.length-1}/>))}
    </div>
  </div>)

@connect(null, {openModal})
export class Week extends PureComponent {
  render() {
    const {week: {parameter_sets}} = this.props
    return (
      <div className={this.className()} onClick={this.onClick}>
        {parameter_sets
          ? <div>
              {this.renderWeekParameterSets()}
              {this.renderDeleteIcon()}
              <div className="dayCompliance">
                {this.renderDays(parameter_sets)}
              </div>
            </div>
          : <span className='text'>
              {this.canAddPeriod()
                ? 'Add Week'
                : <BlockIcon className="blockIcon" /*color="#d0c8c8"*/ />}
             </span>}
      </div>)
  }

  renderDays = (sets) => {
    const groupedParams = groupBy(sets, 'day_of_week')
    return weekDays.map((day, index) => {
      const params = groupedParams[day]
      const completed = params && params[0].completed
      return params && (
        <div key={index} className={completed ? "completed" : "incomplete"}>
          {day.charAt(0)}
        </div>
      )
    })
  }

  className = () => {
    const {week, lastCell} = this.props
    return (`cell week
            ${(lastCell ? ' last' : '')}
            ${(!week.parameter_sets ? ' add' : '')}
            ${(!week.parameter_sets && !this.canAddPeriod() ? ' disabled' : '')}`)
  }

  onClick = (e) => {
    e.stopPropagation()
    if (this.props.week.parameter_sets)
      this.openModal(EditWeek)
    else if (this.canAddPeriod())
      this.openModal(AddWeek)
  }

  renderWeekParameterSets = () => {
    const { week } = this.props
    const parameters =  this.allLabels(week)
    return (
      <div>
        {parameters.length
          ? parameters.map((param, i) =>
            <p key={i}>
              <span className='label'> {`${param.label === 'free text' ? param.value : capitalize(param.label)}: `}</span>
              <span className='value'>
                {this.paramValues(param, week)}
              </span>
            </p>)
         : <p>No Parameters</p>
        }
      </div>
    )
  }

  renderDeleteIcon = () =>
    this.canAddPeriod() &&
    <IconButton onClick={e => {e.stopPropagation()
                               this.openModal(DeleteWeek)}}
                className="deleteIconBtn">
        <DeleteIcon className="deleteIcon"
                    htmlColor={ICON_SECONDARY_COLOR}
                    hovercolor={ICON_SECONDARY_COLOR_HOVER}/>
      </IconButton>

  canAddPeriod = () => moment().diff(this.props.week.end_date) < 0

  allLabels = (weeks) =>
    flatten(partition(this.allParameters(weeks), ({isFreeText}) => !isFreeText)
            .map(params => sortBy(uniqBy(params, ({label}) => label),
                                  ({label}) => label)))

  allParameters = ({parameter_sets}) =>
    parameter_sets &&
      uniqFormatted(
        belongsToParameterSets(parameter_sets))

  getSameValueInDays = (label, dayOfWeek) =>
    belongsToParameterSets(this.props.week.parameter_sets,
                           {dayOfWeek: dayOfWeek, label: label })[0].value

  sameParametersAcrossDays = (week) =>
    week &&
    intersection(
      daysHaveParameterSets(week)
        .map((day) =>
             uniqFormatted(
               belongsToParameterSets(week.parameter_sets, {dayOfWeek: day}))
             .filter((formatted, index, self) =>
                     self.filter(({label}) => label == formatted.label)
                     .length == 1)))

  paramValues = ({label, value}, week) => {
    const haveParamSets = daysHaveParameterSets(week)
    const states = haveParamSets
          .map(dayOfWeek => this.dailyParameterStatus(label, dayOfWeek))
    const sameParameter = this.sameParametersAcrossDays(week)
          .find(parameter => parameter.label === label)
    return sameParameter
      ? label === 'Zone' ? cardioBPM(value) : value
      : label === 'Zone'
        ? <div>
            {states.map((state, index) =>
              state === 'same'
                ? <div>{cardioBPM(this.getSameValueInDays(label, haveParamSets[index]))}</div>
                : state
            )}
          </div>
        : join(states
          .map((state, index) =>
             state === 'same'
               ? this.getSameValueInDays(label, haveParamSets[index])
               : state),
        ', ')
  }

  dailyParameterStatus = (label, dayOfWeek) =>
    state(belongsToParameterSets(this.props.week.parameter_sets,
                                 {dayOfWeek, label}))

  openModal = (Modal) => {
    const {openModal, exercise, week} = this.props
    openModal(<Modal exercise={exercise} week={week}/>)
  }
}

import { Query } from 'react-apollo'
import { graphql } from '../../helpers/graphql'
import { currentProvider } from '../graphql/query/client'
import { clients_by_provider } from '../graphql/query/client'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Divider from '@material-ui/core/Divider'
import Messages from './messages'
import Client from './client'
import style from './style'

@graphql(currentProvider)
export default class MessageContainer extends Component {
  state = { text: '', isFirstRender: true, client: '' }
  componentDidUpdate(prevProps, prevState) {
    const { history } = this.props
    if (history.location.state && history.location.state.client !== prevState.client) {
      return this.setState({ client: history.location.state.client })
    }
  }
  render() {
    const { location } = this.props.history
    return (
      <Paper style={{ height: height - 94, padding: 20 }}>
        <Text style={style.title}>Messages</Text>
        <View style={style.messageContainer}>
          {/* 'tabs' of users messages */}
          <Query
            query={clients_by_provider}
            variables={{ id: this.props.data.currentProvider.id }}
            onCompleted={data => this.setClient(location.state && location.state.client ? location.state.client : data.clients_by_provider[0])}
            fetchPolicy="network-only"
          >
            {({data, loading, error}) =>  {
              if (loading) return <View style={[style.userContainer, { justifyContent: 'center', alignItems: 'center'}]}>
                                    <ActivityIndicator />
                                  </View>
              if (error) return <Text>Something went wrong. Please try refreshing the page.</Text>
              return (
                <View style={style.userContainer}>
                  <List>
                    {data.clients_by_provider.map(client => {
                      const { first_name, last_name, id, email } = client
                      return (
                        <TouchableOpacity onPress={() => this.setState({ client })} key={id}>
                          <ListItem selected={this.state.client && this.state.client.id === id}>
                            <ListItemAvatar>
                              <Avatar>{first_name[0]}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${first_name} ${last_name}`}
                              secondary={email}
                              />
                          </ListItem>
                          <Divider />
                        </TouchableOpacity>
                      )
                    })}
                  </List>
                </View>
              )
            }}
          </Query>
          <View style={{flex: 4}}>
            {this.renderMessages(this.state.client)}
          </View>
        </View>
      </Paper>
    )
  }
  setClient = client => {
    if (this.state.isFirstRender) this.setState({client, isFirstRender: false})
  }
  renderMessages = client => {
    const { data } = this.props
    const provider = data && data.currentProvider.id
    if (client) {
      return (
        <View style={{flex: 11, flexDirection: 'row'}}>
          <Messages provider={provider} client={ client.id }/>
          <Client client={client} {...this.props} />
        </View>
      )
    }
  }
}

const { height } = Dimensions.get('window')

import { Query } from 'react-apollo'
import { o_client_assessments } from '../graphql/query/assessment'
import React, { useContext } from 'react'
import Delete from '@material-ui/icons/Delete'
import { activateWorkout as activateWorkoutMutation } from '../graphql/mutation/workout'
import { toggleSnackbar } from '../../config/actions/snackbar'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { GlobalModalContext } from '../common/GlobalModal'
import { formatDate } from '../../helpers/time'
import { useActions } from '../../helpers/useActions'
import AssessActions from './actions'
import RetractAssessment from './retractAssessment'
import { clientAssessStyle as style } from './style'

export const ClientAssessments = props => {
  const { data, onPress, uuid, history, url } = props
  return (
    <Query
      query={o_client_assessments}
      variables={{ clientId: uuid }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (error) return <Text style={{ padding: 20, alignSelf: 'center' }}>Something went wrong, please try again.</Text>
        if (loading) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', margin: 30}}><ActivityIndicator /></View>

        return (
          <AssessmentCards
            assessments={data && data.o_client_assessments}
            onPress={onPress}
            history={history}
            uuid={uuid}
            url={url}
          />
        )
      }}
    </Query>
  )
}

const VirtualizedList = ({ListEmptyComponent=View, data, children, ...props}) =>
  <ScrollView {...props}>
    {data.length ? children : <ListEmptyComponent/>}
  </ScrollView>

const FlatList = ({data=[], renderItem, ...props}, index) =>
  <VirtualizedList key={index} data={data} {...props}>
    {map(data, item => renderItem({item}))}
  </VirtualizedList>

const AssessmentCards = props => {
  const { strings } = useSelector(state => state.localization)
  const actions = useActions({toggleSnackbar})
  props = {...props, ...actions}

  const [activateWorkout, {loading, error}] = useMutation(activateWorkoutMutation)

  const { openModal } = useContext(GlobalModalContext)

  const { assessments } = props
  const assessHeader = (heart) =>
    [{text: strings.created, title: true},
     {text: strings.workout, title: true},
     {text: heart ? strings.risk : strings.score, title: true},
     {text: strings.options, title: true}]

  const renderAssessment = (assess, index, arr) => {
    const { onPress, history, url, uuid: client } = props
    const { result, created_at, workout, id } = assess
    const assessScore = result && result.score ? Math.round(result.score) : ""
    const assessDetails = [
      {text: formatDate(created_at)},
      {text: workout ? renderWorkoutStatus(assess) : strings.notCreated},
      {text: assessScore || assessScore === 0 ? assessScore : 'N/A'}
    ]
    const last = arr.length - 1 === index

    return (
      <View key={index} style={last ? style.assessment : [style.assessment, style.assessBorder]} onClick={() => onPress(assess)}>
        <View style={[style.assessLinkTxt, style.assessLink]}>
          {assessDetails.map(renderAssessDetails)}
        </View>
        <View style={style.assessActions} onClick={(e) => e.stopPropagation()}>
          <AssessActions assess={assess} {...props} url={`${url}/${id}`} />
          <TouchableOpacity onPress={() => openModal({modal: <RetractAssessment id={id} client={client} />, free: true})}>
            <Delete htmlColor='red' size={30} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  const renderWorkoutStatus = (assess) => {
    const { workoutId, workout: { state, compliance } } = assess
    return state === 'inactive'
      ? <TouchableOpacity style={{ backgroundColor: 'rgb(112, 205, 203)', paddingVertical: 5, paddingHorizontal: 15, borderRadius: 3 }}
          onPress={(e) => {
            e.stopPropagation()
            sendWorkout(workoutId)
        }}>
          <Text style={{ color: 'white', fontWeight: '600' }}>Send Workout</Text>
        </TouchableOpacity>
      : <Text>{`${Math.floor(compliance * 100)}% complete`}</Text>
  }

  const sendWorkout = (workoutId) => {
    console.log('reaching here', workoutId)
    activateWorkout({ variables: { id: workoutId } })
      .then((resp) => (
        console.log('the resp', resp) ||
        props.toggleSnackbar('Workout has been sent.')))
  }

  const renderAssessDetails = ({text, title}, index) =>
    <Text key={index} style={title ? style.detailHeader : style.details}>
      {text}
    </Text>

  return (
    <React.Fragment>
      <FlatList
        style={style.container}
        renderItem={({item: {type, assessments}}) =>
          <View style={{borderWidth: 1, borderColor: 'gainsboro', borderRadius: 5, padding: 10, margin: 10}} key={uniqueId()}>
            <Text style={style.title}>{type}</Text>
            <View style={style.assessHeader}>
              {assessHeader(type === "Cardio").map(renderAssessDetails)}
            </View>
            {assessments.map(renderAssessment)}
          </View>}
        data={map(groupBy(assessments, 'assessment_name'),
          (assessments, type) =>({assessments, type}))}
        ListEmptyComponent={NoAssessments}
      />
    </React.Fragment>
  )
}

const NoAssessments = () =>
  <Text style={style.noAssessments}>
    No assessments
  </Text>

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  calendarContainer: {
    width: 700,
    height: 600,
    flex: 2
  },
  quickViewContainer: {
    height: '100%',
    maxHeight: 600,
    borderWidth: 1,
    borderColor: 'gainsboro',
    borderRadius: 10,
    flex: 1,
    marginLeft: 20,
    marginTop: 40,
    padding: 20,
    overflow: 'scroll'
  },
  title: {
    fontSize: 20,
    fontWeight: '600'
  },
  date: {
    paddingVertical: 5,
    color: 'gray'
  },
  delete: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  inputs: {
    marginBottom:  20,
    marginTop: 15
  },
  input: {
    marginTop: 15
  },
  upcomingAppt: {
    borderBottomWidth: 0.5,
    borderColor: 'gainsboro',
    paddingVertical: 15
  },
  eventTitle: {
    fontWeight: '500',
    fontSize: 16
  },
  eventDetails: {
    fontSize: 14,
    color: 'gray'
  },
  button: {
    marginTop: 25,
    backgroundColor: '#03a9f4',
    width: '100%',
    paddingVertical: 8,
    borderRadius: 3
  }
})

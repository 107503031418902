// // TODO not sure how this gets updated
// // import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
//
// import {
//   cyan500,
//   cyan700,
//   lightBlack,
//   pinkA200,
//   grey100,
//   grey500,
//   deepPurpleA700,
//   white,
//   grey300,
//   darkBlack,
//   blueGrey500,
//   blueGrey300,
//   blueGrey100
// } from '@material-ui/core/colors';
//
// // import ColorManipulator from 'material-ui/utils/colorManipulator';
// // import Spacing from 'material-ui/styles/spacing';
// // import zIndex from 'material-ui/styles/zIndex';
// // import _ from 'lodash';
// // const merge = lodash.merge;
//
//
// const ocpTheme = {
//   flatButton: {
//     primaryTextColor: '#444C63',
//     secondaryTextColor: '#B8D408'
//   },
//   raisedButton: {
//     primaryColor: '#444C63',
//     secondaryColor: '#B8D408'
//   },
//   palette: {
//     primary1Color: '#FFF',
//     primary2Color: '#B8D408',
//     primary3Color: lightBlack,
//     accent1Color: '#B8D408',
//     accent2Color: grey100,
//     accent3Color: grey500,
//     textColor: darkBlack,
//     alternateTextColor: white,
//     canvasColor: white,
//     borderColor: grey300,
//     pickerHeaderColor: cyan500
//   },
//   paper: {
//     zDepth: 0
//   },
//   table: {
//     height: 'calc(100vh - 122px)'
//   },
//   tableHeaderColumn: {
//     fontSize: '14px'
//   },
//   tabs: {
//     backgroundColor: white,
//     textColor: 'darkgray',
//     selectedTextColor: '#444C63'
//   },
//   refreshIndicator: {
//     strokeColor: '#B8D408',
//     loadingStrokeColor: '#B8D408'
//   },
//   textField: {
//     focusColor: '#8C97B2'
//   },
//   stepper: {
//     iconColor: 'rgb(184, 212, 8)'
//   },
//   radioButton: {
//     borderColor: lightBlack,
//     checkedColor: blueGrey300
//   },
//   datePicker: {
//     color: '#F03462',
//     textColor: 'white',
//     calendarTextColor: '#F03462',
//     selectColor: '#444C63',
//     selectTextColor: 'white'
//   }
// };
//
// // const theme = merge(lightBaseTheme, ocpTheme);
// const theme = ocpTheme;
//
// export default theme;

import React, { useState } from 'react'
import moment from 'moment'
import {getStages} from '../helpers/workout'
import IconButton from '@material-ui/core/IconButton'
import ViewWeekIcon from '@material-ui/icons/ViewWeek'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { MenuItem, Select } from '@material-ui/core';
import { useModal } from '../../common/Modal';

import {HEADER_CONTROLS_COLOR, HEADER_CONTROLS_HOVER_COLOR} from '../styles'
import style from '../styles/exerciseList.css'
import Exercises from './Workout/Exercises'
import Header from './Workout/Header'

const Workout = (props) => {
  const [viewingStage, setViewingStage] = useState(null);
  const [values, setValues] = useState({
    exerciseSort: 0,
  });

  const { openModal, closeModal, Modal } = useModal();

  // todo: just creating a generic modal for all exercise functionality
  props = { openModal, closeModal, ...props };

  const handleSelectChange = (event) => {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderNavigationActions = () => {
    return (
        <div className="stages-header stages-control expand">
          <div className="navigate-buttons">
            {/* Disabled until measures are enabled in stage */}
            {/*<Select value={values.exerciseSort}*/}
            {/*        onChange={handleSelectChange}*/}
            {/*        inputProps={{*/}
            {/*          name: 'exerciseSort',*/}
            {/*          id: 'exerciseSort'*/}
            {/*        }}>*/}
            {/*  <MenuItem value={0}>Default</MenuItem>*/}
            {/*  <MenuItem value={1}>Measure</MenuItem>*/}
            {/*</Select>*/}
            <NavAction Icon={ViewWeekIcon}
                       onClick={allWorkoutClick}
                       disabled={!viewingStage}/>
            <NavAction Icon={NavigateBeforeIcon}
                       onClick={() => stageViewClick(viewingStage - 1)}
                       disabled={leftArrowDisable()}/>
            <NavAction Icon={NavigateNextIcon}
                       onClick={() => stageViewClick(viewingStage + 1)}
                       disabled={rightArrowDisable()}/>
            <span className="seperator"/>
          </div>
        </div>
    )
  };

  const stageViewClick = (viewingStage) => setViewingStage(viewingStage)

  const allWorkoutClick = () => stageViewClick(null)

  const leftArrowDisable = () => {
    return viewingStage == null || viewingStage == 1
  };

  const rightArrowDisable = () => {
    return viewingStage == null || viewingStage == 4
  };

  return (
    <div className="wbList">
      <div className={"wb-wlist-li-lowerdeck expand"}>
        <div className="stages-header-bar"
             style={{position: 'sticky', top: '162px',
                     zIndex: 100, background: '#e1e4ea'}}>
          {renderNavigationActions()}
          <Header workout={props.workout}
                  allWorkoutClick={allWorkoutClick}
                  stageViewClick={stageViewClick}
                  stages={getStages(props.workout)}
                  viewingStage={viewingStage}/>
        </div>
        <Exercises {...props}
                   viewingStage={viewingStage}
                   stageViewClick={stageViewClick}
                   sort={values.exerciseSort} />
        {Modal}
      </div>
    </div>
  )

}

export default Workout

const NavAction = ({Icon, ...buttonProps}) => (
  <IconButton {...buttonProps}>
    <Icon className="material-icons"
          htmlColor={HEADER_CONTROLS_COLOR}
          hovercolor={HEADER_CONTROLS_HOVER_COLOR}/>
  </IconButton>)

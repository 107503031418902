import {createContext, useState, useContext} from 'react'

export const WorkoutContext = createContext({})

export const WorkoutProvider = ({children}) => {
  const [workout, setWorkoutState] = useState(undefined)

  return (
    <WorkoutContext.Provider
      value={{
        workout,
        setWorkout: currentWorkout => {
          setWorkoutState(currentWorkout)
        }
      }}
    >
      {children}
    </WorkoutContext.Provider>
  )
}

export const useWorkout = () => useContext(WorkoutContext)
import { compose, createStore, applyMiddleware } from 'redux'
import { combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reducer as form } from 'redux-form'
import modal from './reducers/modal'
import snackbar from './reducers/snackbar'
import localization from './reducers/localization'
import workoutEditor from '../components/workout-editor/reducers/app'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default client => createStore(
  combineReducers({modal, form, workoutEditor, snackbar, localization}),
  undefined,
  composeEnhancers(applyMiddleware(thunkMiddleware.withExtraArgument(client)))
)

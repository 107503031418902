import React, {useContext, useState} from 'react'
import moment from 'moment'
import {editStage} from '../../actions/workout'
import _, {cloneDeep, pick, difference, find, remove, join, map, keys, groupBy, upperFirst} from 'lodash'
import { Button, Card, CardContent, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core'
import ParameterSet from './StageParameterSet'
import {editStyle} from './Week/style'
import {useActions} from "../../../../helpers/useActions";
import {useWorkout, WorkoutContext} from '../../WorkoutContext';

const weekDays = ['monday','tuesday', "wednesday",
                  "thursday", "friday", "saturday", "sunday", 'monday','tuesday', "wednesday",
                  "thursday", "friday", "saturday", "sunday"]
const schedules =  ["morning", "afternoon", "evening"]

// TODO: add useEditStage
const EditStage = props => {
  const actions = useActions({editStage});

  const [week, setWeek] = useState(cloneDeep(props.weeks[0]));

  const { workout } = useWorkout()

  props = {...props, ...actions};

  const renderTitle = () => {
    return 'Edit Stage';
    {/*<View style={{flexDirection: 'row', justifyContent: 'space-between'}}>*/}
    {/*  <Text style={{fontSize: 20}}>Edit Stage</Text>*/}
    {/*<FormControl>*/}
    {/*  <InputLabel htmlFor="add-day">Add day</InputLabel>*/}
    {/*  <Select*/}
    {/*    onChange={this.addDay}*/}
    {/*    inputProps={{*/}
    {/*      name: 'add-day',*/}
    {/*      id: 'add-day'*/}
    {/*    }}>*/}
    {/*    {this.scheduledDays().map((day, i) => (*/}
    {/*      <MenuItem key={i} value={day}>{upperFirst(day)}</MenuItem>))}*/}
    {/*  </Select>*/}
    {/*</FormControl>*/}
    {/*</View>*/}
  }

  const renderHeader = () => {
    const {exercise: {name}, stageIndex, weeks} = props;

    // get start_date
    // get end_date
    const {0: {start_date}, [weeks.length - 1]: {end_date}} = weeks;

    this.start_date = start_date;
    this.end_date = end_date;

    return (
      <div>
        <Text type="subheading">
          {'Exercise: '} <span className="value">{name}</span>
        </Text>
        <div className="scheduleDetail">
          <Text style={styles.scheduleLabel}>
            Stage: <span className="value">{stageIndex}</span>
          </Text>
          {/*<Text style={styles.scheduleLabel}>*/}
          {/* Week: <span className="value">{weekIndex + 1}</span>*/}
          {/* All weeks*/}
          {/*</Text>*/}
          <Text style={styles.scheduleLabel}>
            {'Dates: '}
            <span className="value">
              {join([start_date, end_date].map(getDateStr), ' - ')}
            </span>
          </Text>
        </div>
      </div>)}

  const renderStage = () => {
    // const groupedParams = groupBy(this.state.parameter_sets, 'day_of_week')
    // const dayIndex = indexOf(weekDays, lowerCase(this.start_date.format('dddd')))
    // const days = slice(weekDays, dayIndex, dayIndex + 7)

    // only render 1 set of params to update all days of the stage with
    const paramSet = week.parameter_sets[0];

    return paramSet && (
      <View style={editStyle.weekDayCard}>
        {/*{this.getDate(index)}*/}
        {/*{this.renderAddRoutineSelector(paramSet)}*/}
        {renderParam(paramSet)}
        {/*{params.map(this.renderParam)}*/}
      </View>
    )

  //   return days.map((day, index) => {
  //     const params = groupedParams[day]
  //     return params && (
  //       <Card key={index} className="exerciseCard">
  //         <CardContent>
  //           <View style={editStyle.weekDayCard}>
  //             {this.getDate(index)}
  //             {this.renderAddRoutineSelector(params)}
  //           </View>
  //           {params.map(this.renderParam)}
  //           {/*{this.renderWeekDay(params)}*/}
  //         </CardContent>
  //       </Card>)})
  }

  const renderAddRoutineSelector = params =>
      <div>
        <InputLabel>Add new time</InputLabel>
        <Select
          value={0}
          onChange={(e, i, v) => addRoutine(params, v)}>
          {difference(schedules, map(params, 'daily_index'))
            .map((dailyIndex) =>
              <MenuItem key={dailyIndex} value={dailyIndex}>
                {upperFirst(dailyIndex)}
              </MenuItem>)}
        </Select>
      </div>

  const renderWeekDay = (params) =>
    params.map(renderParam)

  const renderParam = (paramSet) =>
    paramSet.daily_index && (
      <ParameterSet
        paramSet={paramSet}
        addParam={addParam}
        editParam={editParam}
        rmParam={rmParam}/>
    )

  const scheduledDays = () =>
    difference(weekDays, map(week.parameter_sets, 'day_of_week'))

  const editParamSet = param =>
    setWeek(({parameter_sets}) => {
      find(parameter_sets, param).open = true; return {parameter_sets}
    });

  const addDay = (e, index, day_of_week) =>
    setWeek(({parameter_sets}) =>
      parameter_sets.push({day_of_week, __typename: "ParameterSet"})
    );

  const addRoutine = ([{day_of_week}], daily_index) =>
    setWeek(({parameter_sets}) => (
      parameter_sets.push({day_of_week, daily_index, parameters: [], __typename: "ParameterSet"}))
    );

  const removeParamSet = param =>
    setWeek(({parameter_sets}) => {
      remove(parameter_sets, param);
      return {parameter_sets}
    });

  const addParam = (paramSet, label) =>
    setWeek(({parameter_sets}) => {
      parameter_sets[0].parameters.push({label, __typename: 'Parameters'});
      return {parameter_sets};
    });

  const editParam = (paramSet, {label, value}) =>
    setWeek(({parameter_sets}) => {
      find(parameter_sets[0].parameters, {label}).value = value.toString();
      return {parameter_sets};
    });

  const rmParam = (paramSet, label) => {
    setWeek(({parameter_sets}) => {
      remove(parameter_sets[0].parameters, {label})
      return {parameter_sets};
    })};

  const saveStage = () => {
    const {editStage, exercise, closeModal} = props;

    // update each week in the stage
    // get modified param set
    const modifiedParameters = week.parameter_sets[0].parameters;
    // todo: rework this, update all parameters
    const weeks = cloneDeep(props.weeks);
    weeks.map((week, index) => {
      for (let i = 0; i < week.parameter_sets.length; i++) {
        week.parameter_sets[i].parameters = modifiedParameters;
      }
      editStage(workout.id, week, props.weeks[index], exercise);
    });

    closeModal()
  };

  const getDate = (index) => {
    const DAY_DATE_OBJ = moment(props.week.start_date)
    DAY_DATE_OBJ.date(DAY_DATE_OBJ.date() + index)
    return (
      <View>
        <Text type="subheading">{DAY_DATE_OBJ.format('dddd')}</Text>
        <Text>{getDateStr(DAY_DATE_OBJ)}</Text>
      </View>
    )
  }

  {/*<Dialog*/}
  {/*  open={true}*/}
  {/*  actions={[*/}
  {/*  ]}*/}
  {/*  onClose={props.closeModal}*/}
  {/*  maxWidth="md"*/}
  {/*  fullWidth={false}>*/}
  {/*</Dialog>*/}

  return (
    <React.Fragment>
      <DialogTitle>{renderTitle()}</DialogTitle>
      <DialogContent>
        {renderHeader()}
        <div>
          {renderStage()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.closeModal}>Cancel</Button>
        <Button variant="text" color="primary" onClick={saveStage}>Save</Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default EditStage

const getDateStr = (date) => date.format  ('MMM D')

const isPastPeriod = (period) => moment().diff(period.end_date) > 0

const styles = {
  scheduleLabel: {marginVertical: 10, marginRight: 10},
  addTimeLabel: {fontSize: 13, top: 5},
  addDayLabel: {fontWeight: '400', fontSize: 15, top: 5}
}

const findParam = (week, paramSet, label) =>
  find(find(week, paramSet).parameters, {label})

import { strings } from '../localization'

export default function(state = { language: 'en', strings}, action) {
  switch(action.type) {
    case 'CHANGE_LANGUAGE': {
      strings.setLanguage(action.language || state.language)
      return Object.assign(
        {}, state, {
          language: action.language,
          strings
        }
      )
    }
  }
  return state
}

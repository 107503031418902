import ReactDOM from 'react-dom'
import { Query } from 'react-apollo'
import { graphql } from '../../helpers/graphql'
import { currentProvider } from '../graphql/query/client'
import { o_assessmentInfo } from '../graphql/query/assessment'
import { client } from '../graphql/query/client'
import { toggleModal } from '../../config/actions/modal'
import { Route, Switch, Redirect } from 'react-router-dom'
import Parser from 'html-react-parser'
import Paper from '@material-ui/core/Paper'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { GlobalModalContext } from '../common/GlobalModal'
import WorkoutEditor from '../workout-editor/WorkoutEditor'
import Summary from '../summary'
import AssessActions from '../assessments/actions'
import { ClientAssessments as Assessments } from '../assessments/clientAssessments'
import { useModal } from '../common/Modal'
import { Toolbar } from '../common/Toolbar'
import { Button } from '../common/Button'
import NewAssessment from '../assessments/new'
import { Header as ClientHeader } from '../clients/clientHeader'
import { ProviderForm as Assessment } from '../assessments/form'
import { style } from './style'

const ActionButtonPortal = Parser(document.getElementById('actions').outerHTML)

export default class Profile extends Component {
  render() {
    const { match: {path, url, params}, history, location, currentProvider } = this.props
    return (
      <Query
        query={client}
        variables={{ id: params.id }}
        fetchPolicy="network-only"
      >
        {({data, loading, error}) => {
          if (error) return <Text>Something went wrong...</Text>
          if (loading) return <Text>Loading...</Text>
          return (
            <View style={style.index}>
              <View style={style.header}>
                <ClientHeader
                  url={url}
                  history={history}
                  params={params}
                  client={data.client}
                  Actions={ActionButtonPortal}
                />
              </View>
              <View style={style.content}>
                <Paper>
                  <Switch>
                    <Route
                      path={`${path}/assessments/:assessId`}
                      render={props =>
                        <ClientAssessment
                          client={data.client}
                          {...props}
                          redirect={`${url}/assessments`}
                        />
                      }
                    />
                    <Route
                      path={`${path}/assessments`}
                      render={props =>
                        <ClientAssessments client={data.client} {...props} />
                      }
                    />
                    <Redirect
                      from={url}
                      to={`${url}/assessments`} />
                  </Switch>
                </Paper>
              </View>
            </View>
          )
        }}
      </Query>
    )
  }
}

@graphql(currentProvider)
class ClientAssessments extends Component {
  render() {
    const {
      data: { currentProvider },
      match: { url },
      history,
      client
    } = this.props
    return (
      <View>
        <View style={{paddingTop: 15, backgroundColor: 'white', borderRadius: 5}}>
          <Toolbar
            buttons={[<NewAssessmentButton client={client} provider={currentProvider} />]} />
        </View>
        <Assessments
          uuid={client.uuid}
          onPress={({id}) => history.push(`${url}/${id}`)}
          history={history}
          url={url} />
      </View>
    )
  }
}

const NewAssessmentButton = props => {
  const { strings } = useSelector(state => state.localization);
  const { openModal } = useContext(GlobalModalContext);

  return (
    <React.Fragment>
      <Button
        color="#343435"
        text={strings.newAssessment}
        onPress={() => openModal({
          title: strings.newAssessment,
          modal: <NewAssessment {...props} />
        })}
      />
    </React.Fragment>
  )
};

@graphql(
  o_assessmentInfo,
  {options: props => (
    {variables: {id: props.match.params.assessId}})})
class ClientAssessment extends Component {
  componentDidMount() {
    const search = trimStart(this.props.location.search, '?r=')
    if (search) this.props.history.replace(search)
  }

  render() {
    const {
      match: {url}, history,
      data: {error, o_assessment}
    } = this.props

    return (
      <View>
        {error
          ? <View style={{alignItems: 'center', justifyContent: 'center', padding: 20}}>
              <Text style={{paddingBottom: 15}}>
                This assessment has been deleted and cannot be accessed anymore.
              </Text>
              <Button
                color="#343435"
                text="Back to Home"
                onPress={() => history.push(this.props.redirect)}
              />
            </View>
          : <Switch>
              <Route
                exact
                path={`${url}/form`}
                render={() =>
                  this.withPortal(
                    <Assessment
                      key={uniqueId()}
                      id={o_assessment && o_assessment.id}
                      onSubmit={() => history.push(`${url}/summary`)}
                    />
                  )
                }
              />
              <Route
                exact
                path={`${url}/summary`}
                render={() =>
                  this.withPortal(
                    <Summary
                      id={o_assessment && o_assessment.id}
                      key={uniqueId()}
                      onBuildWorkout={(workoutId) => history.push({
                          pathname: `${url}/workout`,
                          state: { workout: workoutId }
                        })
                      }
                    />
                  )
                }
              />
              {/* <Route
                exact
                path={`${url}/workout-old`}
                render={() =>
                  this.withPortal(
                    <WorkoutEditorOld key={uniqueId()} uuid={assessment.workoutId && assessment.workoutId} />
                  )
                }
              /> */}
              <Route
                exact
                path={`${url}/workout`}
                render={() =>
                  this.withPortal(<WorkoutEditor key={uniqueId()} uuid={o_assessment.workoutId && o_assessment.workoutId} traineeProfile={this.props.client} />
                  )
                }
              />
              <Redirect
                from={url}
                to={`${url}/${this.assessmentURL()}`}/>
            </Switch>
        }
      </View>
    )
  }

  withPortal = (Component) => (
    [ReactDOM.createPortal(
      this.assessmentActions(),
      document.getElementById('actions')),
     Component]
  )

  assessmentActions = () => {
    const { url } = this.props.match
    return (
      <AssessActions
        assess={this.props.data.o_assessment}
        url={url}
        {...this.props}
      />
    )
  }

  assessmentURL = () => {
    const {o_assessment} = this.props.data
    if (o_assessment.workoutId) return 'workout'
    else if (o_assessment.score) return 'summary'
    else return 'form'
  }
}

const getUrl = ({children, location: {pathname}}) => (
  some(children, ({props: {path}}) => pathname.includes(path)))

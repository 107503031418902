import React, { memo, useState, useContext } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Mutation } from 'react-apollo'
import { GlobalModalContext } from '../../../common/GlobalModal'
import { editExercises } from '../../graphql/workout'
import moment from 'moment'
import arrayMove from 'array-move'
import Avatar from '@material-ui/core/Avatar'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import DeleteExercise from '../Modals/DeleteExercise'
import ExerciseDetail from './Exercise/ExerciseDetail'
import Stages from './Stages'
import {ICON_SECONDARY_COLOR, ICON_SECONDARY_COLOR_HOVER} from '../../styles/index'
import AddStage from "../Modals/AddStage";

const ICON_COLOR = '#d4d2d2'

const PADDING_LEFT_PERCENT = 20

const SortableItem = SortableElement(({exercise, index, ...props}) => (
  <Exercise
    {...props}
    key={index}
    exerciseIndex={index}
    exercise={exercise}
  />
));

const SortableList = SortableContainer(({exercises, ...props}) => {
  return (
    <div className="wb-wlist-exerciselist">
      {exercises.map((exercise, i) => {
        return (
          <SortableItem
            key={uniqueId()}
            {...props}
            index={i}
            exercise={exercise}
          />
        )
      })}
    </div>
  )
});

const Exercises = (props) => {
  let [exercises, setExercises] = useState(props.workout.exercises);

  if (props.sort === 0) {
    exercises = orderBy(props.workout.exercises, 'position', 'asc');
  } else {
    exercises = orderBy(props.workout.exercises, exercise => exercise.rationale.measure, 'asc');

    // detect each first unique measure
    const a = 5;
    const uniqueExercises = uniqBy(exercises, exercise => exercise.rationale.measure);
    const measures = map(uniqueExercises, exercise => exercise.rationale.measure);
    for (let measure of measures) {
      const id = findIndex(exercises, exercise => exercise.rationale.measure === measure);
      exercises[id].rationale.first = true;
    }
  }

  // old class code
  // componentDidUpdate(prevProp, prevState) {
  //   // // todo: not sure what this is for exactly yet
  //   // if (prevProp.workout.exercises !== this.props.workout.exercises) {
  //   //   this.setState({ exercises: orderBy(this.props.workout.exercises, ['position'], ['asc']) })
  //   // }
  // }

  const onSortEnd = ({ oldIndex, newIndex }, mutation) => {
    setExercises(arrayMove(exercises, oldIndex, newIndex));
    // old class code
    // this.setState(({exercises}) => ({
    //   exercises: arrayMove(exercises, oldIndex, newIndex),
    // }))
    const updatedOrder = exercises.map((exr, index) => {
      exr['position'] = index;
      return exr;
    });
    mutation({
      variables: {
        workout_id: props.id,
        exercises: updatedOrder.map(exr => pick(exr, ['position', 'exercise_id']))
      }
    })
  };

  return (
    <Mutation mutation={editExercises}>
      {(editExercises, { error }) => {
        if (error) return <Text style={{ alignSelf: 'center', padding: 20 }}>Something went wrong. Please try again later.</Text>
        return (
          <SortableList
            {...props}
            helperClass="dragging"
            exercises={exercises}
            onSortEnd={(indexParams) => onSortEnd(indexParams, editExercises)}
            pressDelay={500}
          />
        )
      }}
    </Mutation>
  );
}

export default Exercises

export const Exercise = memo((props) => {
  const [ showDetail, setShowDetail ] = useState();
  const { exercise, exercise: {name, image_urls}, workout, sort} = props;

  const toggleDetail = () => setShowDetail(!showDetail);

  const { openModal } = useContext(GlobalModalContext);

  return (
      // <ListItem>
      //   <ListItemAvatar>
      //     <Avatar src={image_urls[0]} size={57}
      //             backgroundcolor="lightgray" color="white"
      //             onClick={this.toggleDetail}>
      //   </ListItemAvatar>
      // </ListItem>
      <div>
        {sort === 1 && exercise.rationale.first &&
          <div className="exercise-measure">
            {exercise.rationale.measure}
          </div>
        }
        <li className="exercise-strip">
          <div className="exercise-thumb">
            <div className="img">
              <Avatar src={image_urls[0]} size={57}
                      backgroundcolor="lightgray" color="white"
                      onClick={toggleDetail}>
                {!image_urls[0]
                    ? name.match(/^[a-zA-Z]{2}/).join('').toUpperCase()
                    : null}
              </Avatar>
            </div>
            <span className="label">{name}</span>
            <InfoIcon className="infoIcon"
                      htmlColor={ICON_COLOR}
                      onClick={toggleDetail}/>
            {workout.exercises.length &&
              <div>
                <DeleteIcon className="deleteIcon"
                            htmlColor={ICON_SECONDARY_COLOR}
                            hovercolor={ICON_SECONDARY_COLOR_HOVER}
                            onClick={() => openModal({
                              modal: <DeleteExercise exercise={exercise} />,
                              free: true
                            })}
                            style={{color: 'rgba(200, 200, 200, 0.4)',
                              cursor: 'default'}}/>
              </div>
            }
          </div>
          {showDetail &&
            <ExerciseDetail {...props} closeExerciseDetail={this.toggleDetail}/>}
          <Stages {...props}/>
        </li>
      </div>
  );
});

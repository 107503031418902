import { useState, useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Mutation } from 'react-apollo'
import { request_access } from '../graphql/mutation/client'
import { toggleSnackbar } from '../../config/actions/snackbar'
import { useDispatch } from 'react-redux'
import { GlobalModalContext } from '../common/GlobalModal'

const ClientClaim = props => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const { closeModal } = useContext(GlobalModalContext)

  const handleChange = event => {
    setEmail(event.target.value)
  }

  const onComplete = err => {
    const message = err
    ? 'User does not exist! Please try another email.'
    : 'Claim request sent!'
    dispatch(toggleSnackbar(message))
    closeModal()
  }

    return (
      <Mutation
        mutation={request_access}
        onError={() => onComplete('error')}
        onCompleted={() => onComplete()}
      >
        {(request_access, { loading }) => {
          return (
            <View>
              <Text>Please enter an email address of an existing client you wish to claim.</Text>
              <TextField
                style={{ marginTop: 20 }}
                placeholder="Email"
                value={email}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              <View style={{ flexDirection: 'row-reverse' }}>
                <TouchableOpacity
                  style={{ marginTop: 20, width: 150 }}
                  onPress={() => {
                    request_access({
                      variables: {
                        client_email: email,
                        provider_id: props.data.currentProvider.id
                      }
                    })
                  }}
                >
                  {loading
                    ? <ActivityIndicator />
                    : <Button>Claim</Button>
                  }
                </TouchableOpacity>
              </View>
            </View>
          )
        }}
      </Mutation>
    )

}

export default ClientClaim

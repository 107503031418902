import {cloneDeep, reject, concat, pick, dropWhile, curryRight, differenceBy, differenceWith} from "lodash"
import moment from 'moment'
import { useStore } from 'react-redux';
import {editWorkout, editExercises, editPeriods, getWorkout, getWorkoutMeta} from '../graphql/workout.graphql'
import Exercise from '../graphql/fragments/exercise.graphql'
import {weekParamSets, stageParamSets} from '../helpers/ExerciseParameters'
import {useApolloClient, useMutation} from "@apollo/client";

export const addWeek = ({start_date, end_date}, exercise, levelData) =>
  (_, getState, client) => {
    const {workoutEditor: {workoutId}} = getState()
    const period = {
      start_date,
      end_date,
      parameter_sets: levelData ? weekParamSets(exercise, levelData) : weekParamSets(exercise)
    }
    return client.mutate({
      mutation: editPeriods,
      variables: {periods: [{...period, program_exercise_id: exercise.id}],
                  workout_id: workoutId},
      //optimisticResponse: {periods: [period]},
      update: (client, {data: {periods}}) => {
        const data = client.readFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`})
        data.periods = [...data.periods, ...periods]
        client.writeFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`, data})
      }
    })
  }

export const editWeek = (workoutId, updatedPeriod, period, exercise) =>
  (_, getState, client) => {
    const updatedPsets = updatedPeriod.parameter_sets.filter(({parameters}) =>
      parameters && parameters.length)
    const deletedPsets = differenceWith(period.parameter_sets, updatedPsets, equalPset)
          .map(pset => ({...pset, delete: true}))
    const periodGraph = formatPeriod({
      ...updatedPeriod,
      parameter_sets: [...deletedPsets, ...updatedPsets],
      program_exercise_id: exercise.id})
    const optimisticPeriod = {
      ...updatedPeriod,
      parameter_sets: updatedPsets.map((pset, i) => (
        {...pset, id: pset.id || -i, completed: false}))}
    return client.mutate({
      mutation: editPeriods,
      variables: {periods: [periodGraph], workout_id: workoutId},
      //optimisticResponse: {periods: [optimisticPeriod]},
      update: (client, {data: {periods}}) => {
        const data = client.readFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`})
        data.periods = [...differenceBy(data.periods, periods, 'id'), ...periods]
        client.writeFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`, data})
      }
    })
  }

export const editStage = (workoutId, updatedPeriod, period, exercise) =>
  (_, getState, client) => {
    const updatedPsets = updatedPeriod.parameter_sets.filter(({parameters}) =>
      parameters && parameters.length)
    const deletedPsets = differenceWith(period.parameter_sets, updatedPsets, equalPset)
      .map(pset => ({...pset, delete: true}))
    const periodGraph = formatPeriod({
      ...updatedPeriod,
      parameter_sets: [...deletedPsets, ...updatedPsets],
      program_exercise_id: exercise.id})
    const optimisticPeriod = {
      ...updatedPeriod,
      parameter_sets: updatedPsets.map((pset, i) => (
        {...pset, id: pset.id || -i, completed: false}))}
    return client.mutate({
      mutation: editPeriods,
      variables: {periods: [periodGraph], workout_id: workoutId},
      //optimisticResponse: {periods: [optimisticPeriod]},
      update: (client, {data: {periods}}) => {
        const data = client.readFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`})
        data.periods = [...differenceBy(data.periods, periods, 'id'), ...periods]
        client.writeFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`, data})
      }
    })
  };

export const deleteWeek = (week, exercise) =>
  (_, getState, client) => {
    const {workoutEditor: {workoutId}} = getState()
    const period = {...pick(week, ['id', 'start_date', 'end_date']),
                    program_exercise_id: exercise.id, delete: true}
    return client.mutate({
      mutation: editPeriods,
      variables: {periods: [period],  workout_id: workoutId},
      //optimisticResponse: {periods: []},
      update: (client, _) => {
        const data = client.readFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`})
        data.periods = reject(data.periods, ['id', period.id])
        client.writeFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`, data})
      }
    })
  }

export const useAddStage = (workoutId, stage, exercise, levelData) => {
  const updatedParams = map(levelData, exr => {
    const p = map(exr, e => {
      return {
        label: e.parameter_type_name,
        value: e.value
      }
    })
    return p
  });

  const periods = dropWhile(stage.weeks, weekHasPassed)
    .map((period, i) => ({
      ...pick(period, ['start_date', 'end_date']),
      parameter_sets: stageParamSets(exercise, updatedParams, i)
    }))

  console.log('periods', periods);
  console.log('exerciseId', exercise.id);
  console.log('variables', {
    periods: periods.map(period => ({...period, program_exercise_id: parseInt(exercise.id)})),
    workout_id: workoutId
  });



  return useMutation(
    editPeriods, {
      variables: {
        periods: periods.map(period => ({...period, program_exercise_id: parseInt(exercise.id)})),
        workout_id: workoutId
      },
      //optimisticResponse: {periods},
      update: (cache, {data: {periods}}) => {
        console.log('id', `OcpWorkoutExercise:${exercise.id}`);

        const data = cache.readFragment({
          fragment: Exercise,
          fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`
        });

        console.log('data', data);

        data.periods = [...data.periods, ...periods];
        cache.writeFragment({
          fragment: Exercise,
          fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`,
          data
        });
      },
      onCompleted: (data) => {
        console.log('data', data);
      },
      onError: (error) => {
        console.log('error', error);
      }
    }
  );
}

export const addStage = (workoutId, stage, exercise, levelData) =>
  (_, getState, client) => {
    const {workoutEditor: {workoutId}} = getState()
    const updatedParams = map(levelData, exr => {
      const p = map(exr, e => {
        return {
          label: e.parameter_type_name,
          value: e.value
        }
      })
      return p
    })
    const periods = dropWhile(stage.weeks, weekHasPassed)
          .map((period, i) => (
            {...pick(period, ['start_date', 'end_date']),
             parameter_sets: stageParamSets(exercise, updatedParams, i)}))
    return client.mutate({
      mutation: editPeriods,
      variables: {periods: periods
                  .map(period => ({...period, program_exercise_id: exercise.id})),
                  workout_id: workoutId},
      //optimisticResponse: {periods},
      update: (client, {data: {periods}}) => {
        const data = client.readFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`})
        data.periods = [...data.periods, ...periods]
        client.writeFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`, data})
      }
    })
  }

export const useDeleteStage = (workoutId, stage, exercise) => {
  const periods = dropWhile(stage.weeks, weekHasPassed)
    .map(period => ({
      ...pick(period, ['id', 'start_date', 'end_date']),
        program_exercise_id: parseInt(exercise.id),
        delete: true
    }));

  return useMutation(editPeriods, {
    mutation: editPeriods,
    variables: {
      periods,
      workout_id: workoutId
    },
    //optimisticResponse: {periods: []},
    update: (cache) => {
      const data = cache.readFragment({
        fragment: Exercise,
        fragmentName: 'Exercise',
        id: `OcpWorkoutExercise:${exercise.id}`
      });
      data.periods = differenceBy(data.periods, periods, 'id');
      cache.writeFragment({
        fragment: Exercise,
        fragmentName: 'Exercise',
        id: `OcpWorkoutExercise:${exercise.id}`,
        data
      });
    }
  });
}

export const deleteStage = (stage, exercise) =>
  (_, getState, client) => {
    const {workoutEditor: {workoutId}} = getState()
    const periods = dropWhile(stage.weeks, weekHasPassed)
          .map(period => (
            {...pick(period, ['id', 'start_date', 'end_date']),
             program_exercise_id: exercise.id,
             delete: true}))
    return client.mutate({
      mutation: editPeriods,
      variables: {periods,  workout_id: workoutId},
      //optimisticResponse: {periods: []},
      update: (client) => {
        const data = client.readFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`})
        data.periods = differenceBy(data.periods, periods, 'id')
        client.writeFragment({
          fragment: Exercise, fragmentName: 'Exercise',
          id: `OcpWorkoutExercise:${exercise.id}`, data})
      }
    })
  }

export const addExercises = (workoutId, exercises) =>
  (_, getState, client) => {
    exercises = exercises.map(exrToWrktExr)
    return client.mutate({
      mutation: editExercises,
      variables: {exercises: exercises.map(exr => pick(exr, ['position', 'exercise_id'])), workout_id: workoutId},
      //optimisticResponse: {exercises},
      update: (client, {data: {exercises}}) => {
        const data = cloneDeep(client.readQuery({query: getWorkout, variables: {id: workoutId}}))
        data.workout.exercises = [...exercises, ...data.workout.exercises]
        client.writeQuery({query: getWorkout, data})
      }
    })
  }

export const deleteExercise = ({exercise_id, id, name}) =>
  (_, getState, client) => {
    const {workoutEditor: {workoutId}} = getState()
    return client.mutate({
      mutation: editExercises,
      variables: {exercises: [{delete: true, exercise_id}],
                  workout_id: workoutId},
      optimisticResponse: {exercises: []},
      update: (client, _) => {
        const data = cloneDeep(
          client.readQuery({query: getWorkout, variables: {id: workoutId}}))
        data.workout.exercises = reject(data.workout.exercises, {id})
        client.writeQuery({query: getWorkout, data})
      }
    })
  }

export const useDeleteExercise = (workoutId, exercise_id, id, name) => {
  return useMutation(editExercises, {
    variables: {
      exercises: [{delete: true, exercise_id}],
      workout_id: workoutId
    },
    optimisticResponse: {exercises: []},
    update: (client, _) => {
      const data = cloneDeep(client.readQuery({query: getWorkout, variables: {id: workoutId}}))
      console.log('useDeleteExercises data', data);
      data.workout.exercises = reject(data.workout.exercises, {id})
      client.writeQuery({query: getWorkout, data})
    }
  })
}

export const useEditDescription = (workout, instructions, exercise) => {
  exercise.instructions = instructions;
  return useMutation(editExercises, {
    variables: {exercises: [pick(exercise, ['instructions', 'exercise_id'])], workout_id: workout.id},
    optimisticResponse: {exercises: [exercise]}
  });
}

export const editDescription = (instructions, exercise) =>
  (_, getState, client) => {
    const {workoutEditor: {workoutId}} = getState()
    exercise.instructions = instructions
    return client.mutate({
      mutation: editExercises,
      variables: {exercises: [pick(exercise, ['instructions', 'exercise_id'])],
                  workout_id: workoutId},
      optimisticResponse: {exercises: [exercise]}
    })
  }

export const useModifyWorkout = (workoutId, name, endDate) => {
  const client = useApolloClient();
  const workout = client
    .readQuery({query: getWorkoutMeta, variables: {id: workoutId}})
    .workout;
  if (name !== undefined)
    workout.name = name;
  if (endDate !== undefined)
    workout.end_date = endDate.format('Y-MM-DD');
  return useMutation(editWorkout, {
    variables: {workout: pick(workout, ['id', 'name', 'start_date', 'end_date'])},
    optimisticResponse: {workout}
  });
}

export const renameWorkout = (name) =>
  (_, getState, client) => {
    const {workoutEditor: {workoutId}} = getState()
    const workout = client
          .readQuery({query: getWorkoutMeta, variables: {id: workoutId}})
          .workout
    workout.name = name
    return client.mutate({
      mutation: editWorkout,
      variables: {workout: pick(workout, ['id', 'name', 'start_date', 'end_date'])},
      optimisticResponse: {workout}
    })
  }

const weekHasPassed = ({end_date}) =>
  moment().diff(end_date) > 0

const exrToWrktExr = (exr, position) => (
  {...pick(exr, ['name', 'instructions', 'image_urls']),
   exercise_id: exr.id,
   position,
   video_url: '',
   periods: [],
   __typename: 'OcpWorkoutExercise'})

const formatPeriod = period => (
  {...pick(period, ['id', 'start_date', 'end_date', 'exercise_id']),
   parameter_sets:
   period.parameter_sets.map(pset => (
     {...pick(pset, ['id', 'start_date', 'end_date', 'daily_index', 'day_of_week', 'delete']),
      parameters: pset.parameters.map(({__typename, ...param}) => param)}))})

const equalPset = (s1, s2) =>
  s1.day_of_week == s2.day_of_week
    && s1.daily_index == s2.daily_index

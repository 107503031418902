import Avatar from '@material-ui/core/Avatar'
import { userProfile as style } from './style'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import { changeLanguage } from '../../config/actions/localization'

@connect(null, {changeLanguage})
export default class extends Component {
  state = { anchorEl: null }
  render() {
    const { anchorEl } = this.state
    const { user, logout, profileRoute, ome } = this.props
    if (!user) { return null }

    const {first_name, last_name, organization} = user
    const fullName = `${first_name} ${last_name}`

    return (
      <View>
        <TouchableOpacity
          onPress={this.handleClick}
          style={style.profileView}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
        >
          <View style={style.userInformation}>
            <Text style={style.name}>{fullName}</Text>
            {organization && <Text style={style.subtTitle}>{organization.name}</Text>}
          </View>
          <Avatar
          >
            {fullName.charAt(0)}
          </Avatar>
        </TouchableOpacity>
        {logout &&
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                width: 200
              }
            }}
          >
            <Text style={style.submenu}>Options</Text>
            {!ome && <MenuItem onClick={this.handleProfile}>Profile</MenuItem>}
            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            {!ome && <Text style={style.submenu}>Languages</Text>}
            {!ome && <MenuItem onClick={() => this.handleLanguageChange('en')}>English</MenuItem>}
            {!ome && <MenuItem onClick={() => this.handleLanguageChange('cn')}>Chinese</MenuItem>}
          </Menu>
        }
      </View>
    )
  }
  handleLanguageChange = language => {
    this.props.changeLanguage(language)
    this.handleClose()
  }
  handleProfile = () => {
    this.props.profileRoute()
    this.handleClose()
  }
  handleLogout = () => {
    this.props.logout()
    this.handleClose()
  }
  handleClick = event => {

    console.log('clicked')
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
}

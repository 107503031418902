import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useContext} from 'react'
import { GlobalModalContext } from '../../../common/GlobalModal'

const ConfirmModal = props => {
  const {modalClass='sm', title, children} = props;
  const {closeModal} = useContext(GlobalModalContext);

  const triggerAction = () => {
    closeModal();
    props.action();
  };

  return (
    <React.Fragment>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal}>Cancel</Button>
        <Button variant="text" color="primary" onClick={triggerAction}>Confirm</Button>
      </DialogActions>
    </React.Fragment>
    )
}

export default ConfirmModal

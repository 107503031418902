import React, { useState } from 'react'
import {AppBar, Toolbar} from '@material-ui/core'
import UserProfile from './UserProfile'
import { useQuery } from '@apollo/react-hooks'
import { ocpBar as style } from './style'
import { client } from '../graphql/query/client'
import MailOutline from '@material-ui/icons/MailOutline'
import PeopleOutline from '@material-ui/icons/PeopleOutline'
import CalendarToday from '@material-ui/icons/CalendarToday'
import HelpOutline from '@material-ui/icons/HelpOutline'
import Notifications from './Notifications'

const OcpBar = props => {
  const { url, user, logout, clientsRoute, profileRoute,
          provider, logo, calendarRoute, msgsRoute, tutorialRoute } = props
  const { loading, data, error, refetch } = useQuery(client, {
    variables: { id: props.provider && props.provider.id },
    fetchPolicy: "network-only",
    pollInterval: 60000
  })

  const renderNavigation = () => {
    const { unread_messages, id } = data.client
    if (loading) return <ActivityIndicator />
    return (
      <React.Fragment>
        <UserProfile profileRoute={profileRoute} user={data.client} logout={logout} />
        {provider && <Notifications history={props.history} messages={unread_messages} id={id} />}
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <TouchableOpacity
            style={{ marginRight: 24 }}
            onPress={() => clientsRoute && clientsRoute()}
            >
            <PeopleOutline style={{ fontSize: 26 }} htmlColor="gray" />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginRight: 24 }}
            onPress={() => calendarRoute && calendarRoute()}
            >
            <CalendarToday style={{ fontSize: 21 }} htmlColor="gray" />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginRight: 24 }}
            onPress={() => msgsRoute && msgsRoute()}
            >
            <MailOutline style={{ fontSize: 24 }} htmlColor="gray" />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginRight: 24 }}
            onPress={() => tutorialRoute && tutorialRoute()}
            >
            <HelpOutline style={{ fontSize: 24 }} htmlColor="gray" />
          </TouchableOpacity>
        </View>
      </React.Fragment>
    )
  }
  return (
    <AppBar>
      <Toolbar>
        <View style={style.flex}>
          <View style={{ cursor: 'pointer' }} onClick={() => clientsRoute && clientsRoute()}>

            <View style={{ height: 45 }}>
              <img style={{ height: 40, flex: 1 }} src={logo}/>
            </View>
          </View>
          <View style={style.profileContainer}>
            {provider && data && renderNavigation()}
          </View>
        </View>
      </Toolbar>
    </AppBar>
  )
}

export default OcpBar

export default (state = {modalOpen: false}, action) => {
  switch (action.type) {
  case 'SAVE_WORKOUT_ID':
    return {...state, workoutId: action.id}
  case 'OPEN_MODAL':
    return {...state, modalOpen: true, modal: action.modal}
  case 'CLOSE_MODAL':
    return {...state, modalOpen: false}
  default: return state
  }
}

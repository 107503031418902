export const style = {
  index: {
    marginTop: 60,
  },
  header: {
    flex: 1,
    position: 'fixed',
    zIndex: 1000,
    width: '100%',
    paddingTop: 20,
    backgroundColor: 'gainsboro',
  },
  content: {
    marginTop: 45,
    paddingTop: 90,
    paddingBottom: 20,
    paddingHorizontal: 20,
    borderRadius: 5
  }
}

export default function(state = { open: false }, action) {
  switch(action.type) {
    case 'TOGGLE_MODAL': {
      return Object.assign(
        {}, state, {
          open: action.open || !state.open,
          modal: action.modal,
          title: action.title,
          onSubmit: action.submit
        }
      )
    }
  }

  return state
}

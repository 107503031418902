import { Mutation } from 'react-apollo'
import Send from '@material-ui/icons/Send'
import { message } from '../graphql/mutation/message'
import style from './style'

export default class MessageInput extends Component {
  state = { text: '' }
  render() {
    return (
      <Mutation mutation={message}>
        {(message, { error }) => {
          if (error) return <Text style={{ alignSelf: 'center', paddingTop: 5 }}>
                              Something went wrong, please try again.
                            </Text>
          return (
            <View style={style.inputContainer}>
              <View style={style.innerContainer}>
                <TextInput
                  style={style.msgInput}
                  onChangeText={(text) => this.setState({text})}
                  value={this.state.text}
                  autoFocus
                  inputRef={(input) => { this.nameInput = input }}
                  placeholderTextColor="gainsboro"
                  placeholder="Type your message here"
                  onKeyPress={e => {
                    if(e.key === 'Enter'){
                        this.state.text !== '' && this.sendMessage(message)
                    }
                  }}
                />
                <TouchableOpacity style={style.sendBtn} onPress={() => this.sendMessage(message)}>
                  <Send htmlColor="white" size={20} />
                </TouchableOpacity>
              </View>
            </View>
          )
        }}
      </Mutation>
    )
  }
  sendMessage = mutation => {
    const { client, provider, scrollEnd } = this.props
    mutation({
      variables: {
        content: this.state.text,
        sender: provider,
        receivers: [client]
      }
    })
    scrollEnd({ animated: true })
    this.setState({ text: '' })
    this.nameInput.focus()
  }
}

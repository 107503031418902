
import {connect} from 'react-redux'
import {addWeek} from '../../../actions/workout'
import ConfirmModal from '../ConfirmModal'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import style from '../../../styles/modal.css'

@connect(null, {addWeek})
export default class AddWeek extends Component {
  state = {
    value: '1',
  };
  render() {
    const {
      week,
      week: { weekIndex, stageIndex },
      exercise,
      addWeek
    } = this.props

    const levelData = groupBy(exercise.level_data, 'level')

    return (
      <ConfirmModal
        action={() => addWeek(week, exercise, levelData[this.state.value])}
        modalClass="sm"
      >
        <Text type="subheading">
          Add <span className="secondary">
            {` Week ${weekIndex + 1} `}</span>
          to <span className="secondary">
            {` Stage ${stageIndex + 1} `}</span>
          for <span className="primary">
            {exercise.name}
          </span>?
        </Text>
        {!isEmpty(levelData) &&
          <View style={{ paddingTop: 10 }}>
            <FormControl component="fieldset">
              <Text style={{ paddingVertical: 10 }}>Please select difficulty level:</Text>
              <RadioGroup
                aria-label="Select Level"
                name="level"
                value={this.state.value}
                onChange={this.handleChange}
                style={{ flexDirection: 'row' }}
              >
                {map(levelData, (exr, i) => (
                  <FormControlLabel
                    key={uniqueId()}
                    value={exr[0].level.toString()}
                    control={<Checkbox color="primary" />}
                    label={`level ${exr[0].level}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </View>
        }
      </ConfirmModal>
    )
  }
  handleChange = event => {
    this.setState({ value: event.target.value })
  }

}

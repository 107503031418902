import Button from '@material-ui/core/Button'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react';

export const useModal = (props) => {
  props = props || {};

  // if passed a single argument, assume it's the modal
  if (React.isValidElement(props))
    props = {modal: props};

  if (props.modal === undefined)
    props.modal = <View><Text>No modal</Text></View>;

  const {onSubmit} = props;
  const [open, setOpen] = useState(false);
  const [m, setModal] = useState(props.modal);
  const [title, setTitle] = useState(props.title);
  const [dialogProps, setDialogProps] = useState(props.dialogProps || {});
  const [isFree, setIsFree] = useState(false);

  const openModal = props => {
    // if passed a single argument, assume it's the modal
    if (React.isValidElement(props))
      props = {modal: props};

    if (props.modal)
      setModal(props.modal);

    setTitle(props.title || undefined);
    setDialogProps(props.dialogProps || {});
    setIsFree(props.free || false);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const clonedModal = React.cloneElement(m, {...props, closeModal});

  console.log('global modal props', props);

  const Modal = isFree ?
    <Dialog
      open={open}
      onClose={closeModal}
      disableEnforceFocus={true}
      {...dialogProps}
    >
      {clonedModal}
    </Dialog>
    :
    <Dialog
      open={open}
      onClose={closeModal}
      disableEnforceFocus={true}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {clonedModal}
      </DialogContent>
      {onSubmit &&
      <DialogActions>
        <Button onClick={closeModal} color="primary">Cancel</Button>
        <Button onClick={onSubmit} color="primary">Confirm</Button>
      </DialogActions>
      }
    </Dialog>;

  return {openModal, closeModal, setModal, Modal};
};

export const Modal = ({modal, open, toggleModal, title, onSubmit, description}) => {
  // const [open, setOpen] = useState(false);
  // const [m, setModal] = useState(modal);
  // const [title, setTitle] = useState(title);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      disableEnforceFocus={true}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {modal}
      </DialogContent>
      {onSubmit &&
      <DialogActions>
        <Button onClick={closeModal} color="primary">Cancel</Button>
        <Button onClick={onSubmit} color="primary">Confirm</Button>
      </DialogActions>
      }
    </Dialog>
  )
};

export const GlobalModalContext = React.createContext(Modal);

export const GlobalModalProvider = ({children}) => {
  const props = useModal();
  const {Modal} = props;

  return (
    <GlobalModalContext.Provider value={props}>
      {children}
      {Modal}
    </GlobalModalContext.Provider>
  );
}

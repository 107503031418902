import { scoreInfo } from '../../../../helpers/assessment'
import { style } from '../../style'
import Detail from './detail'

export default class Measure extends Component {
  state = {selected: false}
  render() {
    const { selected } = this.state
    const { msr: {name, weight, id, display, msrs, description},
            assessment_type, components, ob, last, assessent_name, msr } = this.props
    const cardio = assessment_type.name === 'Cardio'
    // until category is populated
    // const outcome = assessment_type.category === 'Outcome Measure'
    const outcome = true
    const scoreOb = ob || this.scoreOb(id)

    // const score = scoreOb && cardio ? ((scoreOb.score - 5) * 2.5) + 10 : (5 - scoreOb.score) * 2.5
    const score = 0
    const percent = scoreOb &&
                    score * 10
    const assessInfo = scoreInfo(percent, assessment_type)
    const hasMsrs = msr.components

    if (display == "Multi") {
      return msr.components.map((msr, index) => {
        var ob = this.scoreOb(msr.id)
        return ob && <Measure
                       {...this.props}
                       ob={ob}
                       key={index}
                       msr={msr}
                       assessment_type={assessment_type} />})}

    return (
      <View style={last ? style.msrCard : [style.msrCard, style.msrBorder]}>
        <TouchableOpacity onPress={this.toggleSelected}>
          <View key={uniqueId()}>
            <View style={style.msrContainer}>
              <MeasureScore
                outcome={outcome}
                value={percent}
                thickness={6}
                backgroundColor={assessInfo.color}
                label={score}
              />
            {hasMsrs
              ? <Text style={style.msrName}>{name}</Text>
              : <Text style={style.msrName}>{description[0]}</Text>
            }
            </View>
            <View>
              {this.state.selected &&
                <React.Fragment>
                  {this.props.msr.rationale && <Text style={{ fontSize: 12, color: 'gray', paddingLeft: 75 }}>{this.props.msr.rationale}</Text>}
                  <View style={style.msrDetails}>
                    <Detail
                      {...this.props}
                      hasMsrs={hasMsrs}
                      color={assessInfo.color}
                      score={score} scoreOb={this.scoreOb}
                    />
                  </View>
                </React.Fragment>
              }
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  // scoreOb = id => find(this.props.obs, { msr: { id }})

  scoreOb = id => {
    return find(this.props.obs, ob => ob.id === id)
  }

  toggleSelected = () => this.setState({selected: !this.state.selected})
}

class MeasureScore extends Component {
  render() {
    const {label='N/A', value, backgroundColor='#a0c96f', outcome} = this.props
    return (
      <View style={[style.scoreContainer, outcome ? { backgroundColor: 'gainsboro' } : { backgroundColor }]}>
        <Text style={style.scoreTxt}>{outcome ? 'N/A' : label}</Text>
      </View>
    )
  }
}

import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

export const useModal = (props) => {
  props = props || {};

  // if passed a single argument, assume it's the modal
  if (React.isValidElement(props))
    props = {modal: props};

  if (props.modal === undefined)
    props.modal = <View><Text>No modal</Text></View>;

  const {onSubmit} = props;
  const [open, setOpen] = useState(false);
  const [m, setModal] = useState(props.modal);
  const [title, setTitle] = useState(props.title);

  const openModal = props => {
    // if passed a single argument, assume it's the modal
    if (React.isValidElement(props))
      props = {modal: props};

    if (props.modal)
      setModal(props.modal);

    if (props.title)
      setTitle(props.title);

    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const clonedModal = React.cloneElement(m, {...props, closeModal});

  const Modal =
    <Dialog
      open={open}
      onClose={closeModal}
      disableEnforceFocus={true}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {clonedModal}
      </DialogContent>
      {onSubmit &&
      <DialogActions>
        <Button onClick={closeModal} color="primary">Cancel</Button>
        <Button onClick={onSubmit} color="primary">Confirm</Button>
      </DialogActions>
      }
    </Dialog>;

  return {openModal, closeModal, setModal, Modal};
};

export const Modal = ({modal, open, toggleModal, title, onSubmit, description}) => {
  return (
    <Dialog
      open={open}
      onClose={() => toggleModal()}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {modal}
      </DialogContent>
      {onSubmit &&
      <DialogActions>
        <Button onClick={() => toggleModal()} color="primary">Cancel</Button>
        <Button onClick={() => onSubmit()} color="primary">Confirm</Button>
      </DialogActions>
      }
    </Dialog>
  )
};

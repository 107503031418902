import _ from 'lodash';

export function intersection(formattedParameters) {
  return _.reduce(formattedParameters, (accumulator, value, key) => {
    if(key === 0) {
      accumulator = value;
    } else {
      accumulator = _.intersectionWith(accumulator, value, _.isEqual);
    }

    return accumulator
  })
}

export function uniqFormatted(parameters) {
  let formattedParameters = parameters.map((parameter) => {
    return {
      label: parameter.label,
      value: parameter.value,
      isFreeText: parameter.label === 'free text'
    }
  })

  return _.uniqWith(formattedParameters, _.isEqual);
}

export function state(parameters) {
  let targetParameters = uniqFormatted(parameters);

  if(targetParameters.length > 1) {
    return 'varied'
  } else if (targetParameters.length === 1) {
    return 'same';
  } else if (targetParameters.length === 0) {
    return '-'
  } else {
    return 'error'
  }
}

export function belongsToParameterSets(parameter_sets, filter_options = {}) {
  let temp_parameter_sets = _.clone(parameter_sets);
  let label = filter_options.label;
  let dayOfWeek = filter_options.dayOfWeek;

  if(dayOfWeek) {
    temp_parameter_sets = temp_parameter_sets.filter(parameter_set => parameter_set.day_of_week === dayOfWeek);
  }

  let parameters = _.flatten(temp_parameter_sets.map(parameter_set => parameter_set.parameters));

  if(label) {
    parameters = parameters.filter(parameter => parameter.label === label);
  }

  return parameters
}

export const cardioBPM = value => {
  if (value === '1') {
    return `${value} (101 - 114 BPM)`
  } else if (value === '2') {
    return `${value} (114 - 128 BPM)`
  } else if (value === '3') {
    return `${value} (128 - 141 BPM)`
  } else if (value === '4') {
    return `${value} (141 - 155 BPM)`
  } else if (value === '5') {
    return `${value} (155 - 168 BPM)`
  } else {
    return false
  }
}

import ApolloClient from 'apollo-client'
import { ApolloLink, HttpLink } from '@apollo/client'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { persistCache } from 'apollo3-cache-persist'
import { withClientState } from 'apollo-link-state'

export const client = async (uri, fragmentData) => {
  const fragmentMatcher =
    fragmentData &&
    new IntrospectionFragmentMatcher({introspectionQueryResultData: fragmentData})


  const cache = new InMemoryCache(
  fragmentMatcher
    ? {
        fragmentMatcher,
        dataIdFromObject: o => {
          switch (o.__typename) {
            case 'Event': return o._id ? `${o.__typename}:${o._id}`: null
            default: return o._id ? `${o.__typename}:${o._id}` : (o.id ? `${o.__typename}:${o.id}` : null)
          }
        }
      }
    : {
        dataIdFromObject: o => {
          switch (o.__typename) {
            case 'Event': return o._id ? `${o.__typename}:${o._id}`: null
            default: return o._id ? `${o.__typename}:${o._id}` : (o.id ? `${o.__typename}:${o.id}` : null)
          }
        }
      })

  const stateLink = withClientState({
    cache,
    resolvers: {
      Query: () => ({})
    },
    defaults: {
      currentUser:
      JSON.parse(await AsyncStorage.getItem('currentProvider'))}
  })

  await persistCache({
   cache,
   storage: AsyncStorage,
   trigger: 'write',
    maxSize: false
  });

   const httpLink = new HttpLink({
     uri
   })

  const authMiddleware = setContext(async (req, { headers }) => {
    const token = await AsyncStorage.getItem('access_token')
    return {
      ...headers,
      headers: {
        auth: token
      }
    }
  })

  const link = ApolloLink.from([
    stateLink,
    authMiddleware,
    // new BatchHttpLink({uri}),
    httpLink
  ])

  return new ApolloClient({
    link,
    cache,
    resolvers: {}
  })
}

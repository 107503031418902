import React, {useContext} from 'react'
import moment from 'moment'
import {isEqual, join, capitalize, each, flatten, filter, compact, flatMap, partition, sortBy, uniqBy, some} from 'lodash'
import Weeks from './Weeks'
import {
  uniqFormatted,
  belongsToParameterSets,
  intersection,
  state,
  cardioBPM
} from '../../helpers/Parameters'
import {IconButton} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteStage from '../Modals/DeleteStage'
import BlockIcon from '@material-ui/icons/Block'
import EditIcon from '@material-ui/icons/Edit'
import RepeatIcon from '@material-ui/icons/Repeat'
import {ICON_PRIMARY_COLOR, ICON_PRIMARY_COLOR_HOVER, ICON_SECONDARY_COLOR, ICON_SECONDARY_COLOR_HOVER} from '../../styles/index'
import AddStage from '../Modals/AddStage'
import EditStage from '../Modals/EditStage';
import {WorkoutContext} from "../../WorkoutContext";
import Workout from "../Workout";

export default (props) => {
  const {viewingStage, exercise: {stages}} = props
  return (
    <div className="exercise-schedule-strip">
      {viewingStage
        ? <Stage {...props}
                 stage={stages[viewingStage-1]}
                 stageIndex={viewingStage}
                 lastStage={viewingStage == stages.length-1}/>
        : stages.map((stage, i) =>
          <Stage {...props}
                 key={i}
                 stage={stage}
                 stageIndex={i+1}
                 lastStage={i == stages.length-1}/>)}
    </div>)
}

// TODO: was PureComponent
// export class Stage extends PureComponent {
export const Stage = props => {
  const { workout } = useContext(WorkoutContext);

  const renderSummary = () => {
    const {stage, stage: {weeks}, stageIndex, viewingStage, openModal, exercise} = props

    return (
      <div className="stageSummaryContent" style={viewingStage == stageIndex ? {width: 0} : {}}>
        {renderStageParameterSets(weeks)}
        <EditStageButton onClick={e => {
          e.stopPropagation();
          openModal({
            modal: <EditStage stageIndex={stageIndex} exercise={exercise} weeks={weeks} workout={workout} {...props} />,
            free: true
          })}} />
        {hasRemovableWeeks() &&
        <DeleteStageButton onClick={e => {
          e.stopPropagation()
          openModal({
            modal: <DeleteStage stage={stage} stageIndex={stageIndex} exercise={exercise} workout={workout} {...props} />,
            free: true
          })}}/>}
      </div>)
  }

  // TODO: significant refactor

  const getMin = (data) => {
    return data && data.reduce((minParamValue, p) => p.value < minParamValue ? p.value : minParamValue, data[0].value)
  }
  const getMax = (data) => {
    return data && data.reduce((maxParamValue, p) => p.value > maxParamValue ? p.value : maxParamValue, data[0].value)
  }

  const renderStageParameterSets = (weeks) => {
    const allLabelsArray = []
    return (
      <div>
        {allLabels(weeks)
          .map(({label, value, isFreeText}, i) => {
            const getWeeklyParams = weeks[i].parameter_sets && weeks[i].parameter_sets.map(set => {
              set.parameters.map(param => {
                allLabelsArray.push(param)
              })
            })
            const groupedParams = groupBy(allLabelsArray, x => x.label)
            const stages = [0,1,2,3].map(
              weekIndex => weeklyParameterStatusOfLabel(label, weekIndex))
            const same = stages.every((val, i, arr) => val === arr[0])
            const sameParameter = sameParametersAcrossWeeks(weeks, label === 'Zone' && { label })
              .find(parameter => parameter.label === label)
            const minParamValue = getMin(groupedParams[label])
            const maxParamValue = getMax(groupedParams[label])
            return (
              <p key={i}>
                <span className='label'>
                  {`${capitalize(isFreeText ? value : label)}: `}
                </span>
                <span className='value'>
                  {same && label === 'Zone' || stages.includes('varied')
                    ? minParamValue === maxParamValue ? cardioBPM(minParamValue) : `${minParamValue} ~ ${maxParamValue}`
                    : sameParameter
                      ? sameParameter.value
                      : same && label === 'Zone'
                        ? cardioBPM(value)
                        : join(stages.map((stage, i) => {
                          return (stage === 'same')
                            ? isFreeText ? 'Y' : getSameValueInWeek(label, i)
                            : stage
                        }), ', ')}
                </span>
              </p>)})}
      </div>
    )
  }

  const renderAddStageToExercise = () => (
    <div className="cell add" onClick={addStage}>
      <span className="text">Add Stage</span>
    </div>)

  const renderDisabledStage = () => (
    <div className="cell add disabled">
      <span className="text">
        <span className="material-icons disabled-icon">
          <BlockIcon className="blockIcon" /*color="d0c8c8"*//>
        </span>
      </span>
    </div>
  )

  const allLabels = (weeks) =>
    flatten(
      partition(allParameters(weeks), 'isFreeText')
        .map(params => sortBy(uniqBy(params, 'label'), 'label')))

  const allParameters = (weeks) =>
    uniqFormatted(
      belongsToParameterSets(
        compact(flatMap(weeks, 'parameter_sets'))))

  // Some serious problems with this one
  const sameParametersAcrossWeeks = (weeks, label) =>
    intersection(weeks.map(({parameter_sets}) =>
      parameter_sets
        ? uniqFormatted(belongsToParameterSets(parameter_sets, { label }))
        : {}))

  const getSameValueInWeek = (label, weekIndex) => {
    const {weeks} = props.stage
    return belongsToParameterSets(weeks[weekIndex].parameter_sets,
      {label})[0].value
  }

  const weeklyParameterStatusOfLabel = (label, weekIndex) => {
    const {parameter_sets} = props.stage.weeks[weekIndex]
    return parameter_sets
      ? state(belongsToParameterSets(parameter_sets, {label}))
      : '-'
  }

  const addStage = () => {
    const {exercise, stage, stageIndex, openModal, closeModal} = props;

    openModal({
      modal: <AddStage stage={stage} exercise={exercise} stageIndex={stageIndex} closeModal={closeModal} workout={workout} />,
      free: true
    });
  }

  const hasRemovableWeeks = () => {
    const {weeks} = props.stage
    return some(weeks, ({id, end_date}) => id && moment().diff(end_date) < 0)
  }

  const canAddStage = () => moment().diff(props.stage.end_date) < 0

  const {exercise, stage, stage: {weeks}, stageIndex, lastStage, viewingStage, stageViewClick} = props
  return (
    <div
      className={`cell stage ${lastStage ? ' last' : ''}`}
      data-value={stageIndex}
      onClick={() => some(weeks, 'parameter_sets')
                      ? stageViewClick(stageIndex)
                      : null}>
      <div className="stageCell">
        {viewingStage == stageIndex && <Weeks exercise={exercise} weeks={weeks}/>}
        {some(weeks, 'parameter_sets')
          ? renderSummary()
          : canAddStage()
            ? renderAddStageToExercise()
            : renderDisabledStage()}
      </div>
    </div>
  )
}

const EditStageButton = (props) => (
  <IconButton {...props} className="editIconBtn">
    <EditIcon htmlColor={ICON_SECONDARY_COLOR} className="editIcon" hovercolor={ICON_SECONDARY_COLOR_HOVER} />
    </IconButton>);

const DeleteStageButton = (props) => (
  <IconButton {...props} className="deleteIconBtn">
    <DeleteIcon htmlColor={ICON_SECONDARY_COLOR} className="deleteIcon" hovercolor={ICON_SECONDARY_COLOR_HOVER}/>
    </IconButton>);
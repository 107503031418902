import React, { Component } from 'react'
import { graphql, Query } from 'react-apollo'
import { useQuery } from '@apollo/client'
import { filter, update, reject, sortBy, set, map, find } from 'lodash'
import { o_assessment } from '../graphql/query/assessment'
import {serializeAssess, setPosition} from '../../helpers/assessment'
import formActions from './formActions'
import Cached from '@material-ui/icons/Cached'
import { Image } from 'react-native-web'
import Header, { HeaderTitle } from '../common/Header'
import { formatDate } from '../../helpers/time'
import { isMobile } from 'react-device-detect'
import { SubmitButton } from './submit'
import { formStyle as style} from './style'
import { MeasureView, PanelView } from './views'

@formActions
class Form extends Component {
  state = { description: false, width: width }

  componentDidMount() {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth)
  }

  updateWidth = () => this.setState({ width: document.body.clientWidth })

  render() {
    const { assessment, onSubmit, retractOb, postObservation, refetch, ome } = this.props
    const { assessment_type: { description },
           created_at, components, client, obs, assessment_name } = assessment && assessment
    const ViewComponent = isMobile ? PanelView : ScrollView

    const msrs = sortBy(setPosition(components), 'position')
    const selfMsrs = filter(msrs, 'self')

    // reject hidden and optional measures for assess msr bubbles status
    const statusMsrs = reject(msrs, msr => msr.display === 'Hidden' || msr.optional)

    // reject hidden measures (Cardio)
    const measures = reject(msrs, msr => msr.display === 'Hidden')
    console.log(this.props, "props")
      return (
        <View>
          <View
            style={
              !ome
              ? [style.msrStatusContainer, { width: this.state.width - 40 }]
              : style.omeMsrStatusContainer
            }
            >
            <Header
              headerTitle={(
                <HeaderTitle
                  title={`${assessment_name} Form`}
                  style={{marginTop: 10}}
                  onPress={this.toggleBlurb}
                  textItems={[formatDate(created_at)]}
                  />
              )}
              />
            {!ome
              ? <TouchableOpacity style={style.refreshBtn} onPress={() => refetch()}>
              <Cached htmlColor="black" size={30} />
            </TouchableOpacity>
            : null
          }
          <Text style={
              this.state.description
              ? style.padding
              : { display: 'none' }
            }
            >
            {description}
          </Text>
          <View style={style.msrStatus}>
            {statusMsrs.map((msr, index) => {
              let parentObStatus
              if (msr.components) {
                // map through to get component msr ids and find corresponding ob
                const observations = msr.components.map(measure => find(obs, ob => ob.id === measure.id))

                // filter completed observations
                const doneObs = observations.filter(ob => ob.value)
                // mark done if done obs length === observations length
                parentObStatus = doneObs.length === observations.length



                //////////////////////////////// TEST THIS
                // const doneObs2point0 = filter(msr.components.map(measure => {
                //   const getOb = find(obs, ob => ob.id === measure.id)
                //   return !!getOb.value
                // }), doneOb => !!doneOb)
                //
                // // const filteredObs = doneObs2point0.filter(obs => !!ob)
                // console.log(doneObs2point0, "filtered obs that are completed")
                ////////////////////////////////

              } else {
                // single layer can just check ob value
                const getOb = find(obs, ob => ob.id === msr.id)
                parentObStatus = getOb.value

              }

              return (
                <TouchableOpacity
                  key={uniqueId()}
                  onPress={() =>
                    window.scrollTo({
                      top: document.getElementsByClassName(`${msr.id}`)[0].offsetTop + 20,
                      behavior: 'smooth'
                    })
                  }
                  >
                  <Text style={[ style.undoneMsr, parentObStatus && style.doneMsr]}>{index + 1}</Text>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
        <ScrollView style={style.container}>
          <View style={!ome ? style.padding : { paddingHorizontal: 20, paddingBottom: 20 }}>
            <ViewComponent assessment={assessment}>
              {measures.map((msr, i, arr) => {
                return (
                  <div key={i} className={`${msr.id}`}>
                    <MeasureView
                      position={i+1}
                      protocol={msr.protocol}
                      last={arr.length - 1 === i}
                      isMobile={isMobile}
                      msr={msr}
                      retractOb={retractOb}
                      assessment={assessment}
                      postObservation={this.postOb}
                      />
                  </div>
                )
              })}
            </ViewComponent>
            {!isMobile &&
              <SubmitButton assessment={assessment} onSubmit={onSubmit} ome={ome} />}
              </View>
            </ScrollView>
          </View>
        )
    }
  // }

  // postOb = (assessment, client, msr, value, msrObj, type) => {
  //   const { obs } = this.props.assessment
  //   const ob = obs[msr]
  //   if (value === '') {
  //     if (ob) {
  //       return this.props.retractOb({ obId: ob.id })
  //     }
  //     return true
  //   } else if (msrObj && msrObj.type === "FITB") {
  //     return this.props.postObservation({
  //       assessment,
  //       client,
  //       msr,
  //       value: 0,
  //       freetext: value,
  //       obId: ob && ob.id
  //     })
  //   } else if (msrObj && msrObj.type === "Q&A") {
  //       if (type === 'text') {
  //         return this.props.postObservation({
  //           assessment,
  //           client,
  //           msr,
  //           value: 0,
  //           freetext: value,
  //           obId: ob && ob.id
  //         })
  //       } else {
  //         return this.props.postObservation({
  //           assessment,
  //           client,
  //           msr,
  //           value: parseFloat(value),
  //           obId: ob && ob.id
  //         })
  //       }
  //
  //   } else if (msr && (value || (typeof value == 'number'))) {
  //     this.props.postObservation({
  //       assessment,
  //       client,
  //       msr,
  //       value: parseFloat(value),
  //       obId: ob && ob.id
  //     })
  //   }
  // }

  postOb = (assessment, msr, value) => {
    const { obs } = this.props.assessment

    const ob = obs[msr]
    if (value === '') {
      if (ob) {
        return this.props.retractOb({ obId: ob.id })
      }
      return true
    } else {
      return this.props.postObservation({
        assessment_id: assessment,
        measurement: msr,
        value: value.toString(),
      })
    }
  }

  toggleBlurb = () => this.setState({ description: !this.state.description })
}

export const ProviderForm = props => {
  const { onSubmit, id } = props
  const { data, loading, error, refetch } = useQuery(o_assessment, {
    variables: { id },
    fetchPolicy: 'network-only'
  })

  if (loading) return <ActivityIndicator style={{ padding: 30 }} />
  if (error) return <Text style={{ padding: 20, textAlign: 'center' }}>Something went wrong. Please try again later.</Text>

  return (
    <Form
      assessment={data.o_assessment}
      onSubmit={onSubmit}
      refetch={refetch}
    />
  )

}


const { width } = Dimensions.get('window')

import React from 'react'
import { connect } from 'react-redux'
import { Mutation, graphql } from 'react-apollo'
import { View, Text, Button, Platform } from 'react-native-web'
import { Button as FlatButton } from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { create_client } from '../graphql/mutation/client'
import { validateEmail, validatePhone, required, validateBirthdate } from '../../helpers/validation'
import { normalizeDate, normalizePhone } from '../../helpers/normalize'
import { activation } from '../graphql/mutation/client'
import TextInput from '../common/textInput'
import { style } from './style'

@graphql(activation, {
  props: ({ownProps, mutate}) => ({
    sendEmail: variables => {
      return mutate({variables})}})})
@reduxForm({ form: 'client' })
export default class NewClientForm extends Component {
  render() {
    const { closeModal, sendEmail } = this.props;

    const columns = [
      { name: 'uuid', component: TextInput, style: { display: 'none' } },
      { label: 'First Name', name: 'first_name', component: TextInput, validate: required },
      { label: 'Last Name', name: 'last_name', component: TextInput, validate: required },
      { label: 'Email', name: 'email', component: TextInput, validate: validateEmail },
      // { label: 'Phone', name: 'phone', component: TextInput, validate: validatePhone, normalize: normalizePhone },
      { label: 'Birth Date (MM/DD/YYYY)', name: 'birth_date', component: TextInput, validate: validateBirthdate, normalize: normalizeDate },
      { label: 'Gender', name: 'gender', component: PickerComponent,
        style: style.genderPicker, validate: required,
        items: [{ value: null, label: 'Select Gender' },
                { value: 'M', label: 'Male' },
                { value: 'F', label: 'Female' }]}
    ];

    return (
      <Mutation
        mutation={create_client}
        onCompleted={(data) => {
          this.props.sendEmail({email: data.create_client.email}).then(() => this.props.history.push(`/client/${data.create_client.id}/assessments`))
        }}
      >
        {(create_client, { error }) => {
          return (
            <View style={style.modal}>
              <View style={style.modalRow}>
                <View style={style.modalColumn}>
                  {columns.map(field => <View style={{width: 350, flex: 1, paddingBottom: 10}}><Field {...field} /></View>)}
                  {error && <Text style={{ color: 'red', textAlign: 'center', paddingTop: 10 }}>Email already exists!</Text>}
                </View>
              </View>
              <View style={style.modalBtnContainer}>
                <FlatButton
                  variant="text"
                  onClick={this.props.handleSubmit(data => {
                    this.onSubmit(data, create_client)
                  })}>Create</FlatButton>
                <FlatButton variant="text"onClick={closeModal}>Cancel</FlatButton>
              </View>
            </View>
          )
        }}
      </Mutation>
    )
  }
  onSubmit = (values, createClient) => {
    const { closeModal, refetch, data } = this.props
    const clientVariables = {...values, language: "EN", provider: data.currentProvider.id}
    createClient({ variables: clientVariables }).then(() => {
      closeModal()
      refetch()
    })
  }
}


class PickerComponent extends React.Component {
  render() {
    const { input, items, meta: { touched, error, warning } } = this.props

    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: '#e5e5e5',
          borderRadius: 7,
          width: '100%',
          fontSize: 15,
          marginTop: 20,
        }}
      >
        <Picker onValueChange={input.onChange} selectedValue={input.value}>
          {items.map(({ label, value, enabled }, index) =>
            <Picker.Item key={index} label={label} value={value || label} />)}
        </Picker>
        {touched &&
         ((error &&
           <View>
             <Text style={{color: 'red'}}>{error}</Text>
           </View>) ||
          (warning && <Text>warning</Text>))}
      </View>
    )
  }
}

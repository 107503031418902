import React, { Component, useState } from 'react'
import ClearIcon from '@material-ui/icons/Clear'

import ExerciseImages from './Images'
import ExerciseDescription from './Description'

const ExerciseDetail = (props) => {
    const [ displayMedia, setDisplayMedia ] = useState('image');
    const { exercise, exercise: { name, instructions, image_urls, video_url }, closeExerciseDetail} = props;

    const renderMediaStateButtons = (value) => {
        // const {displayMedia} = this.state;
        const label = value === 'image' ? 'Images' : 'Videos';
        return (
            <span className={displayMedia === value ? 'active' : ''}
                  data-value={value}
                  onClick={onClickDataLabel}>
        {label}
      </span>
        )
    };

    const renderExerciseVideo = (video_url) =>
        <div className='exercise-video'>
            {video_url
                ? <video src={video_url} controls/>
                : <p className='no-video'>NO VIDEO</p>}
        </div>;

    const onClickDataLabel = (e) =>
        setDisplayMedia(e.target.dataset.value);

    return (
        <div className="exercise-detail">
            <div className='exercise-data'>
                <div className='exercise-data-label'>
                    {renderMediaStateButtons('image')} | {renderMediaStateButtons('video')}
                </div>
                {displayMedia === 'image'
                    ? <ExerciseImages urls={image_urls}/>
                    : renderExerciseVideo(video_url)
                }
            </div>
            <div className="info">
                <div className="name">{name}</div>
                <ExerciseDescription description={instructions} exercise={exercise}/>
            </div>
            <span className="close" onClick={closeExerciseDetail}>
                <ClearIcon/>
            </span>
        </div>
    );
}
export default ExerciseDetail

// export default class ExerciseDetail extends Component {
//   state = {displayMedia: 'image'}
//
//   render() {
//     const {exercise, exercise: {name, instructions, image_urls, video_url},
//            closeExerciseDetail} = this.props
//     const {displayMedia} = this.state
//     return (
//       <div className="exercise-detail">
//         <div className='exercise-data'>
//           <div className='exercise-data-label'>
//             {this.renderMediaStateButtons('image')} | {this.renderMediaStateButtons('video')}
//           </div>
//           {displayMedia === 'image'
//             ? <ExerciseImages urls={image_urls}/>
//             : this.renderExerciseVideo(video_url)
//           }
//         </div>
//         <div className="info">
//           <div className="name">{name}</div>
//           <ExerciseDescription description={instructions} exercise={exercise}/>
//         </div>
//         <span className="close" onClick={closeExerciseDetail}>
//           <ClearIcon/>
//         </span>
//       </div>
//     )
//   }
//
//   renderMediaStateButtons = (value) => {
//     const {displayMedia} = this.state
//     const label = value === 'image' ? 'Images' : 'Videos'
//     return (
//       <span className={displayMedia === value ? 'active' : ''}
//             data-value={value}
//             onClick={this.onClickDataLabel}>
//         {label}
//       </span>
//     )
//   }
//
//   renderExerciseVideo = (video_url) =>
//     <div className='exercise-video'>
//       {video_url
//         ? <video src={video_url} controls/>
//         : <p className='no-video'>NO VIDEO</p>}
//     </div>
//
//   onClickDataLabel = (e) =>
//     this.setState({displayMedia: e.target.dataset.value})
// }

import React, {useContext} from 'react';
import ClientTable from './ClientsTable'
import { clients_by_provider } from '../graphql/query/client'
import { Query } from 'react-apollo'
import NewClientForm from './form'
import { Button } from '../common/Button'
import { useModal } from '../common/Modal'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Paper } from '@material-ui/core'
import { useQuery } from '@apollo/client';
import { GlobalModalContext } from '../common/GlobalModal';
import ClaimClient from './clientClaim'

const ClientsTable = (props) => {
  const { strings } = useSelector(state => state.localization);

  const { loading, error, data, refetch } = useQuery(clients_by_provider, {
    variables: { id: props.data.currentProvider.id },
    fetchPolicy: 'network-only'
  });

  const { onSubmit } = props;

  const { openModal } = useContext(GlobalModalContext);

  return (
    <Paper>
      {data &&
        <React.Fragment>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', padding: 20}}>
            <Text style={{fontSize: 24}}>{strings.clientList}</Text>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ marginRight: 10 }}>
                <Button
                  color="#343435"
                  text="Claim Client"
                  onPress={() => openModal({
                    title: 'Claim a Client',
                    modal: <ClaimClient {...props} refetch={refetch} />
                  })}
                />
              </View>
              <Button
                color="#343435"
                text={strings.newClient}
                onPress={() => openModal({
                  title: strings.newClient,
                  modal: <NewClientForm {...props} refetch={refetch} />
                })}
              />
            </View>
          </View>
          <ClientTable {...props} clients={data && data.clients_by_provider} />
        </React.Fragment>
      }
    </Paper>
  )
}

export default ClientsTable

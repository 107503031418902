import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    photo: "Photo",
    clientStatus: "Client Status",
    clientList: "Client List",
    newClient: "New Client",
    clientActivated: "Client Activated",
    clientNotActivated: "Not Activated",
    profile: "Profile",
    viewProfile: "View Profile",
    noMatch: "Sorry, no matching records found",
    sort: "Sort",
    showColumns: "Show Columns",
    viewColumns: "View Columns",
    search: "Search",
    rowsPerPage: "Rows per page:",
    newAssessment: "New Assessment",
    selectAnAssessment: "Select an Assessment",
    created: "CREATED",
    workout: "WORKOUT",
    risk: "RISK",
    score: "SCORE",
    options: "OPTIONS",
    notCreated: "Not Created",
    enterYourId: "Enter Your ID",
    welcomeToOCP: "Welcome to OCP!",
    signin: "Sign in",
    loginError: "Invalid email or password. Please try again.",
    password: "Password",
    enterYourPw: "Enter Your Password"
  },
  cn: {
    firstName: "名字",
    lastName: "姓",
    email: "电子邮件",
    photo: "照片",
    clientStatus: "客户身份",
    clientList: "客户名单",
    newClient: "新客户",
    clientActivated: "客户激活",
    clientNotActivated: "客户端未激活",
    profile: "轮廓",
    viewProfile: "查看资料",
    noMatch: "没有找到匹配的记录",
    sort: "分类",
    showColumns: "显示列",
    viewColumns: "查看列",
    search: "搜索",
    rowsPerPage: "每页行数:",
    newAssessment: "新的评估",
    selectAnAssessment: "选择评估",
    created: "创建",
    workout: "锻炼",
    risk: "风险",
    score: "得分了",
    options: "选项",
    notCreated: "没有创建",
    enterYourId: "输入你的身份证",
    welcomeToOCP: "欢迎来到OCP!",
    signin: "登入",
    loginError: "无效的电子邮件或密码。请再试一次。",
    password: "密码",
    enterYourPw: "输入你的密码"
  },
});

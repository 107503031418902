import _ from 'lodash';

const dayLabels = { monday: 'M', tuesday: 'T', wednesday: 'W', thursday: 'Th', friday: 'F', saturday: 'S', sunday: 'Su' };
const dayOfWeeks = Object.keys(dayLabels);

export function daysHaveParameterSets(period) {
    if (!period) {
      return null;
    }

    let result = period.parameter_sets.map(parameter_set => parameter_set.day_of_week);

    return _.filter(dayOfWeeks, day => _.includes(result, day));
}

export function labelOfDaysHavingParameterSet(period) {
  if (!period) {
    return null
  }

  let daysHaveParameterSets = this.daysHaveParameterSets(period);

  return daysHaveParameterSets.map(day => dayLabels[day]);
}

export function sameDaysOverPeriods(periods) {
  periods = periods.filter(period => period !== undefined);
  let daysOfPeriods = periods.map(period => [this.labelOfDaysHavingParameterSet(period)]);
  return _.uniqWith(daysOfPeriods, _.isEqual)
}

import React, {useContext} from 'react'
import {connect} from 'react-redux'
import {useDeleteExercise} from '../../actions/workout'
import ConfirmModal from './ConfirmModal'
import {WorkoutContext} from '../../WorkoutContext'

const DeleteExercise = props => {
  const {exercise}  = props
  const {workout} = useContext(WorkoutContext)
  const [deleteExercise] = useDeleteExercise(workout.id, exercise)

  return (
    <ConfirmModal
     action={deleteExercise}
     modalClass="sm"
    >
      <Text type="subheading"> Are you sure you want to delete&nbsp;
        <span className="primary">{exercise.name}</span>?
      </Text>
    </ConfirmModal>
  )
}

export default DeleteExercise

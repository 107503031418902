import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import {HEADER_CONTROLS_COLOR,
        HEADER_CONTROLS_HOVER_COLOR} from '../../../styles'
export default class ExerciseImages extends React.Component {
  state = {
    index: 0
  }

  leftArrowClick = () => this.onArrowClick(-1)

  rightArrowClick = () => this.onArrowClick(1)

  onArrowClick = (value) => (
    this.setState({ index: this.state.index + value })
  )

  renderLeftArrow = (index) => (
    index === 0 ? false : true
  )

  renderRightArrow = (length, index) => (
    (index + 1 >= length) ? false : true
  )

  renderNoImages = () => (
    <div className="exercise-images">
      <p className="no-image">NO IMAGES</p>
    </div>
  )

  renderImages = (urls, length) => {
    const { index } = this.state
    return (
      <div className={ "exercise-images" + (length < 1 ? " single-image" : "") }>
        <div className="image" style={{ backgroundImage: 'url(' + urls[this.state.index] + ')'}}></div>
        <div className="controls">
          <IconButton
            onClick={ this.leftArrowClick }
            disabled={ !this.renderLeftArrow(index) }
            value= { 'left' }
          >
            <NavigateBeforeIcon
              className="material-icons"
              color={ HEADER_CONTROLS_COLOR }
              hovercolor={ HEADER_CONTROLS_HOVER_COLOR }
            />
          </IconButton>
          <IconButton
            onClick={ this.rightArrowClick }
            disabled={ !this.renderRightArrow(length, index) }
            value= { 'right' }
          >
            <NavigateNextIcon
              className="material-icons"
              color={ HEADER_CONTROLS_COLOR }
              hovercolor={ HEADER_CONTROLS_HOVER_COLOR }
            />
          </IconButton>
        </div>
      </div>
     )
  }

  render() {
    const urls = this.props.urls
    const length = urls.length
    return(
      <div>
        {length === 0 ? this.renderNoImages() : this.renderImages(urls, length)}
      </div>
    )
  }
}

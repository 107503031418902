import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { style } from './style'

export class ProgressCircle extends React.Component {
  render() {
    const {label='N/A', value, min=0, max=100, size=100, color='#3399FF', backgroundColor='gainsboro', thickness=5} = this.props
    return (
      <View style={{width: `${size * 2}px`, height: `${size * 2}px`}}>
        <Text style={[style.circleLabel, {fontSize: `${size * 2/6}px`}]}>
          {label}
        </Text>
        <View style={style.circles}>
          <View style={style.backgroundCircle}>
            <CircularProgress
              variant="static"
              thickness={thickness/2}
              size={size * 2}
              value={100}
              style={{color: backgroundColor}}
            />
          </View>
          <View style={style.valueCircle}>
            <CircularProgress
              variant="static"
              thickness={thickness/2}
              size={size * 2}
              value={value || 0}
              style={{color: color}}
            />
          </View>
        </View>
      </View>
    )
  }
}

class StartCheckboxGrid extends Component {
  render() {
    const { name, description, id, msrs } = this.props.msr
    const ob = this.props.assessment.obs[id]
    const value = ob && ob.value

    return (
      <View>
        {this.scheduleHeader(msrs)}
        {this.scheduleBody(msrs)}
      </View>
    )
  }

  scheduleHeader = msrs => (
    <View style={styles.headerView}>
      <Text style={styles.flex} />
      {msrs[0].optionables
              .map(({ option: { text }}, index) => (
                <Text style={[styles.flex, {textAlign: 'center'}]} key={index}>{text}</Text>))}
    </View>
  )

  scheduleBody = (days) => (
    <View style={{display: 'flex', paddingTop: 5}}>
      {days.map(({ id, name, optionables }, i, arr) => (
        <View style={arr.length - 1 === i ? styles.lastView : styles.bodyView} key={i}>
          <Text style={styles.flex}>{name}</Text>
          {optionables.map(({ option: { text }, position}, index) => {
             const ob = this.getOb(id)
             const value = ob && ob.value
             return (
               <View style={[styles.flex, {alignItems: 'center'}]} key={index}>
                 <CheckBox
                   style={styles.checkbox}
                   value={value == index}
                   onValueChange={
                     checked => this.handleSelect(id, index, checked)
                   }
                 />
               </View>
             )})}
        </View>))}
    </View>)

  renderScheduleHeader = () => (
    <View style={styles.headerView}>
      {['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        .map((header, index) => (
          <Text style={styles.flex} key={index}>{header}</Text>
        ))}
    </View>
  )

  renderScheduleBody = (days) => (
    <View style={{display: 'flex'}}>
      {['Morning', 'Afternoon', 'Evening']
        .map((time, i) => (
          <View key={i} style={styles.bodyView}>
            <Text style={styles.flex}>{time}</Text>
            {days.map(({optionables, id}, index) => {
               const ob = this.props.assessment.obs[id]
               const value = ob && ob.value
               return (
                 <View style={styles.flex}>
                   <CheckBox
                     style={styles.checkbox}
                     value={value == i}
                     onValueChange={
                       checked => this.handleSelect(id, i, checked)
                     }
                   />
                 </View>
               )})}
          </View>
        ))}
    </View>
  )

  handleSelect = (msrId, value, checked) => {
    const ob = this.getOb(msrId)
    if (ob) {
      if (!checked) {
        return this.props.retractOb({ obId: ob.id })
      }
    }
    this.props.postOb(value, msrId)
  }
  getOb = id => this.props.assessment.obs[id]
}

export default StartCheckboxGrid

const styles = {
  display: {
    display: 'flex',
    flexDirection: 'row'
  },
  flex: {
    flex: 1
  },
  headerView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkbox: {
    width: 15,
    height: 15
  },
  bodyView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 0.5,
    borderBottomColor: 'gainsboro',
    paddingVertical: 7,
    alignItems: 'center'
  },
  lastView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 7,
    alignItems: 'center'
  }
}

import { Image } from 'react-native-web'
// import client from '../../../graphql/query/client'
import { ptExercises } from '../graphql/query/workout'
import { setGoal } from '../graphql/mutation/client'
import { activation } from '../graphql/mutation/client'
import { toggleModal } from '../../config/actions/modal'
import { toggleSnackbar } from '../../config/actions/snackbar'
import _Header from '../common/Header'
import { headerStyle as style } from './style'
import { graphql, Mutation, Query } from 'react-apollo'
import { formatDate } from '../../helpers/time'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CheckCircle from '@material-ui/icons/CheckCircle'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

// @connect(null, { toggleModal })
export class Header extends React.Component {
  render() {
    const { history, url, client } = this.props
    if (!client) { return null }

    return (
      <View style={style.header}>
      <_Header
        headerLeft={
          <TouchableOpacity onPress={() => history.push(url)}>
            <ClientImage client={client} />
          </TouchableOpacity>
        }
        headerTitle={<ClientHeaderTitle user={client} />}
        headerRight={this.props.Actions}
      />
      </View>
    )
  }

  // initialValues = (client) => pick(
  //   client, ['first_name', 'last_name', 'email',
  //            'phone', 'birth_date', 'gender', 'uuid'])

  // editClient = (client) => this.props.toggleModal(
  //   <ClientForm initialValues={this.initialValues(client)} />)
}

@connect(null, {toggleSnackbar})
@graphql(activation, {
  props: ({ownProps, mutate}) => ({
    sendEmail: variables => {
      return mutate({variables})}})})
class ClientHeaderTitle extends Component {
  state = { openGoal: false, openPt: false, goals: '' }
  render() {
    const { user: { first_name, last_name, birth_date, uuid },
            user, sendEmail } = this.props

    return (
      <View>
        <View style={style.headerAssessmentTitle}>
          <Text style={style.title}>{`${first_name} ${last_name}`}</Text>
        </View>
        <Text style={style.subtitle}>{formatDate(birth_date)}</Text>
        <Email user={user} />
        <View style={{ flexDirection: 'row', marginTop: 5 }}>
          {this.clientGoals()}
          {!user.active &&
            <TouchableOpacity
              style={{ marginLeft: 10, backgroundColor: 'rgb(112, 205, 203)', paddingVertical: 5, paddingHorizontal: 25, borderRadius: 3 }}
              onPress={this.sendEmail}
            >
              <Text style={style.activationText}>Resend Activation</Text>
            </TouchableOpacity>
          }
          <Query
            query={ptExercises}
            variables={{ uuid }}
            fetchPolicy="no-cache"
          >
            {({ data, loading }) => {
              if (loading) return <ActivityIndicator style={{ marginLeft: 20 }} />
              if (data) {
                return this.ptHistory(data)
              }
              return null
            }}
          </Query>
        </View>
      </View>
    )
  }

  ptHistory = data => {
    const filteredExrs = uniqBy(data.client_pt_workout_ex2, exr => exr.exercise.name)
    return (
      <View>
        <TouchableOpacity
          onPress={() => this.handleClickOpen('openPt')}
          style={{ marginLeft: 10, background: '#FF6F68', paddingVertical: 5, paddingHorizontal: 25, borderRadius: 3 }}
        >
          <Text style={style.activationText}>P.T. History</Text>
        </TouchableOpacity>
        <Dialog
          open={this.state.openPt}
          onClose={() => this.handleClose('openPt')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle>{"Physical Therapy Exercise History"}</DialogTitle>
          <DialogContent>
            <View>
              {data && filteredExrs.map((exr, i, arr) => {
                const { image_urls, name, instructions } = exr.exercise
                return (
                  <View key={uniqueId()} style={arr.length - 1 === i ? style.ptLastExercise : style.ptExercise}>
                    {image_urls && image_urls.length > 0
                      ? <Avatar src={image_urls[0]} style={{ width: 60, height: 60 }} />
                      : <Avatar style={{  width: 60, height: 60 }}>N/A</Avatar>
                    }
                    <View style={style.ptData}>
                      <Text style={style.ptName}>{name}</Text>
                      <Text style={style.ptInstructions}>{instructions ? instructions : 'No instructions available.'}</Text>
                      <View style={style.ptParamContainer}>
                        {exr.parameters && exr.parameters.map(params => {
                          return (
                            <View style={style.ptParams}>
                              <Text style={style.paramValue}>{params.value}</Text>
                              <Text style={style.paramLabel}>{params.label}</Text>
                            </View>
                          )
                        })}
                      </View>
                    </View>
                  </View>
                )
              })}
            </View>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose('openPt')} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </View>
    )
  }

  clientGoals = () => {
    const {
      user: {
        first_name, last_name, birth_date, goals
      },
      user
    } = this.props
    return (
      <View>
        <TouchableOpacity
          style={{ backgroundColor: '#007AFF', paddingVertical: 5, paddingHorizontal: 25, borderRadius: 3 }}
          onPress={() => this.handleClickOpen('openGoal')}
        >
          <Text style={style.activationText}>Client Goals</Text>
        </TouchableOpacity>
        <Mutation
          mutation={setGoal}
          onCompleted={() => this.setState({ goals: '' })}
          >
          {(setGoal) => (
            <Dialog
              open={this.state.openGoal}
              onClose={() => this.handleClose('openGoal')}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>{"Client Goals"}</DialogTitle>
              <DialogContent>
                <View style={{ width: 400 }}>
                  {goals && goals.map(({goal, id, done}, index) => {
                    return (
                      <View key={id} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 3 }}>
                        <Text>{`${index + 1}. ${goal}`}</Text>
                        <TouchableOpacity
                          onPress={() =>
                            setGoal({
                              variables: {
                                user_id: user.id,
                                done: !done,
                                goal_id: id
                              },
                              refetchQueries: () => [`client`]
                            })
                          }
                        >
                          {done
                            ? <CheckCircle htmlColor="#007AFF" size={20} />
                          : <RadioButtonUnchecked htmlColor="gray" size={20} />
                          }
                        </TouchableOpacity>
                      </View>
                    )
                  })}
                  <TextField
                    label="Set a Goal"
                    onChange={this.handleChange('goals')}
                    multiline
                    value={this.state.goals}
                    rows="4"
                    margin="normal"
                    variant="outlined"
                  />
                </View>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose('openGoal')} color="primary">
                  Close
                </Button>
                <Button
                  onClick={() => this.handleSubmit(setGoal)}
                  color="primary"
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Mutation>
      </View>
    )
  }

  handleSubmit = mutation => {
    mutation({
      variables: {
        user_id: this.props.user.id,
        done: false,
        goal: this.state.goals
      },
      refetchQueries: () => [`client`]
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleClickOpen = (name) => {
    this.setState({ [name]: true })
  }

  handleClose = (name) => {
    this.setState({ [name]: false })
  }

  sendEmail = () => {
    this.props.sendEmail({email: this.props.user.email})
      .then((resp) => (
        console.log('the resp', resp) ||
        this.props.toggleSnackbar('Activation email has been sent.')))
  }
}

const Email = ({user: {active, email} }) => (
  active
    ? <Text style={style.subtitle}>{email}</Text>
    : <Text style={style.subtitle}>Client Not Activated</Text>
)

const ClientImage = ({client: {first_name, photo_url}}) => (
  photo_url ?
  <Image style={style.clientImg} source={{uri: photo_url}}/> :
  <Avatar style={{ width: 60, height: 60 }}>{first_name[0].toUpperCase()}</Avatar>
)

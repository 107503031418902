export const saveWorkoutId = (id) => (
  {type: 'SAVE_WORKOUT_ID', id})

export const openModal = (modal) => (
  {type: 'OPEN_MODAL', modal})

export const closeModal = () => (
  {type: 'CLOSE_MODAL'})

export const toggleSnackbar = (message) => (
  {type: 'TOGGLE_SNACKBAR', message}
)

import Paper from '@material-ui/core/Paper';
import useReactRouter from 'use-react-router';

import WorkoutEditorContainer from './containers/WorkoutEditorContainer'

const WorkoutEditor = props => {
  const { uuid, traineeProfile } = props;
  const { location } = useReactRouter();

  const workoutId = location.state && location.state.workout;

  return (
    <Paper>
      <div id="wb">
        <WorkoutEditorContainer id={workoutId || uuid} traineeProfile={traineeProfile} />
      </div>
    </Paper>
  )
}

export default WorkoutEditor

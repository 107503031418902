import Paper from '@material-ui/core/Paper'
import style from './style'

const content = [
  {
    title: 'Signup as New OCP',
    description: 'This video will show you how to get started and signup as an OCP.',
    video_url: 'https://optimis-university.s3.amazonaws.com/LMS/OME-OCP/OCP/Sign+Up+and+Login+OCP.mp4'
  },
  {
    title: 'Create and/or Claim a Client',
    description: 'This video will show you how to create a client from scratch. It will also show you how to claim any existing clients in our system.',
    video_url: 'https://optimis-university.s3.amazonaws.com/LMS/OME-OCP/OCP/Creating+a+New+Client+and+Claiming+a+PT+Client+OCP.mp4'
  },
  {
    title: 'Client Profile and Assessment Creation',
    description: "This video will show you how to go in to a client's profile page. It will also show you how to create an assessment.",
    video_url: 'https://optimis-university.s3.amazonaws.com/LMS/OME-OCP/OCP/Clients+Profile+Page+and+Creating+a+New+Assessment+OCP.mp4'
  },
  {
    title: 'Assess, Score, and Workout',
    description: 'This video will walk you through on how to do an assessment to completion, view the results, and build a workout based on those results.',
    video_url: 'https://optimis-university.s3.amazonaws.com/LMS/OME-OCP/OCP/Completing+an+Assessment%2C+Viewing+a+Score%2C+Building+a+Workout+OCP.mp4'
  }
]

export default class Tutorial extends Component {
  render() {
    return (
      <Paper style={{ padding: 20 }}>
        <Text style={style.title}>Tutorials</Text>
        <View style={style.contentContainer}>
          {content.map(guide => {
            return (
              <View style={style.content} key={uniqueId()}>
                <View style={style.titleContainer}>
                  <Text style={style.contentTitle}>{guide.title}</Text>
                </View>
                <video
                  src={guide.video_url}
                  width="418"
                  height="270"
                  controls
                />
              <View style={style.descriptionContainer}>
                  <Text style={style.contentDescription}>{guide.description}</Text>
                </View>
              </View>
            )
          })}
        </View>
      </Paper>
    )
  }
}

const { height } = Dimensions.get('window')

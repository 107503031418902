
import {Card, Avatar} from '@material-ui/core'

export default class ExerciseListItem extends React.Component {
  render() {
    const {exercise, exercise: {name, image_urls, instructions},
           inWorkout, selectExercise, index} = this.props
    return (
      <Card
        key={index}
        style={this.getStyle()}
        onClick={() => !inWorkout && selectExercise(exercise)}>
        {image_urls.length
          ? <Avatar src={image_urls[0]} style={styles.img} />
          : <Avatar style={styles.noImg}>N/A</Avatar>
        }
        <div className="exerCardDetail">
          <div className="exerName">{name}</div>
          <div>
            {instructions && `${instructions.substring(0, 100)}...`}
          </div>
        </div>
      </Card>)
  }

  getStyle = () => {
    const {inWorkout, isSelected} = this.props
    return (
      inWorkout
        ? styles.usedCard
        : isSelected
          ? styles.selectedCard
          : styles.card
    )
 }
}

const styles = {
  usedCard: {cursor: 'pointer', margin: '5px', display: 'flex', flex: '1 0 auto', width: '48%', height: '131px', border: '2px solid chartreuse', opacity: '.5'},
  selectedCard: {cursor: 'pointer', margin: '5px', display: 'flex', flex: '1 0 auto', width: '48%', height: '131px', border: '2px solid #1fbcd3'},
  card: {cursor: 'pointer', margin: '5px', display: 'flex', flex: '1 0 auto', width: '48%', height: '131px', border: '2px solid white'},
  img: {width: '105px', height: '105px', margin: '10px'},
  noImg: {width: '105px', height: '105px', margin: '10px', backgroundColor: 'gainsboro'}
}

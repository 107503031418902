import { ocpSignup } from '../graphql/mutation/client'
import { Mutation } from 'react-apollo'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Image } from 'react-native-web'
import { styles } from './style'
import { currentProvider } from '../graphql/query/client'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

export default class Signup extends Component {
  state = { language: 'EN', password: '', email: '', firstName: '', lastName: '', showPassword: false }
  render() {
    const { onSignup } = this.props
    return (
      <Mutation
        mutation={ocpSignup}
        onCompleted={onSignup}
        update={(cache, {data}) => {
          AsyncStorage.setItem('currentProvider', JSON.stringify(data.ocpSignup))
          cache.writeQuery({
            query: currentProvider,
            data: { currentProvider: data.create_ocp }
          })
        }}
      >
        {(ocpSignup, { loading, error }) => (
          <View style={styles.container}>
            <form onKeyPress={e => this.onEnter(e, ocpSignup)}>
              <Image style={styles.logoImg} source={require('../../../images/logo_only.png')} />
              {this.header()}
              {this.formFields()}
              {error && <Text style={{color: 'red', textAlign: 'center', paddingTop: 5}}>Something went wrong, please try again.</Text>}
              {this.signupButton(loading, ocpSignup)}
            </form>
          </View>
        )}
      </Mutation>
    )
  }

  formFields = () => {
    const { email, password, showPassword, firstName, lastName } = this.state
    return (
      <View style={{ paddingBottom: 10 }}>
        <View style={{ paddingVertical: 5 }}>
          <TextField
            label="Email"
            value={email}
            onChange={this.handleTextChange('email')}
            fullWidth
            style={{ paddingVertical: 10 }}
          />
        </View>
        <View style={{ paddingVertical: 5 }}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={this.handleTextChange('firstName')}
            fullWidth
            style={{ paddingVertical: 10 }}
          />
        </View>
        <View style={{ paddingVertical: 5 }}>
          <TextField
            label="Last Name"
            value={lastName}
            onChange={this.handleTextChange('lastName')}
            fullWidth
            style={{ paddingVertical: 10 }}
          />
        </View>
        <View style={{ paddingVertical: 5 }}>
          <TextField
            label="Password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={this.handleTextChange('password')}
            fullWidth
            style={{ paddingVertical: 10 }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    {showPassword
                      ? <Visibility />
                      : <VisibilityOff />
                    }
                  </IconButton>
                </InputAdornment>
            }}
          />
        </View>
      </View>
    )
  }

  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword })

  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  onEnter = (e, submit) => {
    const { email, password, firstName, lastName, language } = this.state
    if (e.key === 'Enter') {
      e.preventDefault()
      submit({ variables: { email, password, first_name: firstName, last_name: lastName, language } })
    }
  }

  header = () => (
    <View>
      <Text style={styles.headerTitle}>Signup as an OCP!</Text>
    </View>
  )

  signupButton = (loading, ocpSignup) => {
    const { email, password, firstName, lastName, language } = this.state
    return (
      <TouchableOpacity
        disabled={loading || !email || !password || !firstName || !lastName}
        style={loading || !email || !password || !firstName || !lastName ? styles.disable : styles.button}
        onPress={() => ocpSignup({ variables: { email, password, first_name: firstName, last_name: lastName, language } })}
      >
        {loading
          ? <ActivityIndicator size="small" />
        : <Text style={styles.btnText}>Sign up</Text>}
      </TouchableOpacity>
    )
  }
}

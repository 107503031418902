export default {
  textInput: {
    width: 300,
    fontSize: 16,
    marginTop: 10,
    borderBottomColor: 'purple',
    borderBottomWidth: 1,
  },
  msrTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  msrTitle: {
    fontWeight: '300',
    fontSize: 14
  },
  androidInput: {
    width: 300,
    fontSize: 16,
    marginTop: 10,
  },
  validInput: {
    color: 'darkgrey',
    textAlign: 'right',
    fontSize: 16,
    fontWeight: '600',
    paddingRight: 5
  },
  errorInput: {
    color: 'red',
    textAlign: 'right',
    fontSize: 16,
    fontWeight: '600',
    paddingRight: 5
  },
  webValidInput: {
    color: 'darkgrey',
    fontSize: 16,
    fontWeight: '400',
    paddingRight: 5,
    paddingTop: 3
  },
  webErrorInput: {
    color: 'red',
    fontSize: 16,
    fontWeight: '600',
    paddingRight: 5,
    paddingTop: 3
  },
  msrSpacing: {
    paddingVertical: 5,
  },
  inputSpacing: {
    paddingTop: 5,
    paddingBottom: 20,
  },
  inputHint: {
    fontSize: 12,
    marginTop: 5,
  },
  msrText: {
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 20,
    lineHeight: 25,
  },
  nativeInput: {
    width: 100,
    height: 40,
    fontSize: 18,
    borderRadius: 5,
    padding: 10,
    textAlign: 'right',
    borderWidth: 0.5,
    borderColor: 'darkgrey',
    alignSelf: 'flex-end',
  },
  guideContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  guideText: {
    fontWeight: '300',
    fontSize: 12
  }
}

import { graphql } from '../../helpers/graphql'
import { assessment_types, o_client_assessments, o_assessment_types } from '../graphql/query/assessment'
import { create_assessment, o_create_assessment } from '../graphql/mutation/assessment'
import { newAssessment as style } from './style'

export default props => {
  return (
    <View style={{paddingVertical: 10, width: 400}}>
      <AssessmentList {...props} />
    </View>
  );
}

const sortAssess = assessments => {
  return orderBy(assessments, [assess => assess.id.toLowerCase()], ['asc'])
}

@graphql( o_assessment_types, { options: {fetchPolicy: "network-only"} })
@graphql(o_create_assessment, {
  props: ({ownProps: {client: {id, uuid}, provider}, mutate}) => ({
    createAssessment: assessment_type => 
      mutate({
        variables: {
          client: uuid,
          provider: provider.uuid,
          assessment_type
        },
        update: addAssessment(uuid)
      })
    })
  })
class AssessmentList extends Component {
  state = {loading: false}

  render() {
    const {o_assessment_types} = this.props.data
    const [others, start] = partition(o_assessment_types, (assess) =>
      assess.category === 'HEART' || assess.category === 'Outcome Measure')
    const [heart, outcome] = partition(others, (assess) => assess.category === 'HEART')
    const orderedOutcomes = sortAssess(outcome)

    return (
      this.state.loading
        ? <ActivityIndicator style={{marginTop: 20}} color="gray" size="large" />
        : <View style={{ marginTop: -15 }}>
            <Text style={style.assessTitle}>HEART</Text>
            {heart.map(assess => this.renderType(assess))}
            <Text style={style.assessTitle}>START</Text>
            {start.map(assess => this.renderType(assess))}
            {this.props.provider.email === 'bwatson@optimisdev.com' || 'jhwang@optimisdev.com' &&
              <View>
                <Text style={style.assessTitle}>OUTCOME MEASURES</Text>
                {orderedOutcomes.map(assess => this.renderType(assess))}
              </View>
            }
        </View>
    )
  }

  renderType = assess =>
    <View key={uniqueId()} style={style.subTypes}>
      <Text style={style.subTitle}
            onPress={() => {this.createAssessment(assess.id)}}>
        {assess.id}
      </Text>
    </View>

  createAssessment = (id) => {
    this.setState({loading: true});
    this.props.createAssessment(id)
        .then(res => {
          this.props.closeModal()})
  }
}

const addAssessment = client => (store, {data: {o_create_assessment: assessment}}) => {
  const data = store.readQuery({
    query: o_client_assessments,
    variables: {clientId: client}
  })
  store.writeQuery({
    query: o_client_assessments,
    variables: {clientId: client},
    data: {...data, o_client_assessments: [assessment, ...data.o_client_assessments]}
  })
}

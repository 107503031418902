import { Query } from 'react-apollo'
import { getMessages } from '../graphql/mutation/message'
import MessageInput from './messageInput'
import style from './style'

export default class Messages extends Component {
  render() {
    const { provider, client } = this.props
    return (
      <Query
        query={getMessages}
        variables={{ user_one: provider, user_two: client}}
        fetchPolicy="network-only"
        pollInterval={500}
      >
        {({data, loading, error}) => {
          console.log(error)
          if (loading) return <View style={{ justifyContent: 'center', alignItems: 'center'}}>
                                <ActivityIndicator />
                              </View>
          if (error) return <Text>Something went wrong. Please try refreshing the page.</Text>
          const { get_two_person_conversation } = data
          this.messageContainer && this.messageContainer.scrollToEnd()
          return (
            <View style={{ flex: 4 }}>
              <View style={{ flex: 11}}>
                <ScrollView
                  contentContainerStyle={style.messages}
                  ref={ref => this.messageContainer = ref}
                  onContentSizeChange={(contentWidth, contentHeight)=> {
                    this.messageContainer.scrollToEnd({ animated: true })
                  }}
                >
                  {get_two_person_conversation && get_two_person_conversation.length > 0
                    ? get_two_person_conversation.map(message => {
                        return (
                          message.sender === provider
                            ? <View key={uniqueId()} style={style.outgoingMessage}>
                                <Text style={style.outgoingText}>{message.content}</Text>
                              </View>
                            : <View key={uniqueId()} style={style.incomingMessage}>
                                <Text>{message.content}</Text>
                              </View>
                        )
                      })
                    : <Text style={style.emptyMessage}>Send a message to begin your conversation.</Text>
                  }
                </ScrollView>
              </View>
              <View style={{ flex: 1 }}>
                <MessageInput provider={provider} client={ client } scrollEnd={() => this.messageContainer && this.messageContainer.scrollToEnd()} />
              </View>
            </View>
          )
        }}
      </Query>
    )
  }
}

import React, {useContext, useState} from 'react'
import { TextField, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import {BUTTON_PRIMARY_BG} from '../../../styles'
import {useEditDescription} from '../../../actions/workout'
import {WorkoutContext} from '../../../WorkoutContext';

const ExerciseDescription = props => {
  const { exercise } = props;
  const workout = useContext(WorkoutContext);
  const [description, setDescription] = useState(props.description);
  const [isEditing, setIsEditing] = useState(false);
  const [editDescription] = useEditDescription(workout, description, exercise);

  const renderEditDesc = () => (
    <div>
      <TextField name={'editDescription'} autoFocus fullWidth multiLine
                 defaultValue={props.description}
                 onChange={(e, v) => setDescription(v)}/>
      <Button
        variant="text"
        color={BUTTON_PRIMARY_BG}
        hovercolor="rgba(0,0,0,0)"
        onClick={save}>
        Save
      </Button>
      <Button
        variant="text"
        color="secondary"
        hovercolor="rgba(0,0,0,0)"
        onClick={toggleEdit}>
        Cancel
      </Button>
    </div>)

  const toggleEdit = () => setIsEditing(!isEditing)

  const save = () => {
    editDescription()
    toggleEdit()
  }

  return (
    <div className="description">
      <div className="label">
        <span>Description: </span>
        <EditIcon className="editIcon" onClick={toggleEdit} style={{fill: '#808080'}} />
      </div>
      {isEditing
        ? renderEditDesc()
        : props.description}
    </div>
  );
}

export default ExerciseDescription

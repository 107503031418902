import StateButton from '../common/StateButton'
import { retract_assessment } from '../graphql/mutation/assessment'
import { client_assessments } from '../graphql/query/assessment'
import { DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useContext } from 'react';
import { GlobalModalContext } from '../common/GlobalModal';

const buildMutationOptions = ({ownProps, variables}) => {
  return {
    variables,
    update: (store, {data: {retract_assessment}}) => {
      const data = store.readQuery({
        query: client_assessments,
        variables: {uuid: ownProps.client}
      })
      store.writeQuery({
        query: client_assessments,
        variables: {uuid: ownProps.client},
        data: {
          ...data,
          client_assessments: differenceBy(data.client_assessments, [retract_assessment], 'id')
        }
      })
    },
    optimisticResponse: {
      __typename: 'Mutation',
      retract_assessment: {
        __typename: 'Assessment',
        id: variables.id
      }
    }
  }
};

const RetractAssessment = props => {
  const {id} = props;
  const { closeModal } = useContext(GlobalModalContext);

  const [retractAssessment] = useMutation(retract_assessment, {
    ...buildMutationOptions({ownProps: props, variables: {id}} )
  });

  return (
    <React.Fragment>
      <DialogContent>
        <Text style={style.deleteTitle}>Are you sure you want to delete this assessment?{'\n'}{'\n'}</Text>
        <Text style={style.deleteContent} numberOfLines={2}>
          If you choose to delete this assessment, all previous information will be deleted.
          You will not get this information back once this assessment has been deleted.
        </Text>
      </DialogContent>
      <DialogActions>
        <StateButton
          text="Confirm"
          onSubmit={() => retractAssessment({id})}
          onCompleted={closeModal}
        />
      </DialogActions>
    </React.Fragment>
  )
}

export default RetractAssessment

const style = {
  index: {
    position: 'fixed',
    top: 65,
    right: 0,
    bottom: 0,
    left: 0,
    padding: 20,
    overflow: 'auto'
  },
  deleteTitle: {
    fontSize: 16,
  },
  deleteContent: {
    // paddingVertical: 20,
  },
  deleteButton: {
    // width: 200,
    alignSelf: 'flex-end',
  },
}

import {flatMap} from 'lodash'

export const stageParamSets = (exercise, updatedParams, i) =>
  paramSets(stageParams(exercise, updatedParams, i))

export const weekParamSets = (exercise, levelData) =>
  paramSets(weekParams(exercise, levelData))

const paramSets = parameters =>
  WEEK_DAYS.map(day_of_week => (
    {daily_index: DEFAULT_INDEX, day_of_week, parameters}))

const stageParams = (exercise, updatedParams, i) =>
  isStretchItem(exercise)
    ? DEFAULT_STRETCH_PARAMETERS
    : updatedParams[i]

const weekParams = (exercise, levelData) =>
  isStretchItem(exercise)
    ? DEFAULT_STRETCH_PARAMETERS
    : levelData && levelData.map(exr => {
        return {
          label: exr.parameter_type_name,
          value: exr.value
        }
      })

export const isStretchItem = ({name}) =>
  name.toLowerCase().includes('stretch')

const reformatParams = (params) =>
  params.map(({type: label, value}) => ({label, value}))

const WEEK_DAYS = ['monday', 'wednesday', 'friday']
const DAILY_INDICIES = ['morning', 'afternoon', 'evening']
const DEFAULT_INDEX = DAILY_INDICIES[0]
const DEFAULT_EXERCISE_LEVELS =
  [10, 15, 20, 15].map(value =>
    [{label: 'sets', value: '2'},
     {label: 'reps', value}])
const DEFAULT_STRETCH_PARAMETERS =
  [{label: 'reps', value: '1'},
   {label: 'second holds', value: '30'},
   {label: 'stretch force', value: 'light'}]

import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import NotificationOutline from '@material-ui/icons/NotificationsNone'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import { notifications as style } from './style'
import { useMutation, useQuery }  from '@apollo/react-hooks'
import { message_read } from  '../graphql/mutation/client'
import { client } from '../graphql/query/client'

const Notifications = props => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const [readMsg] = useMutation(message_read)
  const { messages } = props
  const markRead = message => {
    handleClose();
    readMsg({
      variables: {
        message: [message.id],
        user: props.id
      }
    });
    props.history.push({
      pathname: '/messages',
      state: { client: message.sender }
    })
  }
  return (
    <React.Fragment>
      <TouchableOpacity
        style={{ marginRight: 24 }}
        aria-owns={anchorEl ? 'notifications-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge badgeContent={messages && messages.length > 0 ? messages.length : null} color="error">
          <NotificationOutline style={{ fontSize: 24 }} htmlColor="gray" />
        </Badge>
      </TouchableOpacity>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
         style: { maxHeight: 400 }
       }}
        >
          {messages && messages.length > 0
            ? messages.map((msg, index, array) => {
              const { id } = msg.sender
              const { loading, data, error, refetch } = useQuery(client, {
                variables: { id },
                fetchPolicy: "network-only",
              })
                return (
                  <View key={uniqueId()}>
                    <MenuItem onClick={() => markRead(msg)}>
                      <View style={style.container}>
                        {/*<Avatar style={{ width: 45, height: 45 }}>{`${data && data.first_name.charAt(0)}${data && data.last_name.charAt(0)}`}</Avatar>*/}
                        <View style={style.texts}>
                          <Text style={style.mainText}>{data && `${data.first_name} ${data.last_name}`}</Text>
                          <Text style={style.subText}>{msg.content}</Text>
                        </View>
                      </View>
                    </MenuItem>
                    {index !== 0 && <Divider />}
                  </View>
                )
              }).reverse()
            : <MenuItem onClick={handleClose}>
                <View style={style.container}>
                  <Text style={{ fontStyle: 'italic', color: 'gray', paddingLeft: 55 }}>You have no new messages.</Text>
                </View>
              </MenuItem>
          }
      </Menu>
    </React.Fragment>
  )
}

export default Notifications

export const ocpBar = {
  flex: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row"
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'row-reverse',
  },
  userContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  separator: {
    marginRight: 17,
    borderRightWidth: 1,
    borderRightColor: 'gray',
    height: 28
  }
}

export const userProfile = {
  name: {
    margin: "2% 0 0 0",
    fontWeight: "600",
    color: "#50534D",
    fontSize: 15
  },
  subtitle: {
    fontSize: 13
  },
  profileView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "row"
  },
  userInformation: {
    alignItems: 'flex-end',
    marginRight: 10
  },
  submenu: {
    fontSize: 12,
    color: 'gray',
    paddingLeft: 10
  }
}

export const notifications = {
  container: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 5,
    width: 320
  },
  texts: {
    marginLeft: 20
  },
  mainText: {
    fontSize: 14
  },
  subText: {
    fontSize: 12,
    fontStyle: 'italic',
    color: 'gray',
    paddingTop: 5
  }
}

export const styles = {
  container: {
    width: '100%',
    padding: 25,
    borderRadius: 5,
    display: 'flex',
    alignSelf: 'center',
    backgroundColor: 'white'
  },
  btnText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16
  },
  disable: {
    marginTop: 20,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(44, 152, 240, 0.3)'
  },
  button: {
    marginTop: 20,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2C98F0',
    borderRadius: 5
  },
  headerTitle: {
    color: 'black',
    fontWeight: '600',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 10,
  },
  headerText: {
    color: 'black',
    fontSize: 12,
    marginTop: 25,
    textAlign: 'center'
  },
  logoImg: {
    margin: 'auto',
    height: 80,
    width: 80,
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  user: {
    color: 'rgb(24, 169, 169)',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 16,
    marginTop: 10,
  },
}

export default StyleSheet.create({
  // index: {
  //   position: 'fixed',
  //   top: 65,
  //   right: 0,
  //   bottom: 0,
  //   left: 0,
  //   padding: 20,
  //   overflow: 'auto'
  // },
  // modalTitle: {
  //   fontSize: 16,
  // },
  // modalContent: {
  //   paddingVertical: 20,
  // },
  // modalButton: {
  //   width: 200,
  //   alignSelf: 'flex-end',
  // },
  // modalContainer: {
  //   borderTopWidth: 0.5,
  //   borderBottomWidth: 0.5,
  //   borderTopColor: 'rgb(224, 224, 224)',
  //   borderBottomColor: 'rgb(224, 224, 224)'
  // },
  // modalPadding: {
  //   paddingHorizontal: 24
  // }
})

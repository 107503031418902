import Paper from '@material-ui/core/Paper'
import style from './style'

export default class Delta extends Component {
  render() {
    return (
      <Paper style={{ height: height - 20, padding: 20 }}>
        <Text style={style.title}>Delta Events</Text>
        <TouchableOpacity onPress={() => this.props.history.push('/event')}>
          <Paper style={{ cursor:'pointer', position: 'relative', margin: 20, height: 250, backgroundPosition: 'center', backgroundSize: 'cover' ,backgroundImage: 'url(https://www.trbimg.com/img-5bbbc876/turbine/ct-spt-chicago-marathon-2019-applications-20181008)'}}>
            <View style={{ position: 'absolute', paddingHorizontal: 20, paddingVertical: 5, width: 300, borderLeftWidth: 5, borderColor: 'white', right: 0, bottom: 20, backgroundColor: 'rgba(0,0,0,0.4)'}}>
              <Text style={{ fontSize: 30, color: 'white', fontWeight: '600' }}>L.A. Marathon</Text>
              <Text style={{ color: 'white' }}>August 3rd, 2019</Text>
              <Text style={{ color: 'white' }}>4:00 PM</Text>
            </View>
          </Paper>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.history.push('/event')}>
          <Paper style={{ cursor:'pointer', position: 'relative', margin: 20, height: 250, backgroundPosition: 'center', backgroundSize: 'cover' ,backgroundImage: 'url(http://www.wp-camp.com/wp-content/uploads/2017/01/swimcamp2.jpg)'}}>
            <View style={{ position: 'absolute', paddingHorizontal: 20, paddingVertical: 5, width: 300, borderLeftWidth: 5, borderColor: 'white', right: 0, bottom: 20, backgroundColor: 'rgba(0,0,0,0.4)'}}>
              <Text style={{ fontSize: 30, color: 'white', fontWeight: '600' }}>Swim for Charity</Text>
              <Text style={{ color: 'white' }}>October 1st, 2019</Text>
              <Text style={{ color: 'white' }}>6:00 PM</Text>
            </View>
          </Paper>
        </TouchableOpacity>
      </Paper>
    )
  }
}

const { height } = Dimensions.get('window')

export const exercisePicker = StyleSheet.create({
  filters: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 10,
  },
  selectedExrs: {
    flexDirection: 'row',
  },
  noExrMsgContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
    flex: 1
  },
  noExrMsg: {
    textAlign: 'center'
  },
  exerciseContainer: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap'
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerTitle: {
    fontSize: 20,
  },
})

// colors
export const PRIMARY_COLOR = '#4D5970';
export const PRIMARY_COLOR_LIGHTER = '#63708a';
export const PRIMARY_COLOR_DARKER = '#232934';

export const BUTTON_PRIMARY_BG = PRIMARY_COLOR;
export const BUTTON_PRIMARY_HOVER_BG = PRIMARY_COLOR_LIGHTER;
export const BUTTON_PRIMARY_COLOR = '#fff';

export const ICON_PRIMARY_COLOR = '#59bae2';
export const ICON_PRIMARY_COLOR_HOVER = '#579db9';
export const ICON_SECONDARY_COLOR = '#c3d4dc';
export const ICON_SECONDARY_COLOR_HOVER = '#99aab3';

export const HEADER_CONTROLS_COLOR = PRIMARY_COLOR;
export const HEADER_CONTROLS_HOVER_COLOR = PRIMARY_COLOR_DARKER;

export const HEADER_CLOSE_MODAL_ICON_COLOR = '#fff';
export const CLOSE_MODAL_ICON_COLOR = 'grey';

export const style = {
  container: {
    backgroundColor: 'white',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 15,
    flexWrap: 'wrap',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: 15,
  },
  header: {
    fontSize: 24,
  },
  searchContainer: {
    width: 300,
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro',
    paddingTop: 10,
    marginLeft: 10,
  },
  clientImg: {
    height: 30,
    width: 30,
    borderRadius: 30,
  },
  newClientBtn: {
    paddingTop: 10,
  },
  searchbar: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchIcon: {
    position: 'absolute',
    paddingLeft: 3,
  },
  searchText: {
    width: '100%',
    paddingLeft: 35,
  },
  addBtn: {
    width: 110,
  },
  image: {
    height: 80,
    width: 80,
    borderRadius: 40,
    resizeMode: 'cover',
  },
  cardsContainer: {
    backgroundColor: 'white',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    width: 360,
    padding: 10,
  },
  cardContent: {
    flexDirection: 'row',
  },
  cardText: {
    marginLeft: 15,
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    color: 'gray',
    flex: 1,
  },
  paginateBtn: {
    width: 90,
    margin: 3,
  },
  modal: {
    padding: 15,
  },
  modalTitle: {
    fontSize: 20,
  },
  modalRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  modalColumn: {
    flexGrow: 1,
    alignItems: 'center',
  },
  modalBtnContainer: {
    flexDirection: 'row-reverse',
    marginTop: 20,
  },
  modalBtn: {
    width: 110,
  },
  genderPicker: {
    marginTop: 20,
    width: '87%',
  },
  noResults: {
    padding: 30,
    fontSize: 14,
    color: 'gray',
  },
}

export const headerStyle = {
  title: {
    fontWeight: '600',
    fontSize: 14,
  },
  subtitle: {
    color: 'gray',
    fontSize: 12,
    paddingTop: 3
  },
  activationText: {
    color: 'white',
    fontSize: 12,
    fontWeight: '600'
  },
  card: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2},
    shadowOpacity: 0.4,
    shadowRadius: 2,
    elevation: 1,
    backgroundColor: 'white',
    padding: 15,
    flexDirection: 'row',
  },
  cardContent: {
    marginLeft: '2%',
  },
  createdText: {
    color: 'gray'
  },
  progressContainer: {
    height: 80,
    width: 80,
    width: null,
  },
  container: {
    backgroundColor: 'white',
    padding: 10,
  },
  header: {
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro',
  },
  name: {
    fontSize: 20,
  },
  demographic: {
    backgroundColor: 'white',
    padding: 15,
    flexDirection: 'row',
  },
  assessContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  clientImg: {
    height: 60,
    width: 60,
    borderRadius: 30,
  },
  clientDetails: {
    marginLeft: 15,
    justifyContent: 'center',
  },
  assessCard: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessDetails: {
    marginLeft: 20,
  },
  assessTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  ptExercise: {
    flexDirection: 'row',
    padding: 15,
    borderBottomWidth: 0.5,
    borderColor: 'gainsboro',
    alignItems: 'center'
  },
  ptLastExercise: {
    flexDirection: 'row',
    padding: 15
  },
  ptData: {
    marginLeft: 20,
    flex: 1 ,
    flexWrap: 'wrap'
  },
  ptName: {
    fontSize: 14,
    fontWeight: '600'
  },
  ptInstructions: {
    fontSize: 12,
    paddingTop: 5,
    color: 'gray'
  },
  ptParamContainer: {
    flexDirection: 'row',
    paddingTop: 5,
    justifyContent: 'flex-end'
  },
  ptParams: {
    flexDirection: 'row',
    paddingRight: 15,
    alignItems: 'center'
  },
  paramValue: {
    fontSize: 16, fontWeight: '600'
  },
  paramLabel: {
    fontSize: 14, paddingLeft: 5
  }
}

import {cloneDeep, range} from "lodash"

const DAYS_IN_WEEK = 7
const WEEKS_IN_STAGE = 4
const WORKOUT_STAGES = 4
const DAYS_TO_NEXT_STAGE = DAYS_IN_WEEK * WEEKS_IN_STAGE

export const formatWorkout = ({workout}) => {
  workout = cloneDeep(workout)
  return update(
    workout, 'exercises',
    exercises => exercises.map(exr =>
      set(exr, 'stages',
          assocPeriodsToStages(exr.periods, buildStages(workout)))))
}

const buildStages = ({start_date: programStartDate}) =>
  map(range(WORKOUT_STAGES), stage => {
    const start_date = moment(programStartDate)
          .add(DAYS_TO_NEXT_STAGE * stage, 'days')
    const end_date = start_date.clone()
          .add(DAYS_TO_NEXT_STAGE - 1, 'days')
    return ({start_date, end_date, weeks: buildWeeks(start_date, stage)})
  })

export const getStages = buildStages

const buildWeeks = (stageStartDate, stageIndex) =>
  map(range(WEEKS_IN_STAGE), weekIndex => {
    const start_date = stageStartDate.clone()
          .add(DAYS_IN_WEEK * weekIndex, 'days')
    const end_date = start_date.clone()
          .add(DAYS_IN_WEEK - 1, 'days')
    return ({start_date, end_date, stageIndex, weekIndex})
  })

// assocs exercise period data into stages and weeks
const assocPeriodsToStages = (periods, stages) =>
  reduce(periods, (stages, period) =>
    stages.map(stage =>
      withinTimeRange(period, stage)
        ? update(stage, 'weeks', weeks => weeks.map(week =>
            sameStartDate(period, week)
              ? {...period, ...week}
              : week))
        : stage),
    cloneDeep(stages))

const withinTimeRange = ({start_date, end_date}, stage) =>
  moment(start_date).isSameOrAfter(stage.start_date) &&
    moment(end_date).isSameOrBefore(stage.end_date)

const sameStartDate = (period, week) =>
  moment(period.start_date).isSame(week.start_date)

import React from 'react'
import { Text, View } from 'react-native-web'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { has } from 'lodash'

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const AvailabilityMeasure = (props) => {
  // parsing observation object
  const obObject = JSON.parse(props.value)

  const onSelect = (value, index) => {
    const msrId = props.msr.id
    // updated key value of index of day and time of day
    const newVal = { [index]: value }
    // merging new val to obObject
    const newObject = { ...obObject, ...newVal }
    // stringify it to post as string
    const jsonifiedOb = JSON.stringify(newObject)
    return props.postOb(jsonifiedOb, msrId)
  }

  return (
    <View>
      <Text style={{ fontWeight: '500', fontSize: 16 }}>Exercise Availability</Text>
      {days.map((day, index) => {
        const getValue = obObject ? obObject[index] : ''
        const value = getValue === 0 ? 0 : getValue || ''

        return (
          <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 15, borderBottomWidth: 1, borderColor: 'gainsboro', alignItems: 'center' }}>
            <Text style={{ fontWeight: '500' }}>{day}</Text>
            <Select
              style={{ width: 150, marginBottom: 10 }}
              value={value}
              onChange={event => onSelect(event.target.value, index)}
            >
              <MenuItem value=''></MenuItem>
              <MenuItem value={0}>Morning</MenuItem>
              <MenuItem value={1}>Afternoon</MenuItem>
              <MenuItem value={2}>Evening</MenuItem>
            </Select>
          </View>
        )
      })}
    </View>
  )
}

export const CardioAvailabilityMeasure = props => {
  const { msr: { dim_strings } } = props
  // parsing observation object
  const obObject = JSON.parse(props.value)
  // refactor use these?
  const periodPosition = 1
  const intensityPosition = 2

  const onSelect = (value, dayIndex, name) => {
    const msrId = props.msr.id
    const oldVal = obObject[dayIndex]
    const newVal = { [parseInt(name)]: value }
    const updatedVal = { ...oldVal, ...newVal }
    // merging updated val to obObject
    const newObject = { ...obObject, [dayIndex]: updatedVal }
    // stringify it to post as string
    const jsonifiedOb = JSON.stringify(newObject)
    return props.postOb(jsonifiedOb, msrId)
  }

  const extractValue = (valueIndex, object) => {
    const value = has(object, valueIndex) ? object[valueIndex] : ''
    return value
  }


  return (
    <View>
      <Text style={{ fontWeight: '500', fontSize: 16 }}>Exercise Availability</Text>
      {dim_strings[0].map((day, dayIndex) => {

        const objectValue = obObject ? obObject[dayIndex] : ''
        const periodValue = extractValue(1, objectValue)
        const intensityValue = extractValue(2, objectValue)

        return (
          <View key={dayIndex} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 15, borderBottomWidth: 1, borderColor: 'gainsboro', alignItems: 'center' }}>
            <Text style={{ fontWeight: '500' }}>{day}</Text>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ marginHorizontal: 10 }}>
                <Text style={{ color: 'gray' }}>Day Availability:</Text>

                <Select
                  style={{ width: 150, marginBottom: 10 }}
                  value={periodValue}
                  name={'1'}
                  onChange={event => onSelect(event.target.value, dayIndex, event.target.name)}
                >
                  <MenuItem value=''></MenuItem>
                  {dim_strings[1].map((period, periodIndex) =>
                    <MenuItem key={periodIndex} value={periodIndex}>{period}</MenuItem>
                  )}
                </Select>
              </View>
              <View style={{ marginHorizontal: 10 }}>
                <Text style={{ color: 'gray' }}>Intensity:</Text>

                <Select
                  style={{ width: 150, marginBottom: 10 }}
                  value={intensityValue}
                  name={'2'}
                  onChange={event => console.log(event.target.name) || onSelect(event.target.value, dayIndex, event.target.name)}
                >
                  <MenuItem value=''></MenuItem>
                  {dim_strings[2].map((intensity, intensityIndex) =>
                    <MenuItem key={intensityIndex} value={intensityIndex}>{intensity}</MenuItem>
                  )}
                </Select>
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
}

import React, {Component} from 'react'
import {join} from 'lodash'

export default class Header extends Component {

  render() {
    return (
      <div className="stages-header stages-label">
        {this.props.stages.map(this.renderHeaderBlock)}
      </div>
    )
  }

  renderHeaderBlock = ({start_date, end_date}, i) => {
    const {workout: {stages: stageCompliance},
           allWorkoutClick, stageViewClick, viewingStage} = this.props
    const stageIndex = i+1
    const isCurrStage = viewingStage == stageIndex
    return (
      <div key={i}
           className={`stage ${isCurrStage ? 'show-weeks' : ''}` }
           style={{width: !viewingStage ? '25%' : isCurrStage ? '100%' : '0%'}}
           onClick={(e) => isCurrStage
                      ? allWorkoutClick(e)
                      : stageViewClick(stageIndex)}>
        <div className="label" style={styles.fullWidth}>
          <span className="title">{`Stage ${stageIndex}`}</span>
          <span className="date">
            {join([start_date, end_date].map(d => d.format('MMM D')), ' - ')}
          </span>
          {stageCompliance[i] &&
            <span style={styles.stageCompliance}>
              {`${stageCompliance[i].percent_complete.toFixed(1)}%`}
            </span>}
        </div>
        {isCurrStage &&
          this.renderWeekHeaderBlock(start_date, stageCompliance[i])}
      </div>
    )
  }

  renderWeekHeaderBlock = (stageStartDate, compliance) => {
    if (compliance) {
      let updatedCompliance = compliance
      updatedCompliance.weeks = sortBy(compliance && compliance.weeks, 'index')
      let startDateString, endDateString
      let weekHeaders = []
      let date = stageStartDate.clone()
      for (let i = 0; i < 4; i++) {
        startDateString = date.format('MMM D')
        date.set('date', (date.get('date') + 6))
        endDateString = date.format('MMM D')
        const weekCompliance = compliance && updatedCompliance.weeks[i]
        weekHeaders.push(
          <div key={i} style={styles.weekHeader}>
            {startDateString} - {endDateString}
            {weekCompliance &&
              <span style={styles.weekCompliance}>
                {`${weekCompliance.percent_complete.toFixed(1)}%`}
              </span>}
            </div>)
            date.set('date', (date.get('date') + 1))
          }
      return (
        <div className="week-dates">{weekHeaders}</div>
      )
    }
  }
}

const styles = {
  fullWidth: {width: '100%'},
  stageCompliance: {float: 'right', marginRight: '10px'},
  weekHeader: {textAlign: 'left'},
  weekCompliance: {float: 'right'}
}

import React from 'react'
import moment from 'moment'
import { KeyboardDatePicker } from '@material-ui/pickers'

export const DatePicker = ({date, placeHolder, onDateChange}) => {
  return (
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        value={moment(date).toDate()}
        label={placeHolder}
        minDate={moment().toDate()}
        style={{ width: 300 }}
        format="MM-DD-YYYY"
        onChange={(_, date) => onDateChange(date)}
      />
  )
}

import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { o_assessment_types, o_measure, o_measurements, o_measures } from '../graphql/query/assessment'
import { o_create_measure, updateComponent, deleteComponent } from '../graphql/mutation/assessment'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Delete from '@material-ui/icons/Delete'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

const UNIT_TYPES = ['DAYS', 'WEEKS', 'MONTHS', 'YEARS', 'SECONDS', 'MINUTES', 'HOURS', 'MMHG', 'SERVINGS', 'TIMES', 'DRINKS', 'LPERM', 'BPM', 'DEGREES', 'METERS', 'INCHES', 'DEGREEOFARC', 'MEALS', 'POUNDS']

const TYPES = ['INT', 'NUMBER', 'BOOL', 'ENUM', 'DATE', 'STRING', 'ARRAY']

const AssessCreator = props => {
  const [assess, setAssess] = useState('')
  const [showForm, setShowForm] = useState(false)
  const { data, loading, error } = useQuery(o_assessment_types, {
    fetchPolicy: 'network-only'
  })

  if (loading) return <h5>Loading ... </h5>
  if (error) return <h5>Something went terribly wrong. We are terribly sorry. Please try again when your terribleness wears off.</h5>

  return (
    <div style={{ padding: 20, minHeight: height }}>
      <div style={{ backgroundColor: 'white', borderRadius: 8, borderBottomWidth: 0.5, borderBottomColor: 'gray', borderBottomStyle: 'solid', padding: 10 }}>
        <h3 style={{ margin: 0, fontWeight: '500' }}>Please select an assessment to edit:</h3>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', paddingTop: 10, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select value={assess} style={{ height: 24, width: 150 }} onChange={e => setAssess(e.target.value)}>
              <MenuItem value=""></MenuItem>
              {data.o_assessment_types.map(assess => {
                return (
                  <MenuItem key={assess.id} value={assess.id}>{assess.id}</MenuItem>
                )
              })}
            </Select>
            <p style={{ paddingRight: 20, paddingLeft: 20 }}>or</p>
            <button onClick={() => setShowForm(!showForm)} style={{ height: 24 }}>Add New Assessment</button>
            {showForm &&
              <NewForm />
            }
          </div>
          <div style={{ display: 'flex' }}>
            <NewMeasure />
            <NewMeasurement />
          </div>
        </div>
      </div>

      {assess &&
        <div style={{ padding: 10 }}>
          <Assessment assessId={assess} />
        </div>
      }
    </div>
  )
}

const NewForm = props => {
  const [id, setId] = useState('')
  const [createAssess, { loading, error }] = useMutation(o_create_measure)
  const [errorMsg, setErrorMsg] = useState('')

  const onChange = event => setId(event.target.value)

  const onClick = () => {
    setErrorMsg('')
    createAssess({
      variables: { id, assessment: true, visible: true }
    }).then(() => setId(''))
      .catch(e => {
        setErrorMsg('Something went wrong, please try again later.')
      })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
      <TextField
        value={id}
        onChange={onChange}
        placeholder="Assessment ID"
      />
    <button style={{ marginLeft: 10 }}>Create</button>
    {errorMsg && <h4 style={{ color: 'red' }}>Something went wrong. Please try again.</h4>}
    </div>
  )
}

const Assessment = props => {
  const { data, loading, error, refetch } = useQuery(o_measure, {
    variables: { id: props.assessId },
    fetchPolicy: 'no-cache'
  })

  if (loading) return <h5>Loading ... </h5>
  if (error) return <h5>Something went terribly wrong. We are terribly sorry. Please try again when your terribleness wears off.</h5>

  // const { o_measure: { name, components } } = data

  const sortedComponents = sortBy(data && data.o_measure.components, ['position'])

  return (
    <div style={{ minHeight: 250 }}>
      <h3 style={{ fontWeight: '500', marginBottom: 0 }}>{`${data && data.o_measure.name} Assessment`}</h3>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4 style={{ fontWeight: '500' }}>Measures & Measurements:</h4>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <AddMeasure refetch={refetch} assessId={props.assessId} />
          <AddMeasurement refetch={refetch} assessId={props.assessId} />
        </div>
      </div>
      <SortableMeasures
        msrs={sortedComponents}
        assessId={props.assessId}
        helperClass="dragging"
        onSortEnd={(indexParams) => console.log(indexParams, "index params")}
        pressDelay={500}
        refetch={refetch}
      />
    </div>
  )
}

const SortableMeasures = SortableContainer(({ msrs, assessId, refetch }) => {
  return (
    <div>
      {msrs.map((msr, index) => {
        return (
          <SortableMeasure
            key={uniqueId()}
            msr={msr}
            index={index}
            assessId={assessId}
            refetch={refetch}
          />
        )
      })}
    </div>
  )
})

const SortableMeasure = SortableElement(({ msr, index, assessId, refetch }) => {
  return msr.__typename === "MM"
    ? <Measurement assessId={assessId} msr={msr} index={index} key={uniqueId()} refetch={refetch} />
    : <Measure assessId={assessId} msr={msr} index={index} key={uniqueId()} refetch={refetch} />
})

const NewMeasure = props => {
  const { data, loading, error } = useQuery(o_measures, {
    fetchPolicy: 'network-only'
  })
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const [id, setId] = useState('')
  const [as, setAs] = useState('')
  const [cutoffs, setCutoffs] = useState('')

  const onChange = type => event => {
    if (type === 'id') {
      setId(event.target.value)
    } else if (type === 'as') {
      setAs(event.target.value)
    } else if (type === 'cutoffs') {
      setCutoffs(event.target.value)
    }
  }

  if (loading) return <p>loading...</p>
  if (error) return <p>Something went wrong, please try again later.</p>

  return (
    <>
      <button onClick={handleOpen} style={{ marginLeft: 5 }}>+ New Measure</button>
        <Dialog onClose={handleClose} open={open}>
          <div style={{ width: 400, minHeight: 350, padding: 20, justifyContent: 'space-between', flexDirection: 'column' }}>
            <div>
              <h4 style={{ margin: 0 }}>New Measure</h4>
              <p>This action will create a new measure. You will have to add it to the assessment once it has been created.</p>
              <div style={{ marginTop: 10 }}>
                <TextField
                  value={id}
                  style={{ width: 250 }}
                  onChange={onChange('id')}
                  placeholder="Measure ID"
                  helperText="ID to set for the measure"
                  />
              </div>
              <div style={{ marginTop: 10 }}>
                <TextField
                  value={as}
                  style={{ width: 250 }}
                  onChange={onChange('as')}
                  placeholder="id: Height as H"
                  helperText="Measure shorthand for fn scoring"
                  />
              </div>
              <div style={{ marginTop: 10 }}>
                <TextField
                  value={cutoffs}
                  style={{ width: 250 }}
                  onChange={onChange('cutoffs')}
                  placeholder="Cutoffs for Measure"
                  helperText="ex: 30, 15, 10, 5"
                  />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <button style={{ marginRight: 15 }} onClick={handleClose}>Cancel</button>
              <button onClick={() => console.log('wooooo')}>Create</button>
            </div>
          </div>
        </Dialog>
    </>
  )
}

const AddMeasure = props => {
  const { data, loading, error } = useQuery(o_measures, {
    fetchPolicy: 'network-only'
  })
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const [selectedMsr, setSelectedMsr] = useState(null)

  // filter out assessments
  const pureMsr = data && data.o_measures.filter(o_measure => !o_measure.assessment)

  const selectMsr = event => {
    const msr = find(pureMsr, msr => msr.id === event.target.value)
    setSelectedMsr(msr)
  }

  if (loading) return <p>loading...</p>
  if (error) return <p>Something went wrong, please try again later.</p>

  return (
    <>
      <button onClick={handleOpen} style={{ marginLeft: 5 }}>+ Add Measure</button>
        <Dialog onClose={handleClose} open={open}>
          <div style={{ width: 400, minHeight: 350, padding: 20, justifyContent: 'space-between', flexDirection: 'column' }}>
            <div>
              <h4 style={{ margin: 0 }}>Add Measurrement</h4>
              <p>This action will add selected measure to the current assessment.</p>
              <Select value={selectedMsr && selectedMsr.id || ''} onChange={selectMsr} style={{ width: 200 }}>
                <MenuItem value=""></MenuItem>
                {pureMsr && pureMsr.map(measure => {
                  return (
                    <MenuItem key={uniqueId()} value={measure.id}>{measure.id}</MenuItem>
                  )
                })}
              </Select>
              <p style={{ fontSize: '0.75rem', marginTop: 3, color: 'rgba(0, 0, 0, 0.54)', lineHeight: 1.66, letterSpacing: '0.03333em' }}>Select Existing Measurement</p>
            </div>

            <div style={{ marginTop: 10 }}>
              <button style={{ marginRight: 15 }} onClick={handleClose}>Cancel</button>
              <button onClick={() => console.log('wooooo')}>Add</button>
            </div>
          </div>
        </Dialog>
    </>
  )
}

const NewMeasurement = props => {
  const { data, loading, error } = useQuery(o_measurements, {
    fetchPolicy: 'network-only'
  })

  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [unitType, setUnitType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [newMmPosition, setNewMmPosition] = useState('')

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const onChange = type => event => {
    if (type === 'name') {
      setName(event.target.value)
    } else if (type === 'description') {
      setDescription(event.target.value)
    } else if (type === 'type') {
      setType(event.target.value)
    } else if (type === 'unitType') {
      setUnitType(event.target.value)
    } else if (type === 'position') {
      setNewMmPosition(event.target.value)
    }
  }


  return (
    <>
      <button onClick={handleOpen} style={{ marginLeft: 5 }}>+ New Mesaurement</button>
      <Dialog onClose={handleClose} open={open}>
        <div style={{ width: 400, minHeight: 350, padding: 20, justifyContent: 'space-between', flexDirection: 'column' }}>
          <div>
            <h4 style={{ margin: 0 }}>New Measurement</h4>
            <p>This action will create a new measurement. You will have to add it to the assessment once it has been created.</p>
            <div style={{ marginTop: 10 }}>
              <TextField
                value={name}
                style={{ width: 250 }}
                onChange={onChange('name')}
                placeholder="Measurement Name"
                helperText="Name which will also be set as its ID"
                />
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                value={description}
                style={{ width: 250 }}
                onChange={onChange('description')}
                placeholder="Description"
                helperText="Describe this measurement in detail"
                />
            </div>
            <div style={{ flexDirection: 'row', display: 'flex', marginTop: 10 }}>
              <div>
                <Select style={{ width: 100 }} value={type} onChange={onChange('type')}>
                  <MenuItem value=""></MenuItem>
                  {TYPES.map(types => {
                    return (
                      <MenuItem key={uniqueId()} value={types}>{types}</MenuItem>
                    )
                  })}
                </Select>
                <p style={{ fontSize: '0.75rem', marginTop: 3, color: 'rgba(0, 0, 0, 0.54)', lineHeight: 1.66, letterSpacing: '0.03333em' }}>Select type</p>
              </div>
              <div style={{ marginLeft: 20 }}>
                <Select style={{ width: 100 }} value={type} onChange={onChange('unitType')}>
                  <MenuItem value=""></MenuItem>
                  {UNIT_TYPES.map(unitTypes => {
                    return (
                      <MenuItem key={uniqueId()} value={unitTypes}>{unitTypes}</MenuItem>
                    )
                  })}
                </Select>
                <p style={{ fontSize: '0.75rem', marginTop: 3, color: 'rgba(0, 0, 0, 0.54)', lineHeight: 1.66, letterSpacing: '0.03333em' }}>Select unit type</p>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            {errorMessage && <h6 style={{ color: 'red' }}>Something went wrong, please try again later.</h6>}
            <button style={{ marginRight: 15 }} onClick={handleClose}>Cancel</button>
            <button onClick={() => addComponent()}>Create</button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

const AddMeasurement = props => {
  const { data, loading, error } = useQuery(o_measurements, {
    fetchPolicy: 'network-only'
  })

  const [updateComponentMutation, { loading: mutationLoading, error: mutationError }] = useMutation(updateComponent)

  const [open, setOpen] = useState(false)
  const [selectedMsr, setSelectedMsr] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [newMmPosition, setNewMmPosition] = useState('')

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const onChange = type => event => {
    if (type === 'position') {
      setNewMmPosition(event.target.value)
    }
  }

  const selectMsr = event => {
    const msr = find(data.o_measurements, msr => msr.id === event.target.value)
    setSelectedMsr(msr)
  }

  const addComponent = () => {

    // to do differentiate new measure and existing measure creation
    updateComponentMutation({
      variables: {
        measure_id: props.assessId,
        component: { id: selectedMsr.id, position: newMmPosition || 0 },
      }
    }).then(() => {
      setSelectedMsr(null)
      handleClose()
      props.refetch()
    })
      .catch(e => {
        setErrorMessage('Something went wrong, please try again later.')
      })
  }

  return (
    <>
      <button onClick={handleOpen} style={{ marginLeft: 5 }}>+ Add Mesaurement</button>
      <Dialog onClose={handleClose} open={open}>
        <div style={{ width: 400, minHeight: 350, padding: 20, justifyContent: 'space-between', flexDirection: 'column' }}>
          <div>
            <h4 style={{ margin: 0 }}>Add Measurrement</h4>
            <p>This action will add this measurement to current selected assessment.</p>
            <Select value={selectedMsr && selectedMsr.id || ''} onChange={selectMsr} style={{ width: 200 }}>
              <MenuItem value=""></MenuItem>
              {data && data.o_measurements.map(msrmnt => {
                return (
                  <MenuItem key={uniqueId()} value={msrmnt.id}>{msrmnt.id}</MenuItem>
                )
              })}
            </Select>
            <p style={{ fontSize: '0.75rem', marginTop: 3, color: 'rgba(0, 0, 0, 0.54)', lineHeight: 1.66, letterSpacing: '0.03333em' }}>Select Existing Measurement</p>
            {/* add position text input here */}
            {selectedMsr &&
              <TextField
                value={newMmPosition}
                style={{ width: 100 }}
                helperText="The position of this new measurement"
                placeholder="MM Position"
                onChange={onChange('position')}
              />
            }
          </div>
          <div style={{ marginTop: 10 }}>
            {errorMessage && <h6 style={{ color: 'red' }}>Something went wrong, please try again later.</h6>}
            <button style={{ marginRight: 15 }} onClick={handleClose}>Cancel</button>
            <button onClick={() => addComponent()}>Add</button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

const Measure = ({ msr, assessId, refetch }) => {
  console.log(msr, "msr@")
  return (
    <div style={{ backgroundColor: 'white', minHeight: 85, padding: 10, borderRadius: 8, marginBottom: 20 }}>
      <h4 style={{ margin: 0, fontWeight: '500', color: 'gray' }}>Measure</h4>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <div>
          <p style={{ color: 'gray', margin: 0}}>id:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.id}</p>
        </div>
        <div>
          <p style={{ color: 'gray', margin: 0}}>name:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.name}</p>
        </div>
        <div>
          <p style={{ color: 'gray', margin: 0}}>unit:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.unit}</p>
        </div>
        <div>
          <p style={{ color: 'gray', margin: 0}}>position:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.position}</p>
        </div>
      </div>
      {msr.components &&
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <h5 style={{ margin: 0, fontWeight: '500'}}>- Measurements:</h5>
          {msr.components.map((mm, index) => {
            return mm.id && (
              <div style={{ borderBottomWidth: 0.5, borderBottomColor: 'gray', borderBottomStyle: 'solid' }}>
                <Measurement componentIndex={index} component={true} assessId={assessId} msr={mm} key={uniqueId()} refetch={refetch} />
              </div>
            )
          })}
        </div>
      }
      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <NewMeasurement refetch={refetch} assessId={assessId} />
      </div>
    </div>
  )
}

const Measurement = ({ msr, assessId, refetch, component, componentIndex }) => {
  const [deleteComponentMutation, { loading, error }] = useMutation(deleteComponent)
  const onClick = componentId => {
    // to do: optimistic write msr add/removal
    deleteComponentMutation({
      variables: { measure_id: assessId, component_id: componentId }
    })
    .then(() => refetch())
    .catch((e) => console.log(e, "error"))
  }
  return (
    <div style={{ backgroundColor: 'white', minHeight: 85, padding: 10, borderRadius: 8, marginBottom: 20 }}>
      <h4 style={{ margin: 0, fontWeight: '500', color: 'gray' }}>{component ? `${componentIndex + 1}.` : "Measurement"}</h4>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ flex: 1 }}>
          <p style={{ color: 'gray', margin: 0}}>id:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.id}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ color: 'gray', margin: 0}}>unit:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.unit}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ color: 'gray', margin: 0}}>type:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.type}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ color: 'gray', margin: 0}}>min:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.min}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ color: 'gray', margin: 0}}>max:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.max}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ color: 'gray', margin: 0}}>position:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.position}</p>
        </div>
        <Delete
          htmlColor='red'
          size={30}
          onClick={() => onClick(msr.id)}
        />
      <div style={{ minWidth: 250, flex: 4 }}>
          <p style={{ color: 'gray', margin: 0}}>description:</p>
          <p style={{ marginBottom: 0, marginTop: 3 }}>{msr.description && msr.description[0]}</p>
        </div>
      </div>
    </div>
  )
}

export default AssessCreator

const { height } = Dimensions.get('window')

const style = {

}

import { style } from './style'
export class Button extends Component {
  render() {
    const { text, onPress, color, disabled } = this.props
    return (
      <TouchableOpacity
        style={disabled ? style.disabled : [style.button, color && {backgroundColor: color}]}
        disabled={disabled}
        onPress={onPress}
      >
        <Text style={{color: 'white', fontWeight: '500'}}>{text}</Text>
      </TouchableOpacity>
    )
  }
}

import { Calendar, momentLocalizer } from 'react-big-calendar'
import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import { Mutation, Query, graphql } from 'react-apollo'
import { create_event, delete_event } from '../graphql/mutation/appointment'
import { get_events } from '../graphql/query/appointment'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { currentProvider } from '../graphql/query/client'
import { toggleSnackbar } from '../../config/actions/snackbar'
import Delete from '@material-ui/icons/Delete'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import style from './style'

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar)
const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

@graphql(currentProvider)
@connect(null, { toggleSnackbar })
export default class OCPCalendar extends Component {
  state = {
    event: null, name: '', start: '',  end: '', view: 'month', date: '',
    recur: false, monday: false, tuesday: false, wednesday: false, thursday: false,
    friday: false, saturday: false, sunday: false,
    recurBy: 'DAILY', recurEnd: moment().format("YYYY-MM-DD"),
    width: width, height: height
  }

  componentDidMount() {
    this.updateSize()
    window.addEventListener('resize', this.updateSize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize)
  }

  updateSize = () => this.setState({ width: document.body.clientWidth, height: document.body.clientWidth })

  render() {
    const { event, view } = this.state

    return (
      <Paper style={{ height: this.state.height - 100, width: this.state.width - 100, padding: 20, margin: 10 }}>
        <Query
          query={get_events}
          fetchPolicy="network-only"
          variables={{ user: this.props.data.currentProvider.id }}
        >
          {({ data, loading, error, refetch }) => {
            if (loading) return <ActivityIndicator />
            if (error) return <Text>Something went wrong. Please try refreshing the page.</Text>
            const events = data.events.map(event => {
              return {
                ...event,
                start: new Date(moment.unix(event.start)),
                end: new Date(moment.unix(event.end)),
                title: event.name
              }
            })
            return (
              <Mutation
                mutation={create_event}
                onCompleted={() => this.onCompleted(refetch, 'scheduled')}
              >
                {(create_event, { error, loading: mutationLoading }) => {
                  if (error) return <Text style={{ alignSelf: 'center' }}>Something went wrong. Please try again.</Text>
                  return (
                    <View style={style.container}>
                      <View style={style.calendarContainer}>
                        <DragAndDropCalendar
                          localizer={localizer}
                          selectable
                          culture='en'
                          defaultView={view}
                          onView={view => this.setState({ view })}
                          views={['month', 'week']}
                          events={events}
                          defaultDate={new Date()}
                          onEventDrop={event => {
                            this.setState({ event })
                            create_event({
                              variables: {
                                start: this.setDate(moment(event.start).format('HH:mm')),
                                end: this.setDate(moment(event.end).format('HH:mm')),
                                user: this.props.data.currentProvider.id,
                                name: event.event.name,
                                id: event.event.id
                              }
                            })
                          }}
                          eventPropGetter={eventStyleGetter}
                          onSelectEvent={(event) => this.setState({ event, name: event.name, start: moment(event.start).format('HH:mm'), end: moment(event.end).format('HH:mm') })}
                          onSelectSlot={(event) => {
                            const monthView = view === 'month'
                            this.setState({
                              event,
                              name: '',
                              start: monthView ? moment().format('HH:mm') : moment(event.start).format('HH:mm'),
                              end: monthView ? moment().add(1, 'hours').format('HH:mm') : moment(event.end).format('HH:mm')
                            })
                          }}
                        />
                      </View>
                      <View style={style.quickViewContainer}>
                        {event
                          ? this.showEvent(refetch, create_event, mutationLoading)
                          : events.length > 0
                            ? this.upcoming(events)
                            : <Text style={style.title}>You have no upcoming appointments.</Text>
                        }
                      </View>
                    </View>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </Paper>
    )
  }

  showEvent = (refetch, create_event, mutationLoading) => {
    const { event, name, start, end, recur, recurBy, recurEnd } = this.state
    return (
      <View>
        {event.title
          ? <View>
              <Text style={style.title}>{event.title}</Text>
              <TextField
                style={{ marginTop: 20 }}
                label="Date"
                type="date"
                variant="outlined"
                onChange={this.handleChange('date')}
                defaultValue={moment(event.start).format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Mutation
                mutation={delete_event}
                variables={{ id: event.id }}
                onCompleted={() => this.onCompleted(refetch, 'deleted')}
              >
                {(delete_event) => {
                  return (
                    <TouchableOpacity
                      onPress={() => delete_event({ variables: { id: event.id } })}
                      style={style.delete}
                    >
                      <Delete style={{'fill': 'crimson'}} size={16} />
                    </TouchableOpacity>
                  )
                }}
              </Mutation>
            </View>
          :  <Text style={style.title}>{moment(event.start).format("MMMM DD")}</Text>
        }
        <View style={style.inputs}>
          <TextField
            label="Event Name"
            placeholder="Event Name"
            value={name}
            onChange={this.handleChange('name')}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            style={{ marginTop: 15 }}
            label="Start Time"
            type="time"
            value={start}
            variant="outlined"
            onChange={this.handleChange('start')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            style={{ marginTop: 15 }}
            label="End Time"
            type="time"
            value={end}
            variant="outlined"
            onChange={this.handleChange('end')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControlLabel
            style={{ marginTop: 10 }}
            control={
              <Switch
                checked={this.state.recur}
                onChange={this.handleToggle('recur')}
                value="recur"
                color="primary"
              />
            }
            label="Recurring Appointment"
          />
          {this.state.recur &&
            <View style={{ flexDirection: 'row', justifyContent: 'space-around'}}>
              {/*<View style={{ justifyContent: 'space-around' }}>*/}
                {/*<FormControl>
                  <InputLabel>
                    Duration
                  </InputLabel>
                  <Select
                    style={{ width: 200 }}
                    native
                    inputProps={{ name: 'duration' }}
                    value={this.state.duration}
                    onChange={this.handleChange('duration')}
                  >
                    <option value="" />
                    <option value={3}>3 Months</option>
                    <option value={6}>6 Months</option>
                    <option value={9}>9 Months</option>
                    <option value={12}>12 Months</option>
                    <option value={0}>Forever</option>
                  </Select>
                </FormControl>*/}
                <FormControl>
                  <InputLabel>
                    recur by
                  </InputLabel>
                  <Select
                    native
                    inputProps={{ name: 'recurBy' }}
                    value={this.state.recurBy}
                    onChange={this.handleChange('recurBy')}
                  >
                    <option value="DAILY">Daily</option>
                    <option value="WEEKLY">Weekly</option>
                    <option value="BIWEEKLY">Bi-Weekly</option>
                    <option value="MONTHLY">Monthly</option>
                  </Select>
                </FormControl>
                <TextField
                  label="end date"
                  type="date"
                  onChange={this.handleChange('recurEnd')}
                  value={this.state.recurEnd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              {/*</View>*/}
              {/*<FormGroup>
                {days.map(day => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state[day]}
                        onChange={this.handleToggle(day)}
                        value={day}
                        />
                    }
                    label={day}
                    />
                ))}
              </FormGroup>*/}
            </View>
          }
          <TouchableOpacity
            disabled={!name}
            style={!name && { opacity: 0.5 }}
            onPress={() =>
              create_event({
                variables: {
                  start: this.setDate(start),
                  end: this.setDate(end),
                  user: this.props.data.currentProvider.id,
                  name,
                  id: event.id,
                  recurBy: recur ? recurBy : null,
                  until: recur ? moment(recurEnd).format('X') : null
                }
              })
            }>
            <View style={style.button}>
              {mutationLoading
                ? <ActivityIndicator color="white" />
              : <Text style={{ color: 'white', textAlign: 'center' }}>SAVE</Text>
              }
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  onCompleted = (refetch, message) => {
    this.setState({
      start: moment().format('HH:mm'),
      end: moment().add(1, 'hours').format('HH:mm'),
      name: '',
      date: '',
      recurEnd: moment().format("YYYY-MM-DD"),
      recur: false,
      recurBy: 'DAILY',
      event: null
    })
    refetch()
    this.props.toggleSnackbar(`Event has been ${message}!`)
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleToggle = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  setDate = time => {
    const { event, date } = this.state
    const eventDate = event.start instanceof Date ? moment(date ? date : event.start).hour(0).minute(0) : event.start.toDate().setHours(0,0)
    const hour = parseInt(time.split(':')[0])
    const minutes = parseInt(time.split(':')[1])
    return moment(eventDate).add(hour, 'hour').add(minutes, 'minutes').format("X")
  }

  upcoming = events => {
    const upcomingEvents = events.filter(event => {
      return moment(event.start).isAfter(moment())
    })
    const sortedEvents = orderBy(upcomingEvents, event => {
      return moment(event.start)
    }, ['asc'])
    return (
      <View>
        <Text style={[style.title, { paddingBottom: 10 }]}>Upcoming Appointments</Text>
        {sortedEvents.map((event, index, arr) => {
          return (
            <View key={uniqueId()} style={[arr.length - 1 === index ? { paddingVertical: 10 } : style.upcomingAppt]}>
              <Text style={style.eventTitle}>{event.title}</Text>
              <Text style={[style.eventDetails, { paddingTop: 5, paddingBottom: 3 }]}>{moment(event.start).format("dddd MMM Do")}</Text>
              <Text style={style.eventDetails}>{`${moment(event.start).format('LT')} to ${moment(event.end).format('LT')}`}</Text>
            </View>
          )
        })}
      </View>
    )
  }
}

const eventStyleGetter = (event, start, end, isSelected) => ({
  style: {
    backgroundColor: event.color,
    borderRadius: '0px',
    opacity: 0.8,
    display: 'block'
  }
})

const { height, width } = Dimensions.get('window')

export const style = {
  container: {
    backgroundColor: 'white'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15
  },
  userInfo: {
    flexDirection: 'row'
  },
  userDetails: {
    marginLeft: 15,
    marginTop: 5
  },
  actionContainer: {
    width: 200, padding: 10
  },
  assessName: {
    fontSize: 18
  },
  userName: {
    fontSize: 15
  },
  gray: {
    color: 'gray'
  },
  padding: {
    paddingVertical: 5,
    paddingHorizontal: 20
  },
  center: {
    textAlign: 'center'
  },
  measureTitle: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 10,
    paddingLeft: 10,
  },
  msrText: {
    fontSize: 16,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 10
  },
  msrDetails: {
    marginLeft: '8%'
  },
  msrName: {
    fontWeight: '500',
    fontSize: 16,
    marginLeft: 75,
    marginTop: 18,
    position: 'absolute'
  },
  msrDescription: {
    flexWrap: 'wrap',
    flex: 1,
    fontWeight: '600'
  },
  msrCard: {
    padding: 15,
  },
  msrBorder: {
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro'
  },
  msrContainer: {
    flexDirection: 'row'
  },
  msrmnt: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro'
  },
  lastMsrmnt: {
    padding: 15
  },
  msrmntDetails: {
    fontWeight: '500',
    paddingTop: 5
  },
  headerToggle: {
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 10,
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: '500',
    paddingTop: 10
  },
  headerDate: {
    fontSize: 14,
    color: 'gray',
    padding: 5
  },
  description: {
    fontSize: 14,
    backgroundColor: 'white',
    color: 'rgb(108, 111, 113)',
    padding: 20,
  },
  measures: {
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderTopColor: 'gainsboro'
  },
  mobileLegendContainer: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  firstLegend: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderColor:'white',
    borderWidth: 0.5,
  },
  lastLegend: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  mobileLegend: {
    height: 8,
    borderColor: 'white',
    borderWidth: 0.4,
  },
  mobileLegendText: {
    fontWeight: '500',
    paddingTop: 10,
    fontSize: 10,
    flexWrap: 'wrap',
    flex: 1
  },
  scoreContainer: {
    width: 45,
    height: 45,
    backgroundColor: 'gainsboro',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10
  },
  scoreTxt: {
    fontWeight: '600',
    color: 'white',
    fontSize: 16,
    textAlign: 'center'
  },
}

export const newAssessment = {
  subTitle: {
    fontSize: 14,
    color: 'gray',
    paddingVertical: 3
  },
  assessTitle: {
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: 0.8,
    paddingVertical: 10
  },
  assessTypes: {
    fontSize: 18,
    color: 'darkgray',
    paddingTop: 10,
    paddingBottom: 10
  },
  subTypes: {
    paddingVertical: 10,
    paddingHorizontal: 5,
  }
}

export const actionsStyle = {
  title: {
    fontWeight: '600',
    fontSize: 18,
  },
  card: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2},
    shadowOpacity: 0.4,
    shadowRadius: 2,
    elevation: 1,
    backgroundColor: 'white',
    padding: 15,
    flexDirection: 'row',
  },
  cardContent: {
    marginLeft: '2%',
  },
  createdText: {
    color: 'gray'
  },
  progressContainer: {
    height: 80,
    width: 80,
    width: null,
  },
  container: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 5
  },
  assessContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  assessCardContainer: {
    width: 360,
    padding: 10,
  },
  assessCard: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessDetails: {
    marginLeft: 20,
  },
  assessTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
  },
  assessment: {
    flexDirection: 'row',
    flex: 1,
    marginVertical: 15,
    paddingBottom: 15,
    alignItems: 'center',
    cursor: 'pointer'
  },
  assessBorder: {
    borderBottomWidth: 0.2,
    borderBottomColor: 'gainsboro',
  },
  assessLinkTxt: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
  },
  assessHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  details: {
    textAlign: 'center',
    flex: 1,
  },
  detailHeader: {
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: '600',
    color: 'gray',
    marginTop: 20,
  },
  assessLink: {
    flex: 3,
    justifyContent: 'center',
  },
  assessActions: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  iconBtn: {
    marginLeft: 10,
    marginRight: 10,
  },
  noAssessments: {
    alignSelf: 'center',
    paddingVertical: 20
  }
}

export const clientAssessStyle = {
  title: {
    fontWeight: '600',
    fontSize: 18,
  },
  card: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2},
    shadowOpacity: 0.4,
    shadowRadius: 2,
    elevation: 1,
    backgroundColor: 'white',
    padding: 15,
    flexDirection: 'row',
  },
  cardContent: {
    marginLeft: '2%',
  },
  createdText: {
    color: 'gray'
  },
  progressContainer: {
    height: 80,
    width: 80,
    width: null,
  },
  container: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 5
  },
  assessContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  assessCardContainer: {
    width: 360,
    padding: 10,
  },
  assessCard: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessDetails: {
    marginLeft: 20,
  },
  assessTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
  },
  assessment: {
    flexDirection: 'row',
    flex: 1,
    marginVertical: 15,
    paddingBottom: 15,
    alignItems: 'center',
    cursor: 'pointer'
  },
  assessBorder: {
    borderBottomWidth: 0.2,
    borderBottomColor: 'gainsboro',
  },
  assessLinkTxt: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
  },
  assessHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  details: {
    textAlign: 'center',
    flex: 1,
  },
  detailHeader: {
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: '600',
    color: 'gray',
    marginTop: 20,
  },
  assessLink: {
    flex: 3,
    justifyContent: 'center',
  },
  assessActions: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  iconBtn: {
    marginLeft: 10,
    marginRight: 10,
  },
  noAssessments: {
    alignSelf: 'center',
    paddingVertical: 20
  }
}

export const formStyle = {
  container: {
    backgroundColor: 'white',
  },
  padding: {
    paddingHorizontal: 20,
    paddingBottom: 20,
    paddingTop: 100
  },
  clientImg: {
    height: 50,
    width: 50,
    borderRadius: 50,
  },
  submitBtn: {
    width: 200
  },
  toolbarBtn: {
    alignItems: 'center',
  },
  btnTitle: {
    color: 'white',
    fontWeight: '600',
    fontSize: 14,
  },
  btnSubtitle: {
    color: '#2D7261',
    fontWeight: '300',
    fontSize: 10
  },
  emailBtn: {
    backgroundColor: '#2C98F0',
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5
  },
  button: {
    marginTop: 50,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2C98F0'
  },
  disableButton: {
    marginTop: 50,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(44, 152, 240, 0.3)'
  },
  btnText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16
  },
  undoneMsr: {
    fontSize: 16,
    color: 'gray'
  },
  doneMsr: {
    color: '#007aff',
    fontWeight: '600'
  },
  msrStatus: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  refreshBtn: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  msrStatusContainer: {
    position: 'fixed',
    top: 190,
    zIndex: 10,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro',
    paddingBottom: 15
  },
  omeMsrStatusContainer: {
    zIndex: 10,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro',
    paddingBottom: 15
  }
}

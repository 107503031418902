import { style } from './style'

export default class extends React.Component {
  render() {
    const {headerLeft, headerTitle, headerRight} = this.props
    return (
      <View style={style.headerContainer}>
        {headerLeft && <View style={style.headerLeft}>
          {headerLeft}
        </View>}
        <View style={style.headerTitle}>
          {headerTitle}
        </View>
        <View style={style.headerRight}>
          {headerRight}
        </View>
      </View>
    )
  }
}

export const HeaderTitle = ({title, onPress, subtitle, textItems=[], style: _style}) => (
  <View style={_style}>
    <View style={style.headerAssessmentTitle}>
      {onPress
        ? <TouchableOpacity
            onPress={onPress}
          >
            <Text style={style.title}>{title}</Text>
          </TouchableOpacity>
        : <Text style={style.title}>{title}</Text>
      }
    </View>
    <Text style={style.subtitle}>{subtitle}</Text>
    {textItems.map((text, index) =>
      <Text key={index} style={style.text}>{text}</Text>)}
  </View>
)

import React, {useContext, useState} from 'react'
import moment from 'moment'
import { useActions } from '../../../helpers/useActions';
import {join} from 'lodash'
import Workout from './Workout'
import axios from 'axios'
import {useMutation} from "@apollo/client"
import EditIcon from '@material-ui/icons/Create'
import RenameWorkout from './Modals/RenameWorkout'
import ExercisePicker from './Modals/ExercisePicker'
import CreateExercise from './Modals/CreateExercise'
import ConfirmModal from './Modals/ConfirmModal'
import { Button } from '../../common/Button'
import { useModal } from '../../common/Modal'
import { activateWorkout } from '../../graphql/mutation/workout'
import { toggleSnackbar } from '../../../config/actions/snackbar'
import SendIcon from '@material-ui/icons/Send'
import SaveIcon from '@material-ui/icons/Save'
import { ProgressCircle } from '../../common/progressCircle'
import { GlobalModalContext } from '../../common/GlobalModal';

const DAYS_IN_WEEK = 7;
const WEEKS_IN_STAGE = 4;
const WORKOUT_STAGES = 4;
const DAYS_TO_NEXT_STAGE = DAYS_IN_WEEK * WEEKS_IN_STAGE;

const WorkoutBar = (props) => {
  let {traineeProfile: {first_name, last_name, photo_url, email, birth_date, uuid},
    workout, workout: { name, start_date, end_date, exercises, percent_complete=0, id, state}} = props
  const [loading, setLoading] = useState(false)
  const { openModal } = useContext(GlobalModalContext)
  const [sendWorkout] = useMutation(activateWorkout)

  const buildPdf = () => {
    setLoading(true)
    axios('http://localhost:1657', {
      method: 'POST',
      responseType: 'blob',
      data: JSON.stringify({data: { workout }})
    }).then(res => {
      const file = new Blob(
        [res.data],
        {type: 'application/pdf'}
      )
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
      setLoading(false)
    })
    .catch(error => {
        setLoading(false)
        console.log(error, 'error')
    })
  }

  const sendingWorkout = () => {
    sendWorkout({ variables: { id } })
    .then(() => props.toggleSnackbar('Workout has been sent!'))
  }

  return (
      <div className="operationBar">
        <div style={{width: '100%', position: 'relative', backgroundColor: 'white'}}>
          <div className="complianceContainer">
            <ProgressCircle
                value={percent_complete}
                label={`${percent_complete.toFixed(1)}%`}
                size={50}
                thickness={6}
                fontSize={18}
            />
          </div>
          <div className="workoutInfo">
            <div>
              <span className="name">{name || 'No name'}</span>
              <div style={{position: 'absolute', right: 15, top: 10, display: 'flex'}}>
                {/*<div
                  style={{ cursor: 'pointer' }}
                  onClick={() => buildPdf()}
                >
                  {loading ? <ActivityIndicator /> : <SaveIcon htmlColor="rgba(0,0,0,0.4)" fontSize="small" />}
                </div>
                <div
                    style={{margin: '0px 10px', cursor: 'pointer'}}
                    onClick={() => openModal({
                      modal: <RenameWorkout name={name} workout={workout} />,
                      free: true,
                    })}
                >
                  <EditIcon
                      htmlColor="rgba(0,0,0,0.4)"
                      fontSize="small"
                  />
              </div>*/}
                {state === 'inactive' &&
                <div
                    onClick={() => openModal({
                      modal:
                        <ConfirmModal
                          action={() => sendingWorkout()}
                          modalClass="sm"
                          {...props}
                        >
                        <Text type="subheading">Do you wish to send this workout to this client?</Text>
                        </ConfirmModal>,
                      free: true
                    })}
                    style={{cursor: 'pointer'}}
                >
                  <SendIcon
                      htmlColor="rgba(0,0,0,0.4)"
                      fontSize="small"
                  />
                </div>
                }
              </div>
            </div>
            <div>
              <span className="secondaryInfo">
                {join([start_date, end_date].map(time => moment(time).format('ll')), ' - ')}
              </span>
            </div>
            <span className="labelSection">
              {`${exercises.length}`} Exercise{exercises.length != 1 ? 's': ''}
            </span>
          </div>

            <div className="button" style={{ marginRight: 135 }}>
              <Button
                color="#343435"
                text="Create Exercise"
                onPress={() => openModal({
                  modal: <CreateExercise {...props} />,
                dialogProps: {maxWidth: 'md'},
                free: true
              })}
              />

            </div>
            <div className="button">
              <Button
                color="#343435"
                text="Add Exercises"
                onPress={() => openModal({
                  modal: <ExercisePicker {...props} workout={workout} />,
                dialogProps: {maxWidth: 'md'},
                free: true
              })}
              />

            </div>

        </div>
      </div>
  )
};

export default function WorkoutEditor(props) {

  const actions = useActions(toggleSnackbar);
  const { openModal } = useContext(GlobalModalContext);
  props = {...props, openModal };
  // todo: do i need to uncomment below?
  // props = {...props, ...actions};

  return (
      <div>
        <WorkoutBar {...props} />
        <Workout
            {...props}
            actions={{...actions}}
            workoutStages={WORKOUT_STAGES}
            weeksInStage={WEEKS_IN_STAGE} />
      </div>
  );
}

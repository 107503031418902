import { signin } from '../graphql/mutation/client'
import { Mutation } from 'react-apollo'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Image } from 'react-native-web'
import { styles } from './style'
import { currentProvider } from '../graphql/query/client'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { changeLanguage } from '../../config/actions/localization'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

@connect(state => state.localization, {changeLanguage})
export default class SigninForm extends Component {
  state = { language: 'en', password: '', email: '', showPassword: false }
  render() {
    const { onSignin, strings } = this.props
    return (
      <Mutation
        mutation={signin}
        onCompleted={onSignin}
        update={(cache, {data}) => {
          AsyncStorage.setItem('currentProvider', JSON.stringify(data.login))
          AsyncStorage.setItem('access_token', data.login.access_token)
          cache.writeQuery({
            query: currentProvider,
            data: {currentProvider: data.login}
          })
        }}
      >
        {(signin, { loading, error }) => (
          <View style={styles.container}>
            <form onKeyPress={e => this.onEnter(e, signin)}>
              {this.languageSelector()}
              <Image style={styles.logoImg} source={require('../../../images/logo_only.png')} />
              {this.header()}
              {this.formFields()}
              {error && <Text style={{color: 'red', textAlign: 'center', paddingTop: 5}}>{strings.loginError}</Text>}
              {this.signinButton(loading, signin)}
            </form>
          </View>
        )}
      </Mutation>
    )
  }
  languageSelector = () => {
    return (
      <View style={{position: 'absolute', right: 15, top: 3}}>
        <Select
          value={this.state.language}
          onChange={this.handleChange}
          >
          <MenuItem value="en"><img style={{width: 32, height: 21, marginLeft: 5}} src={require('./usa.png')} /></MenuItem>
          <MenuItem value="cn"><img style={{width: 32, height: 21, marginLeft: 5}} src={require('./china.png')} /></MenuItem>
        </Select>
      </View>
    )
  }
  formFields = () => {
    const { email, password, showPassword } = this.state
    const { strings } = this.props
    return (
      <View style={{ paddingBottom: 10 }}>
        <View style={{ paddingVertical: 15 }}>
          <TextField
            label={strings.email}
            value={email}
            onChange={this.handleTextChange('email')}
            fullWidth
            style={{ paddingVertical: 10 }}
          />
        </View>
        <View style={{ paddingVertical: 10 }}>
          <TextField
            label={strings.password}
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={this.handleTextChange('password')}
            fullWidth
            style={{ paddingVertical: 15 }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    {showPassword
                      ? <Visibility />
                      : <VisibilityOff />
                    }
                  </IconButton>
                </InputAdornment>
            }}
          />
        </View>
      </View>
    )
  }
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword })
  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value })
  }
  onEnter = (e, submit) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      submit({ variables: { email: this.state.email, password: this.state.password } })
    }
  }
  handleChange = e => {
    this.props.changeLanguage(e.target.value)
    this.setState({ language: e.target.value })
  }
  header = () => (
    <View>
      <Text style={styles.headerTitle}>{this.props.strings && this.props.strings.welcomeToOCP || 'Welcome!'}</Text>
    </View>
  )
  signinButton = (loading, signin) => (
    <TouchableOpacity
      disabled={loading}
      style={loading ? styles.disable : styles.button}
      onPress={() => signin({ variables: { email: this.state.email, password: this.state.password } })}
    >
      {loading
       ? <ActivityIndicator size="small" />
       : <Text style={styles.btnText}>{this.props.strings.signin}</Text>}
    </TouchableOpacity>
  )
}


// get language by browser
// const lang = window.navigator.userLanguage || window.navigator.language

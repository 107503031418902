import React from 'react'
import {map, difference, some} from 'lodash'
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import { Input, MenuItem } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

import {editStyle} from './style'

const parameterOptions = ["sets", "reps", "pounds", "minutes", "seconds", "second holds", "meters", "free text", "zone"]

export default class extends React.Component {
  render() {
    const {paramSet, addParam, editParam, rmParam} = this.props
    return (
      <View>
        <View style={editStyle.weekDayCard}>
          <Text>{_.upperFirst(paramSet.daily_index)}</Text>
          <Select
            style={{width:125}}
            value=""
            displayEmpty
            onChange={(e, i) => addParam(paramSet, e.target.value)}>
            <MenuItem key={-1} value="" disabled><em>Add param</em></MenuItem>
            {this.parameterOptions().map((parameter, i) => (
              <MenuItem key={i} value={parameter}>{parameter}</MenuItem>
            ))}
          </Select>
        </View>
        <View className="paramContainer">
          {paramSet.parameters.map(({label, value}, i) => (
          <View key={i} className="params">
            <Text style={styles.editLabel}>
              {label}:
              <span className="editInput">
                <Input
                  value={value}
                  style={styles.input}
                  placeholder="value"
                  onChange={({target: {value}}) => editParam(paramSet, {label, value})}/>
              </span>
            </Text>
            <View className="deleteBtn"
                 onClick={() => rmParam(paramSet, label)}>
              <ClearIcon style={styles.icon} /*color='#9F9F9F'*/ />
            </View>
          </View>))}
        </View>
      </View>
    )
  }

  parameterOptions = () =>
    difference(parameterOptions, map(this.props.paramSet.parameters, 'label'))
}
const styles = {
  formWidth: {width: 80},
  paramLabel: {fontSize: 11, top: 5},
  editLabel: {color: 'gray', padding: 5},
  input: {textAlign: 'right', paddingRight: 7, fontSize: 14},
  icon: {transform: 'scale(0.6)'}
}

import React, {Component, useContext} from 'react';
import {useDeleteStage} from '../../actions/workout';
import ConfirmModal from './ConfirmModal';

const DeleteStage = props => {
  const { exercise, stage, stageIndex, workout } = props;
  const [deleteStage] = useDeleteStage(workout.id, stage, exercise);

  return (
    <ConfirmModal
      action={() => deleteStage()}
      modalClass="sm"
      {...props}
    >
      <Text>
        Are you sure you want to delete
        <span className="secondary">{ ` Stage ${stageIndex} ` }</span>
        from <span className="primary">{exercise.name}</span>?
      </Text>
    </ConfirmModal>
  );
}

export default DeleteStage

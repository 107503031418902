import { Button, DialogTitle, DialogContent, DialogActions,
         TextField, InputAdornment, FormControl,
         FormControlLabel, Checkbox, FormGroup, FormLabel } from '@material-ui/core'
import {exercisePicker as style} from './style'
import { useState } from 'react'


// add parameters....
// const parameterOptions = ["sets", "reps", "pounds", "minutes", "seconds", "second holds"]
const CreateExercise = props => {
  const [name, setName] = useState('')
  const [instructions, setInstructions] = useState('')
  const [activeParams, setActiveParams] = useState([])
  const [params, setParams] = React.useState({
    sets: false,
    reps: false,
    pounds: false,
    minutes: false,
    seconds: false,
    second_holds: false
  })

  const handleChange = (event) => {
    // remove/add state for active parameters for input
    if (event.target.checked === true) {
      setActiveParams(state => [...state, event.target.name])
    } else {
      setActiveParams(state => state.filter(p => p !== event.target.name))
    }

    setParams({ ...params, [event.target.name]: event.target.checked })

  }

  const { sets, reps, pounds, minutes, seconds, second_holds } = params

  return (
    <View style={{ width: 450, margin: 10 }}>
      <DialogTitle disableTypography={true}>
        <Text style={style.headerTitle}>Create Exercise</Text>
      </DialogTitle>
      <DialogContent>
        <View>
          <View style={{ marginVertical: 10 }}>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              size="small"
              onChange={(event) => setName(event.target.value)}
           />
          </View>
          <View style={{ marginVertical: 10 }}>
            <TextField
              id="instructions"
              label="Instructions"
              multiline
              size="small"
              rows={4}
              onChange={(event) => setInstructions(event.target.value)}
              variant="outlined"
            />
          </View>
          {/*<View style={{ marginVertical: 5 }}>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Add Images and Video</Text>
            <input
              accept="image/*,video/*"
              id="media"
              multiple
              type="file"
              onChange={event => console.log(event.target.files)}
            />
          </View>*/}
          <FormControl component="fieldset">
            <Text style={{ fontSize: 16, paddingVertical: 10 }}>Default Parameters</Text>
            <FormGroup style={{ flexDirection: 'row' }}>
              <FormControlLabel
                control={<Checkbox checked={sets} onChange={handleChange} name="sets" />}
                label="sets"
              />
              <FormControlLabel
                control={<Checkbox checked={reps} onChange={handleChange} name="reps" />}
                label="reps"
              />
              <FormControlLabel
                control={<Checkbox checked={pounds} onChange={handleChange} name="pounds" />}
                label="pounds"
              />
              <FormControlLabel
                control={<Checkbox checked={minutes} onChange={handleChange} name="minutes" />}
                label="minutes"
              />
              <FormControlLabel
                control={<Checkbox checked={seconds} onChange={handleChange} name="seconds" />}
                label="seconds"
              />
              <FormControlLabel
                control={<Checkbox checked={second_holds} onChange={handleChange} name="second_holds" />}
                label="second holds"
              />
            </FormGroup>
          </FormControl>
          <View style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
            {map(activeParams, param => {
              return (
                <TextField style={{ padding: 10, width: 130 }} id={param} size="small" label={`# of ${param.replace(/_/g," ")}`} />
              )
            })}
          </View>

          <View style={{ marginVertical: 10 }}>
            <Text style={{ fontSize: 16, paddingVertical: 10 }}>Add Images</Text>
            <input
              accept="image/*"
              id="image"
              multiple
              type="file"
              onChange={event => console.log(event.target.files)}
            />
          </View>
          {/*<View style={{ marginVertical: 10 }}>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Add Video</Text>
            <input
              accept="video/*"
              id="video"
              type="file"
              onChange={event => console.log(event.target.files)}
            />
          </View>*/}
        </View>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.closeModal}>Cancel</Button>
        <Button
          variant="text"
          color="primary"
          onClick={() => console.log('clicked')}
          disabled={true}
        >
          Create Exercise
        </Button>
      </DialogActions>
    </View>
  )
}

export default CreateExercise

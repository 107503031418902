import { graphql } from 'react-apollo'
import compose from 'recompose/compose'
import {post_ob, retract_ob, o_post_ob} from '../graphql/mutation/assessment'
import {o_assessment as AssessmentQuery} from '../graphql/query/assessment'
import {omit, concat, cloneDeep, merge} from 'lodash'

// const postOb = graphql(post_ob, {
//   props: (props) => ({
//     postObservation: vars =>
//       buildPostOb(props, vars)
//   })
// })

const postOb = graphql(o_post_ob, {
  props: (props) => ({
    postObservation: vars =>
      buildPostOb(props, vars)
  })
})

const retractOb = graphql(retract_ob, {
  props: (props) => ({
    retractOb: vars => buildRetractOb(props, vars)
  })
})

export default compose(postOb, retractOb)

const buildPostOb = ({mutate}, vars) => (
  mutate(mutatePostOptions(vars))
)

// const mutatePostOptions = (variables) => {
//   const { assessment } = variables
//   return {
//     variables: omit(variables, 'obId'),
//     update: (store, { data: { create_observation: obs }}) => {
//       const data = cloneDeep(
//         store.readQuery({
//           query: AssessmentQuery,
//           variables: { id: assessment }
//         }))
//       store.writeQuery({
//         query: AssessmentQuery,
//         variables: { id: assessment },
//         data: update(data, 'assessment.obs', assessObs =>
//                      concat(differenceBy(assessObs, obs, 'id'), obs))
//       })
//     },
//     optimisticResponse: mkeOptimisticResponse(variables)
//   }
// }

const mutatePostOptions = (variables) => {
  const { assessment_id } = variables
  return {
    variables: omit(variables, 'obId'),
    update: (store, { data: { o_post_ob: obs }}) => {
      const data = cloneDeep(
        store.readQuery({
          query: AssessmentQuery,
          variables: { id: assessment_id }
        }))
      store.writeQuery({
        query: AssessmentQuery,
        variables: { id: assessment_id },
        data: update(data, 'assessment.obs', assessObs =>
                     concat(differenceBy(assessObs, obs, 'id'), obs))
      })
    },
    optimisticResponse: mkeOptimisticResponse(variables)
  }
}

const buildRetractOb = ({mutate, ownProps}, vars) => (
  mutate(mutateRetractOptions(ownProps.assessment || vars.assessment, vars))
)

const mutateRetractOptions = (assessment, {obId}) => {
  return {
    variables: { id: obId },
    update: (store, { data: { clear_observation } }) => {
      const data = cloneDeep(
        store.readQuery({
          query: AssessmentQuery,
          variables: { id: assessment.id || assessment }
      }))
      data.assessment.obs = differenceBy(data.assessment.obs, clear_observation, 'id')
      store.writeQuery({
        query: AssessmentQuery,
        variables: { id: assessment.id || assessment },
        data
      })
    },
    optimisticResponse: mkeOptimisticResponse(merge({obId}, {type: "clear_observation"}))
  }
}

// const mkeOptimisticResponse = ({freetext, msr, obId, client, value, type = "create_observation"}) => {
//   let observation = {__typename: 'Mutation'}
//   observation[type] = [{
//     __typename: "Observation",
//     id: obId || uniqueId('id_'),
//     recommendation: "",
//     client: client || "",
//     score: -1,
//     value: value || "",
//     freetext: value && value.toString() || "",
//     assessor: '',
//     msr: {
//       __typename: "Measure",
//       id: msr || ""
//     }
//   }]
//   return observation
// }

const mkeOptimisticResponse = ({assessment_id, measurement, value, type = "o_record_ob"}) => {
  let observation = {__typename: 'Mutation'}
  observation[type] = [{
    __typename: "Ob",
    id: measurement || uniqueId('id_'),
    value: value || "",
    denominator: "",
    created_at: "",
    denominator_unit: "",
    unit: "",
    msr: {
      __typename: "MM",
      id: measurement || ""
    }
  }]
  return observation
}

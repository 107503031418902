
import {connect} from 'react-redux'
import {deleteWeek} from '../../../actions/workout'
import ConfirmModal from '../ConfirmModal'

@connect(null, {deleteWeek})
export default class DeleteWeek extends Component {
  render() {
    const {week, week: {weekIndex, stageIndex},
           exercise,
           deleteWeek}  = this.props
    return (
      <ConfirmModal
        action={() => deleteWeek(week, exercise)}
        modalClass="sm">
        <Text type="subheading"> Are you sure you want to delete
          <span className="secondary">{` Week ${weekIndex} `}</span>
          from <span className="secondary">{` Stage ${stageIndex} `}</span>
          of <span className="primary">{exercise.name}</span>?
        </Text>
      </ConfirmModal>
    )
  }
}

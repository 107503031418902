import { style } from '../../style'

const HTML = ({html, ...props}) =>
  <div dangerouslySetInnerHTML={{__html: html}} {...props} />

export default class Detail extends Component {
  render() {
    const {
      ob: multiOb,
      msr: {components, description, id, rationale},
      scoreOb, color='gray',
      assessment_type,
      hasMsrs
    } = this.props
    const ob = scoreOb(id)
    console.log(this.props, "detail")
    const recommendation = ob && ob.recommendation ? ob.recommendation : ''
    return (
      <View>
        {hasMsrs
          ? components.map((msr, index, msrs) => {
              const { id, name, description, display } = msr
              const ob = scoreOb(id)

              return (
                <View key={index}>
                  {(ob && !display) &&
                    <View
                      style={
                        msrs.length - 1 == index
                          ? style.lastMsrmnt
                          : style.msrmnt
                      }
                    >
                      <Text numberOfLines={5} style={style.msrDescription}>
                        {description[0] || name}
                      </Text>
                      <View>
                        <Text style={[style.msrText, {color: color}]}>{obValue(msr, ob.value)}</Text>
                        {assessment_type.name !== 'Cardio' && this.showCutoffs(msr, ob.value)}

                        <HTML html={ob.recommendation} />
                      </View>
                      <HTML html={recommendation} />
                    </View>
                  }
                </View>
              )})
          : <View>
              {ob &&
                <View style={style.msrmnt}>
                  <View>
                    <Text style={[style.msrText, {color: color}]}>{obValue(this.props.msr, ob.value)}</Text>
                    <HTML html={ob.recommendation} />
                  </View>
                  <HTML html={recommendation} />
                </View>
              }
            </View>
        }
      </View>
    )
  }
  showCutoffs = (measure, value) => {
    const cutoffs = JSON.parse("[" + this.props.msr.cutoffs + "]")
    const nextLevel = cutoffs[0] && getNextCuttoff(cutoffs[0], value, measure)
    return (
      <Text style={{ color: 'gray' }}>
        {nextLevel}
      </Text>
    )
  }
}

// const obValue = ({ display, options, unit }, value) => {
//   console.log(options[value], "options")
//   if (display == 'Date') return moment(value * 1000).format('MMMM Do YYYY')
//   if (options.length) {
//     const text = find(options, {value})
//       ? find(options, {value}).option.text
//       : Math.max(Math.round(value * 10) / 10).toFixed(1)
//     return text
//   }
//   return `${ Math.max(Math.round(value * 10) / 10).toFixed(1)} ${unit ? unit.plural : ''}`
// }

const obValue = ({ display, options, unit }, value) => {
  console.log(unit, "unit")
  if (display == 'Date') return moment(value * 1000).format('MMMM Do YYYY')
  if (options && options.length) {
    const text = options[value]
      ? options[value]
      : Math.max(Math.round(value * 10) / 10).toFixed(1)
    return text
  }
  return `${ Math.max(Math.round(value * 10) / 10).toFixed(1)} ${unit ? unit : ''}`
}

const getNextCuttoff = (cutoffs, value, measure) => {
  // console.log(measure, 'measure')
  // console.log(cutoffs, 'cutoffs')
  const lowOrHigh = cutoffs[0] - cutoffs[3]
  const high = lowOrHigh > 0
  if (high) {
    const highs = cutoffs.filter(num => num > value)
    const val = highs[highs.length - 1]
    return value >= cutoffs[0] ? 'Client has achieved maximum efficiency for this measure' : `Getting ${obValue(measure, val)} is the client's next step up to a higher score.`
  } else {
    const lows = cutoffs.filter(num => num < value)
    const val = lows[lows.length - 1]
    return value <= cutoffs[0] ? 'Client has achieved maximum efficiency for this measure' : `Getting ${obValue(measure, val)} is the client's next step up to a higher score.`
  }
}

import { Text, View } from 'react-native-web'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const getSelectedWrkts = ({name, id}, days, {obs}) => {
  const selectedWrkts = flatMap(days, (day) => drop(day, 1)).filter(({id}) => has(obs, id)).length

  return (
    <Text style={{fontWeight: '300'}}>
      {`${selectedWrkts} selected`}
    </Text>
  )
}

class CardioDropdownGrid extends Component {
  render() {
    const [
      exrFrequency,
      longDay,
      ...days
    ] = sortBy(this.props.msr.msrs, 'position')
    const formatDays = this.formatDays(longDay, days)

    return (
      <View>
        {this.renderLongDay(longDay)}
        {this.renderCardioHeader(exrFrequency, formatDays)}
        {this.renderCardioBody(formatDays)}
      </View>
    )
  }

  renderLongDay = ({optionables, id, name, description}) => {
    const ob = this.getOb(id)

    return (
      <View style={styles.longDayView}>
        <View>
          <Text style={styles.longDayText}>{description || name}</Text>
          <Select
            style={{flex: 1}}
            value={ob && ob.value}
            onChange={event => this.onDaySelect(id, ob, event.target.value)}>
            <MenuItem key={-1} value={-1}>Select long day availability</MenuItem>
            {optionables.map(({option: {text}, position}, index) =>
              <MenuItem key={index} value={position}>{text}</MenuItem>
            )}
          </Select>
        </View>
        <View>
        </View>
      </View>
    )
  }

  renderCardioHeader = (exrFrequency, formatDays) => {
    return (
      <View>
        <Text style={{marginBottom: 25, fontWeight: '300'}}>
          {`Select your available days. (Default: Monday, Wednesday, and Friday mornings)`}
        </Text>
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <Text style={{flex: 1}}>
            {getSelectedWrkts(exrFrequency, formatDays, this.props.assessment)}
          </Text>
          {['Morning', 'Afternoon', 'Evening'].map(header => {
             return (
               <Text style={{flex: 1}} key={header}>{header}</Text>
             )})}
        </View>
      </View>
    )
  }

  renderCardioBody = (days) => {
    return (
      <View style={{display: 'flex'}}>
        {days.map(([day, ...rest], i) => {
           return (
             <View key={i} style={{display: 'flex', flexDirection: 'row'}}>
               <Text style={{flex: 1}}>{day.name}</Text>
               {this.renderBody(rest.map(day => day))}
             </View>
           )
        })}
      </View>
    )
  }

  renderBody = (days) => {
    return days.map(({ id, optionables }, i) => {
      const ob = this.getOb(id)
      return (
        <View style={{ marginHorizontal: 3, flex: 1 }} key={id}>
          <Select
            style={{flex: 1}}
            value={ob && ob.value}
            onChange={event => this.onDaySelect(id, ob, event.target.value)}>
            <MenuItem key={-1} value={-1}>none</MenuItem>
            {optionables.map(({option: {text}}, index) => {
               return (
                 <MenuItem key={index} value={index} >{text}</MenuItem>
               )
            })}
          </Select>
        </View>
      )
    })
  }

  formatDays = (longDay, days) => {
    const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const ob = this.getOb(longDay.id)
    let [weekDays, weekTimes] = partition(days, ({name}) => WEEK_DAYS.includes(name))
    if (ob) {
      set(weekDays, '[ob.value].long', true)
    }
    const dayTimes = chunk(weekTimes, 3)
    return zipWith(weekDays, dayTimes, (day, times) => [day].concat(times))
  }

  onDaySelect = (msrId, ob, value) => {
    const {id: assessId, client: { uuid: clientId }} = this.props.assessment
    if (value < 0) {
      if (ob) {
        this.props.retractOb({obId: ob.id})
      }
    }
    else {
      this.props.postOb(value, msrId)
    }
  }

  getOb = (id) => {
    return this.props.assessment.obs[id];
  }
}

export default CardioDropdownGrid

const styles = {
  display: {
    display: 'flex',
    flexDirection: 'row'
  },
  flex: {
    flex: 1
  },
  longDayView: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20
  },
  longDayText: {
    fontWeight: '300',
    marginBottom: 20
  }
}

import React, {useContext} from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useActions } from '../../../helpers/useActions';
import { getWorkout } from '../graphql/workout.graphql'
import { formatWorkout } from '../helpers/workout'
import * as appActions from '../actions/app'
import * as workoutActions from '../actions/workout'
import WorkoutEditor from '../components/WorkoutEditor'
import Modal from '../components/Modals'
import './style.css'
import {useWorkout, WorkoutContext} from '../WorkoutContext';
import Workout from '../components/Workout';
import {LinearProgress} from '@material-ui/core'

const WorkoutEditorContainer = props => {
  const storeData = useSelector(s => s.workoutEditor);
  // TODO deprecate useActions
  const actions = useActions({...appActions, ...workoutActions});
  const {workout, setWorkout} = useWorkout();

  props = { ...props, ...storeData, ...actions};
  const { id, modalOpen, modal, traineeProfile } = props;

  const { loading, error, data, refetch, networkStatus } = useQuery(getWorkout, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  if (loading) return <LinearProgress />;
  if (error) return `Error! ${error.message}`;
  setWorkout(data.workout);

  // // todo: only for local testing while measures isn't returning
  // let measureId = 5;
  // let count = 0;
  // data.workout.exercises.map(exercise => {
  //   // const rand = Math.floor(Math.random() * 4);
  //   const score = Math.floor(Math.random() * 10);
  //   exercise.rationale = {
  //     'measure': `measure_${measureId}`,
  //     'score':score
  //   };
  //
  //   if (++count >= 4) {
  //     count = 0;
  //     measureId--;
  //   }
  // });

  return (
    <div>
      <WorkoutEditor
        {...props}
        traineeProfile={traineeProfile}
        workout={formatWorkout(data)}
        data={data}
      />
      <Modal open={modalOpen} modal={modal} />
    </div>
  );
}

export default WorkoutEditorContainer

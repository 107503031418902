import { createTheme } from '@material-ui/core/styles'

// TODO not sure how this gets updated
// import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';


import blueGrey from '@material-ui/core/colors/blueGrey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';

// import ColorManipulator from 'material-ui/utils/colorManipulator';
// import Spacing from 'material-ui/styles/spacing';
// import zIndex from 'material-ui/styles/zIndex';
// import _ from 'lodash';
// const merge = lodash.merge;


export const theme = createTheme({
  flatButton: {
    primaryTextColor: '#444C63',
    secondaryTextColor: '#B8D408'
  },
  raisedButton: {
    primaryColor: '#444C63',
    secondaryColor: '#B8D408'
  },
  palette: {
    primary1Color: '#FFF',
    primary2Color: '#B8D408',
    primary3Color: 'black',
    accent1Color: '#B8D408',
    accent2Color: grey[100],
    accent3Color: grey[500],
    textColor: 'black',
    alternateTextColor: 'white',
    canvasColor: 'white',
    borderColor: grey[300],
    pickerHeaderColor: cyan[500]
  },
  paper: {
    zDepth: 0
  },
  table: {
    height: 'calc(100vh - 122px)'
  },
  tableHeaderColumn: {
    fontSize: '14px'
  },
  tabs: {
    backgroundColor: 'white',
    textColor: 'darkgray',
    selectedTextColor: '#444C63'
  },
  refreshIndicator: {
    strokeColor: '#B8D408',
    loadingStrokeColor: '#B8D408'
  },
  textField: {
    focusColor: '#8C97B2'
  },
  stepper: {
    iconColor: 'rgb(184, 212, 8)'
  },
  radioButton: {
    borderColor: 'black',
    checkedColor: blueGrey[300]
  },
  datePicker: {
    color: '#F03462',
    textColor: 'white',
    calendarTextColor: '#F03462',
    selectColor: '#444C63',
    selectTextColor: 'white'
  },




  palette: {
    primary: lightBlue,
    secondary: pink,
  },



  overrides: {
    MuiButton: {
      root: {
        color: blueGrey[500],
        height: 40,
        padding: '0 15px'
      }
    },
    MuiToolbar: {
      root: {
        background: 'white'
      }
    },
    MuiTabs: {
      root: {
        background: lightBlue[500]
      }
    },
    MuiTab: {
      label: {
        fontSize: '0.7125rem',
        color: 'white'
      }
    },
    MuiTabIndicator: {
      root: {
        background: 'white'
      },
      colorAccent: {
        backgroundColor: 'white'
      }
    },
    MuiFormControlLabel: {
      root: {
        height: 30
      },
      label: {
        fontSize: 16,
        fontWeight: 300
      }
    },
    MuiSwitch: {
      root: {
        color: 'gainsboro'
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'black !important',
        color: 'white !important'
      }
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'white'
      },
      toolbarBtnSelected: {
        color: 'white'
      }
    },
    MuiPickersModal: {
      // color: 'white',
      // dialogAction: {
      //   color: 'rgb(68, 76, 99)',
      // }
    },
    MuiPickersDay: {
      day: {
        fontSize: 9,
        fontWeight: 400,
      },
      daySelected: {
        backgroundColor: 'rgb(68, 76, 99) !important',
        color: 'white !important',
      },
      dayDisabled: {
        color: 'gainsboro !important'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgb(0, 188, 212)',
        color: 'white',
      }
    },
    // MuiMenuItem: {
    //   root: {
    //     color: props => {props.disabled ? 'black' : '#999999'}
    //   }
    // }
  }
});
